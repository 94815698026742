export enum PrivilegeEnum {
  ViewSCFAmendment = 1,
  EditSCFAmendment = 2,
  RequestAmendment = 3,
  SCFOperationalApproval = 4,
  SCFNewForm = 5,
  ViewRSSWAAmendment = 6,
  EditRSSWAAmendment = 7,
  RequestRSSWAAmendment = 8,
  BulletinBoardEditor = 9,
  RadAreaView = 1004,
  RadAreaCreate = 1005,
  RadAreaModify = 1006,
  RadAreaDelete = 1007,
  SCFSubmit = 10,
  SCFSubmitAmendment = 11,
  // Schedules privilages
  CreateSchedule = 12,
  EditSchedule = 13,
  RequestScheduleExtension = 14,
  GrantScheduleExtension = 15,
  CompleteSchedule = 16,
  CreateScheduleNote = 17,
  // Reminder Schedule privilages
  CreateReminder = 18,
  UpdateReminder = 19,
  DeleteReminder = 20,
  SubscribePeopleReminder = 21,
  // Suscribe Role Privilages
  SubscribePeopleRequestExtension = 22,
  ToxicGasEditor = 23,
  // Branchline status privilege
  BranchlineStatusToggle = 24,
  LinksCatalogView = 25,
  LinksCatalogCreate = 26,
  LinksCatalogModify = 27,
  LinksCatalogInactive = 28,
  LinksCatalogDelete = 29,
  RSSWA = 30,
  CopySCF = 31,
  RemoveSchedule = 32,
  RefuseScheduleExtension = 33,
  EditScheduleDates = 34,
  PPSTBNewForm = 35,
  PPSTBOperationalApproval = 36,
  PPSTBRequestAmendment = 37,
  PPSTBViewAmendment = 38,
  PPSTBEditAmendment = 39,
  // Equipment Bypass Privileges
  EBNewForm = 40,
  EBOperationalApproval = 41,
  EBRequestAmendment = 42,
  EBViewAmendment = 43,
  EBEditAmendment = 44,
  AdminDisplayMessages = 45,
  PPSTBFollowUp = 46,
  RPAnswerSCFS4 = 48,
  CompleteABSI = 49,
  CompleteEPS = 50,
  CompleteTOCA = 51,
  CompleteABCRS = 52,
  CompleteRSS = 53,
  RSSTestDBEdit = 58,
  RSSTestDBAuth = 59,
  BeamlineCatalog = 60,
  BlRequiringApprovalAdd = 62,
  BlRequiringApprovalEdit = 63,
  BlRequiringApprovalDelete = 64,
  BlRequiringApprovalApprove = 65,
  BlRequiringApprovalUnapprove = 66,
  RSSWACancel = 69,
  EBCancel = 71,
  PPSTBRepairReplaceComponent = 72,
  PPSTBModifyInterlock = 73,
  PPSTBOther = 74,
  ApproveABSITemplate = 75,
  CreateABSITemplate = 76,
  CancelABSIChecklist = 170,

  //#region RadMonitor
  RadMonitorSettings = 78,
  CreateRadMonitor = 79,
  CreateEditRadMonitorType = 80,
  CreateEditRadMonitorDescription = 81,
  CreateRadLocation = 82,
  CreateEditRadLocationType = 83,
  CreateEditRadLocationInterlock = 84,
  CreateRadMonitorNote = 85,
  CreateRadLocationNote = 86,
  CreateRadMonitorFaultNote = 112,
  DeleteRadMonitorNote = 113,
  DeleteRadLocationNote = 114,
  EditRadMonitor = 115,
  EditRadLocation = 116,
  DeleteRadMonitor = 117,
  DeleteRadLocation = 118,

  RSSWACancelAmendment = 120,
  RSSWASubmit = 160,

  DeleteRadMonitorFaultNote = 152,
  AddRadLocationEMShop = 153,
  //#endregion

  //#region Schedules
  EditABSI = 87,
  EditEPS = 88,
  EditTOCA = 89,
  EditABCRS = 90,
  EditRSS = 91,
  RequestExtensionABSI = 92,
  RequestExtensionEPS = 93,
  RequestExtensionTOCA = 94,
  RequestExtensionABCRS = 95,
  RequestExtensionRSS = 96,
  CreateNoteABSI = 97,
  CreateNoteEPS = 98,
  CreateNoteTOCA = 99,
  CreateNoteABCRS = 100,
  CreateNoteRSS = 101,
  AddReminderABSI = 102,
  AddReminderEPS = 103,
  AddReminderTOCA = 104,
  AddReminderABCRS = 105,
  AddReminderRSS = 106,
  EditReminderABSI = 107,
  EditReminderEPS = 108,
  EditReminderTOCA = 109,
  EditReminderABCRS = 110,
  EditReminderRSS = 111,
  EditScheduledFor = 119,
  CompleteSRVIS = 1009,
  EditSRVIS = 1010,
  CreateNoteSRVIS = 1011,
  EditScheduledForSRVIS = 1012,
  RequestExtensionSRVIS = 1013,
  EditReminderSRVIS = 1014,
  AddReminderSRVIS = 1015,
  //#endregion

  //#region RSSFunctionalTest
  RSSFunctionalTestCreate = 121,
  RSSFunctionalTestEdit = 123,
  RSSFunctionalTestDelete = 124,
  //#endregion

  //#region PC
  ALSProcedureCategory = 61,
  ALSProcedureActions = 67,
  ALSProcedureAddNew = 68,
  ALSProcedureSignatureAdmin = 70,
  ALSProcedureTrainingAdmin = 77,
  PCEPSPreparer = 125,
  PCEPSReviewer = 126,
  PCEPSApprover = 127,
  PCRSSPreparer = 128,
  PCRSSReviewer = 129,
  PCRSSApprover = 130,
  PCRADPreparer = 1501,
  PCRADReviewer = 1502,
  PCRADApprover = 1503,
  PCNotifications = 1504,
  PCViewRadTab = 1008,
  //#endregion

  //#region DocumentType
  CreateDocumentType = 131,
  ModifyDocumentType = 132,
  CreateTemplateType = 133,
  UpdateTemplateType = 134,
  DeleteTemplateType = 135,
  //#endregion

  //#region APL
  APLCategoryCreate = 136,
  APLCategoryApproval = 137,
  APLCategoryEdit = 138,
  APLCategoryDelete = 141,
  APLGroupCreate = 142,
  APLGroupApproval = 149,
  APLGroupEdit = 150,
  APLGroupDelete = 151,
  //#endregion

  AddEditBlOverviewComment = 139,
  DeleteBlOverviewComment = 140,

  //#region OlogCategory
  CreateOlogCategory = 143,
  UpdateOlogCategory = 144,
  DeleteOlogCategory = 145,
  CreateOlogEmail = 146,
  UpdateOlogEmail = 147,
  DeleteOlogEmail = 148,
  CreateOlogMessage = 154,
  UpdateOlogMessage = 155,
  DeleteOlogMessage = 156,
  //#endregion

  // Home
  BeamlineRestrictions = 200,
  BeamlineRestrictionsApprove = 201,

  //#region Reviews
  CreateReviewRequest = 600,
  EvaluateReviewRequest = 601,
  CreateReview = 602,
  ExecuteReviewActions = 603,
  ChangeReviewDate = 604,
  ChangeReviewTemplate = 605,
  ManageActionItemCategories = 606,
  ManageRosterCategories = 607,
  ManageDocumentCategories = 608,
  ChangeSettings = 609,
  AddRemoveRosterMembers = 610,
  AddRemoveActionItems = 611,
  AddRemoveDocuments = 612,
  CompleteActionItems = 613,
  AddBRCComments = 614,
  RegisterAttendance = 615,
  MemberConfirmation = 616,
  ReviewChecklistBLDevCheck = 617,
  ReviewChecklistBARCol = 618,
  CreateCustomResources = 619,
  //#endregion Reviews

  RadMetersCatalogChanges = 700,
  RadSurveyReviewer = 705,
  //#region SCFV2

  CreateHoldPoints = 800,
  CreateHoldPointsDraft = 801,

  CreateKEChecklist = 900,
  CancelKEChecklist = 901,

  AcceleratorCatalogCreate = 1000,
  AcceleratorCatalogApprove = 1001,

  UserCatalogCreate = 1002,
  UserCatalogApprove = 1003,

}
