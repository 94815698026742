import { Component, Input } from '@angular/core';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { utils } from 'src/app/modules/libs/utils';

@Component({
  selector: 'initials-chip',
  templateUrl: './initials-chip.component.html',
  styleUrls: ['./initials-chip.component.scss']
})
export class InitialsChipComponent {
  @Input() user: User | undefined | null;
  @Input() date: Date | undefined | null;
  @Input() disabled?: boolean = false;

  getTooltip(): string {
    return `<b>${this.user?.name}</b><br><span class="date">${utils.formatDatePipe(this.date, 'MM-dd-yyyy h:mm a')}</span>`;
  }

}
