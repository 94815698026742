import { Component, OnInit, Inject, ViewChild, NgZone, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Schedule } from '../../models/schedule';
import { ScheduleNote } from '../../models/schedule-note';
import { NoteType } from 'src/app/services/notes/note.model';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})
export class AddNoteComponent extends BaseComponent implements OnInit {

  descriptionCtrl = new FormControl();

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<AddNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Schedule
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      description: [null, Validators.required],
      scheduleId: [],
      userId: [],
      type: []
    });
    this.formGroup.controls['scheduleId'].setValue(this.data.id);
    this.formGroup.controls['type'].setValue(NoteType.Normal);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    let desc = this.descriptionCtrl.value as string;
    desc = desc.trim();
    if (!desc) {
      this.descriptionCtrl.setErrors({ required: true }); //.setErrors({invalid: true});
      return;
    }
    this.formGroup.value.description = desc;
    this.formGroup.value.createdOn = new Date();
    this.dialogRef.close(this.formGroup.value);
  }
}
