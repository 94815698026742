import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RSSWAResource } from '../../../rsswa';
import { RsswaService } from '../../../rsswa.service';
import { BaseComponent } from 'src/app/common/base/base.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { ChecklistBuilderPopupParameters, RSSFunctionalTestPopupComponent } from '../../rss-functional-test-popup/rss-functional-test-popup.component';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { RSSFunctionalTest } from '../../../rss-functional-test';
import { E } from '@angular/cdk/keycodes';

@Component({
  selector: 'rsswa-section1-functional-tests',
  templateUrl: './rsswa-section1-functional-tests.component.html',
  styleUrls: ['./rsswa-section1-functional-tests.component.scss']
})
export class RsswaSection1FunctionalTestsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() rsswa?: RSSWAResource | null;
  @Input() disabled: boolean = true;
  @Input() showAmendment?: boolean;

  @Input() chkFuncTestCtrl!: FormControl<any>;
  @Output() chkFuncTestCtrlChange = new EventEmitter<FormControl>();

  @Input() functionalTestCtrl!: FormControl<any>;
  @Output() functionalTestCtrlChange = new EventEmitter<FormControl>();

  @Input() provisionalTestingRequiredCtrl!: FormControl<any>;
  @Output() provisionalTestingRequiredCtrlChange = new EventEmitter<FormControl>();

  @Output() testCreated = new EventEmitter();

  rssFunctionalTests?: RSSFunctionalTest[];

  rssFunctionalTestCreatePrivilege?: boolean | null = false;

  constructor(
    protected override injector: Injector,
    public service: RsswaService,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.showAmendment)
      this.rssFunctionalTests = this.rsswa?.rssFunctionalTests;
    else this.rssFunctionalTests = this.rsswa?.rsswaAmendment?.rssFunctionalTests;
    this.enableControls();
  }

  ngOnInit(): void {
    this.rssFunctionalTestCreatePrivilege = this.hasPrivilege(PrivilegeEnum.RSSFunctionalTestCreate);
  }

  enableControls() {
    this.chkFuncTestCtrl.disable();
    this.functionalTestCtrl.disable();
    this.provisionalTestingRequiredCtrl.disable();

    if (!this.disabled) {
      this.chkFuncTestCtrl.enable();
      this.functionalTestCtrl.enable();
      this.provisionalTestingRequiredCtrl.enable();
    }
  }

  confirmRSSFunctionalTest(e: MatCheckboxChange) {
    if (e.checked) {
      this.dialog.open(YesNoDialogComponent, {
        width: '500px',
        data: {
          message: 'You will need to add at least one RSS functional test, do you want to continue?',
        },
      }).afterClosed().subscribe(data => {
        this.chkFuncTestCtrl.setValue(data);
      });
    }
    else this.chkFuncTestCtrl.setValue(false);
  }

  createRSSFunctionalTest() {
    this.dialog.open(RSSFunctionalTestPopupComponent, {
      width: '80vw',
      disableClose: true,
      autoFocus: false,
      data: {
        rsswa: this.rsswa,
        amendment: this.service.showAmendment,
      } as ChecklistBuilderPopupParameters,
    }).afterClosed().toPromise().then(result => {
      if (result && this.rsswa) {
        if (!this.showAmendment)
          this.rsswa.rssFunctionalTests?.push(result);
        else this.rsswa.rsswaAmendment?.rssFunctionalTests?.push(result);
        this.testCreated.emit();
      }
    });
  }

  edit(test: RSSFunctionalTest) {
    this.dialog.open(RSSFunctionalTestPopupComponent, {
      width: '80vw',
      data: {
        rsswa: this.rsswa,
        amendment: this.service.showAmendment,
        rssFunctionalTest: test
      } as ChecklistBuilderPopupParameters,
    }).afterClosed().toPromise();
  }

  showAlert(e: any) {
    this.alert.error(e);
  }



  remove(id: number) {
    let index = -1;
    if (!this.showAmendment) {
      index = this.rsswa?.rssFunctionalTests?.findIndex(t => t.id == id) as number;
      if (index >= 0)
        this.rsswa?.rssFunctionalTests?.splice(index, 1);
    }
    else {
      index = this.rsswa?.rsswaAmendment?.rssFunctionalTests?.findIndex(t => t.id == id) as number;
      if (index >= 0)
        this.rsswa?.rsswaAmendment?.rssFunctionalTests?.splice(index, 1);
    }
  }

}
