<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-12">
        <h5>{{ title }}</h5>
      </div>
      <button
        mat-mini-fab
        class="closex float-right"
        color="warn"
        (click)="close()"
        [disabled]="disabled || service.loading"
        *ngIf="!service.loading"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Review Request</mat-label>
          <input
            type="text"
            placeholder="Select Review Request"
            matInput
            name="reviewRequestCtrl"
            [formControl]="reviewRequestCtrl"
            [matAutocomplete]="autoReviewRequest"
            (keyup)="changedReviewRequest($event)"
          />
          <mat-autocomplete
            #autoReviewRequest="matAutocomplete"
            #reviewRequestAutocomplete
            [displayWith]="displayObject"
            (optionSelected)="selectedReviewRequest($event)"
          >
            <mat-option
              class="request-option"
              *ngFor="let reviewRequest of reviewRequestsFiltered"
              [value]="reviewRequest"
            >
              <div class="p-2 d-flex align-items-center">
                <div class="p-2">{{ reviewRequest.name }}</div>
                <div
                  class="small desc"
                  [innerHTML]="reviewRequest.description"
                ></div>
                <div
                  class="small loc p-2"
                  [innerHTML]="reviewRequest.location"
                ></div>
              </div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-12 mb-2">
        <mat-radio-group
          aria-label="Select an option"
          [(ngModel)]="reviewTypeEnum"
          [disabled]="false"
          name="opList"
        >
          <mat-radio-button
            *ngFor="let option of options"
            [value]="option.id"
            (change)="radioChanged($event.value)"
          >
            {{ option.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Review Type</mat-label>
          <input
            type="text"
            placeholder="Select Review Type"
            matInput
            name="reviewTypeCtrl"
            [formControl]="reviewTypeCtrl"
            [matAutocomplete]="autoReviewType"
          />
          <mat-autocomplete
            #autoReviewType="matAutocomplete"
            #reviewTypeAutocomplete
            [displayWith]="displayObject"
            (optionSelected)="selectedReviewType($event)"
          >
            <mat-option
              *ngFor="let reviewType of filterReviewTypes()"
              [value]="reviewType"
            >
              <span>({{ reviewType.code }}) {{ reviewType.name }}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Checklist Template</mat-label>
          <input
            type="text"
            placeholder="Select Checklist Template"
            matInput
            name="checklistTemplateCtrl"
            [formControl]="checklistTemplateCtrl"
            [matAutocomplete]="autoChecklistTemplate"
          />
          <mat-autocomplete
            #autoChecklistTemplate="matAutocomplete"
            #checklistTemplateAutocomplete
            [displayWith]="displaySerialNo"
          >
            <mat-option
              *ngFor="let checklistTemplate of checklistTemplatesFiltered"
              [value]="checklistTemplate"
            >
              <span>{{ checklistTemplate.serialNo }}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-12">
        <datetime-picker
          [dateTime]=""
          (changed)="dateChanged($event)"
        ></datetime-picker>
      </div>
    </div>
  </div>

  <div class="card-footer">
    <div class="row">
      <div class="col-12 text-right">
        <button
          mat-stroked-button
          color="warn"
          (click)="cancel()"
          [disabled]="service.loading"
        >
          Cancel
        </button>
        <button
          class="ml-2"
          mat-flat-button
          color="primary"
          (click)="accept()"
          [disabled]="!formValid() || service.loading"
        >
          Accept
        </button>
      </div>
    </div>
  </div>
</div>
