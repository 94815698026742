import { Component, Inject, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { ChecklistBuilderService } from 'src/app/components/checklists/checklist-builder/checklist-builder.service';
import { ChecklistTempComponent } from 'src/app/components/checklists/checklist-temp/checklist-temp.component';
import { TemplateType, ChecklistTemplate, TemplateRolePermission, Checklist } from 'src/app/components/checklists/checklists';
import { ChecklistsService } from 'src/app/components/checklists/checklists.service';
import { ScheduleStatusEnum } from 'src/app/components/schedules/models/enums';
import { Schedule } from 'src/app/components/schedules/models/schedule';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { RSSFunctionalTestService } from 'src/app/services/rss-functional-test/rss-functional-test.service';
import { WFSectionLocalResource } from 'src/app/services/work-flow/work-flow';
import { RSSFunctionalTest } from '../../rss-functional-test';
import { RSSWAResource } from '../../rsswa';
import { BaseComponent } from '../../../../common/base/base.component';
import { ResourceAutocompleteComponent } from 'src/app/controls/resources/resource-autocomplete/resource-autocomplete.component';
import { DocumentType } from 'src/app/services/documents/documents';
import { ChecklistBuilderDuplicateComponent } from 'src/app/components/checklists/checklist-builder/checklist-builder-duplicate/checklist-builder-duplicate.component';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TasksUpdateAll } from 'src/app/components/checklists/store/tasks/task.action';
import { StepError } from 'src/app/components/checklists/checklist-builder/checklist-builder.component';
import { ChecklistBuilderSectionsComponent } from 'src/app/components/checklists/checklist-builder/checklist-builder-sections/checklist-builder-sections.component';
import { TemplateTypesRefresh } from 'src/app/components/checklists/store/template-types/template-types.action';
import { FormControl } from '@angular/forms';
import { FormType } from 'src/app/common/enumerations/enumerations';
import { ChecklistTemplatesRefreshById } from 'src/app/components/checklists/store/checklist-templates/checklist-templates.action';

export class ChecklistBuilderPopupParameters {
  public rsswa!: RSSWAResource;
  public amendment?: boolean;
  public rssFunctionalTest?: RSSFunctionalTest;
}

@Component({
  selector: 'rss-functional-test-popup',
  templateUrl: './rss-functional-test-popup.component.html',
  styleUrls: ['./rss-functional-test-popup.component.scss']
})
export class RSSFunctionalTestPopupComponent extends BaseComponent implements OnInit, OnDestroy {
  documentType$!: Observable<DocumentType[]>;
  documentTypeSubscription!: Subscription;
  rssTestDocumentType!: DocumentType;
  rssFunctionalTestDocumentType!: DocumentType;

  checklistTemplate?: ChecklistTemplate;
  checklistTemplates$!: Observable<ChecklistTemplate[]>;
  checklistTemplatesSubs!: Subscription;

  templateType?: TemplateType;
  templateType$!: Observable<TemplateType[]>;
  templateTypeSubscription!: Subscription;
  templateTypes!: TemplateType[];

  schedule$!: Observable<Schedule[]>;
  scheduleSubscription!: Subscription;
  schedules!: Schedule[];

  rssTestTemplates: ChecklistTemplate[] = [];
  rssFunctionalTestTemplates: ChecklistTemplate[] = [];
  rssTestTemplatesDS = new MatTableDataSource<ChecklistTemplate>();
  rssFunctionalTestTemplatesDS = new MatTableDataSource<ChecklistTemplate>();
  checklistTemplateColumns: string[] = ['version', 'resource', 'type', 'duplicate'];

  loadingRSSTestTemplates = false;
  loadingRSSFunctionalTestTemplates = false;
  loadingShutters = false;
  loading = false;
  name!: string;
  sections?: WFSectionLocalResource[];

  rssFunctionalTest!: RSSFunctionalTest;
  resource?: Resource;
  resources: Resource[] = [];
  rssTestRolePermissions!: TemplateRolePermission[];
  rssFunctionalTestRolePermissions!: TemplateRolePermission[];

  nameCtrl = new FormControl();

  errorMessages?: StepError[];
  @ViewChild(ChecklistBuilderSectionsComponent) sectionsComponent!: ChecklistBuilderSectionsComponent;

  @ViewChild(ResourceAutocompleteComponent) resourceAutocomplete!: ResourceAutocompleteComponent;
  createFrom = '1';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ChecklistBuilderPopupParameters,
    public dialogRef: MatDialogRef<RSSFunctionalTestPopupComponent>,
    private checklistBuilderService: ChecklistBuilderService,
    private checklistService: ChecklistsService,
    private rssFunctionalTestService: RSSFunctionalTestService,
    protected override injector: Injector
  ) {
    super(injector);
    if (data.rssFunctionalTest) {
      this.rssFunctionalTest = data.rssFunctionalTest;
      this.checklistTemplate = this.rssFunctionalTest.checklistTemplate;
    }
    this.store.dispatch(new TemplateTypesRefresh());
  }

  ngOnInit(): void {
    this.loadingRSSTestTemplates = true;
    this.loadingRSSFunctionalTestTemplates = true;
    this.loadingShutters = true;
    this.loading = true;

    this.loadDocumentTypes();

    this.loadingShutters = false;
    this.loading = !(!this.loadingShutters && !this.loadingRSSTestTemplates && !this.loadingRSSFunctionalTestTemplates);

    this.getChecklistTemplates();
    this.loadChecklistTemplates();
  }

  override ngOnDestroy(): void {
    this.templateTypeSubscription?.unsubscribe();
    this.checklistTemplatesSubs?.unsubscribe();

    if (!this.rssFunctionalTest && this.checklistTemplate) {
      this.checklistBuilderService.deleteChecklistTemplate(this.checklistTemplate, 'RSS Functional Test not saved').subscribe();
    }
  }

  getChecklistTemplates() {
    this.checklistBuilderService.getChecklistTemplatesByDocumentTypeID(FormType.RSSTEST).subscribe(
      data => {
        this.rssTestTemplates.push(...data.filter(x => x.code == 'ACTIVE'));
        this.rssFunctionalTestTemplates.push(...data.filter(x => x.code == 'TEMP'));
        this.rssTestTemplatesDS.data = this.rssTestTemplates;
        this.rssFunctionalTestTemplatesDS.data = this.rssFunctionalTestTemplates;
      },
      error => this.alert.error(error.error),
      () => {
        this.loadingRSSTestTemplates = false;
        this.loading = !(!this.loadingShutters && !this.loadingRSSTestTemplates && !this.loadingRSSFunctionalTestTemplates);
      },
    );

    this.checklistBuilderService.getChecklistTemplatesByDocumentTypeID(FormType.RSSFT).subscribe(
      data => {
        this.rssFunctionalTestTemplates.push(...data.filter(x => x.code == 'ACTIVE' || x.code == 'TEMP'));
        this.rssTestTemplatesDS.data = this.rssTestTemplates;
        this.rssFunctionalTestTemplatesDS.data = this.rssFunctionalTestTemplates;
      },
      error => this.alert.error(error.error),
      () => {
        this.loadingRSSFunctionalTestTemplates = false;
        this.loading = !(!this.loadingShutters && !this.loadingRSSTestTemplates && !this.loadingRSSFunctionalTestTemplates);
      },
    );

    this.checklistBuilderService.getTemplateRolePermissionsByDocumentTypeID(FormType.RSSTEST).subscribe(
      data => this.rssTestRolePermissions = data.filter(x => x.templateTypeID === 0),
      error => this.alert.error(error.error),
    );

    this.checklistBuilderService.getTemplateRolePermissionsByDocumentTypeID(FormType.RSSFT).subscribe(
      data => {
        this.rssFunctionalTestRolePermissions = data.filter(x => x.templateTypeID === 0);
        this.checklistBuilderService.documentRolePermissions = this.rssFunctionalTestRolePermissions;
      },
      error => this.alert.error(error.error),
    );
  }

  loadChecklistTemplates() {
    this.checklistTemplates$ = this.store.select(state => state.ChecklistTemplates.data);
    this.checklistTemplatesSubs = this.checklistTemplates$.subscribe(data => {
      if (data?.length && this.checklistTemplate) {
        const checklistTemplate = data.find(t => t.id == this.checklistTemplate?.id);
        if (checklistTemplate) {
          this.checklistTemplate = checklistTemplate;
          this.setValues();
        }
      }
    });
  }

  loadDocumentTypes() {
    this.documentType$ = this.store.select(state => state.DocumentType.data);
    this.documentTypeSubscription = this.documentType$.subscribe(documentTypes => {
      if (documentTypes) {
        this.rssTestDocumentType = documentTypes?.filter(x => x.id == FormType.RSSTEST)[0];
        this.rssFunctionalTestDocumentType = documentTypes?.filter(x => x.id == FormType.RSSFT)[0];
        this.loadTemplateTypes();
      }
    });
  }

  loadTemplateTypes() {
    this.templateType$ = this.store.select(state => state.TemplateTypes.data);
    this.templateTypeSubscription = this.templateType$.subscribe(templateTypes => {
      if (templateTypes) {
        this.templateTypes = templateTypes?.filter(x => x.status == 1 && x.documentTypeID == FormType.RSSFT);
        this.loadSchedules();
      }

    });
  }

  loadSchedules() {
    this.resources = [];
    this.schedule$ = this.store.select(state => state.Schedules.data);
    this.scheduleSubscription = this.schedule$.subscribe(schedules => {
      this.schedules = schedules.filter(x => x.typeId == 5 && (x.statusId == ScheduleStatusEnum.new || x.statusId == ScheduleStatusEnum.extensionGranted || x.statusId == ScheduleStatusEnum.refuseExtension || x.statusId == ScheduleStatusEnum.requestExtension));

      this.schedules.map(schedule => {
        schedule.name = schedule.scheduleResources.map(val => {
          const names = [];
          if (!val.resourceId && !val.resourceString?.includes('(EC')) {
            names.push(val.resourceString);
          } else {
            if (val.resource) {
              names.push(val.resource.name);
            }
          }
          return names;
        }).join('');
        schedule.description = schedule.scheduleResources.map(val => {
          const descs = [];
          if (!val.resourceId && !val.resourceString?.includes('(EC')) {
            descs.push(val.resourceString);
          } else {
            if (val.resource) {
              descs.push(val.resource.description);
            }
          }
          return descs;
        }).join('');

        const res = schedule.scheduleResources.filter(x => x.resource).map(x => x.resource);
        res.map(r => {
          if (r)
            this.resources.push(r);
        });
      });
      this.resources.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name));
      if (this.checklistTemplate) this.setValues();
    });
  }

  createTemplate() {
    const checklistTemplate = this.checklistBuilderService.getNewChecklistTemplate(this.rssFunctionalTestDocumentType, this.templateType, null, this.resource, null, '');
    checklistTemplate.status = -1;
    checklistTemplate.name = this.name;

    this.loading = true;
    this.checklistBuilderService.createChecklistTemplate(checklistTemplate).subscribe(
      data => {
        this.checklistTemplate = data;
        this.setValues();
      },
      error => this.alert.error(error.error),
      () => this.loading = false,
    );
  }

  checkEnableCreate() {
    var enable = true;
    if (!this.name) {
      enable = false;
    }

    if (!this.templateType) {
      enable = false;
    }

    if (this.templateType?.resourceTypeID && !this.resource?.id) {
      enable = false;
    }
    return !enable;
  }

  duplicate(checklistTemplate: ChecklistTemplate) {
    switch (checklistTemplate.documentTypeID) {
      case FormType.RSSTEST:
        this.duplicateRSSTest(checklistTemplate);
        break;

      case FormType.RSSFT:
        this.duplicateRSSFunctionalTest(checklistTemplate);
        break;
    }
  }

  duplicateRSSTest(checklistTemplate: ChecklistTemplate) {
    const schedule = this.schedules.filter(x => x?.scheduleResources?.filter(x => x?.resourceId == this.resource?.id).length > 0)[0];
    const schedules = this.schedules;
    this.dialog.open(ChecklistBuilderDuplicateComponent, {
      data: {
        templateType: checklistTemplate?.templateType,
        documentType: this.rssTestDocumentType,
        templateVersion: checklistTemplate,
        resource: checklistTemplate?.resource,
        schedule,
        schedules,
        documentRolePermissions: this.rssTestRolePermissions,
        temp: true,
        documentTypeID: FormType.RSSFT
      },
      disableClose: true,
      autoFocus: false,
      width: '60vw'
    }).afterClosed().subscribe(
      data => {
        if (data?.duplicatedVersion) {
          this.checklistTemplate = data.duplicatedVersion;
          this.store.dispatch(new ChecklistTemplatesRefreshById(this.checklistTemplate!.id));
        }
      }
    );
  }

  duplicateRSSFunctionalTest(checklistTemplate: ChecklistTemplate) {
    this.dialog.open(ChecklistBuilderDuplicateComponent, {
      data: {
        templateType: checklistTemplate?.templateType,
        documentType: this.rssFunctionalTestDocumentType,
        templateVersion: checklistTemplate,
        resource: checklistTemplate?.resource,
        resources: this.resources,
        documentRolePermissions: this.rssFunctionalTestRolePermissions,
        temp: true,
        documentTypeID: FormType.RSSFT
      },
      disableClose: true,
      autoFocus: false,
      width: '60vw'
    }).afterClosed().subscribe(
      data => {
        if (data?.duplicatedVersion) {
          this.checklistTemplate = data.duplicatedVersion;
          this.store.dispatch(new ChecklistTemplatesRefreshById(this.checklistTemplate!.id));
        }
      }
    );
  }

  createRSSFunctionalTest() {
    if (!this.rssFunctionalTest) {
      this.loading = true;
      const rssFunctionalTest: RSSFunctionalTest = {
        name: this.name,
        rsswaID: this.data.rsswa.id,
        checklistTemplateID: this.checklistTemplate?.id ?? 0,
      };

      this.rssFunctionalTestService.create(rssFunctionalTest, this.data.amendment).subscribe(
        data => {
          this.rssFunctionalTest = data;
          this.setFormDirty(false);
          this.dialogRef.close(data);
        },
        error => {
          this.alert.error(error.error);
          this.loading = false;
        },
        () => this.loading = false,
      );
    }
    else this.dialogRef.close();
  }

  openViewMode(checklistTemplate: ChecklistTemplate) {
    this.dialog.open(ChecklistTempComponent, {
      minWidth: '60vw',
      disableClose: true,
      data: {
        checklistTemplate,
      }
    })
      .afterClosed().toPromise().then((checklist: Checklist) => {
        if (checklist) {
          this.checklistService.deleteChecklist(checklist.id).toPromise();
        }
      });
  }

  setValues() {
    this.loading = true;
    setTimeout(() => {
      if (this.checklistTemplate) {
        this.checklistBuilderService.getchecklistTemplateById(this.checklistTemplate.id).toPromise().then(data => {
          this.checklistTemplate = data;
          this.storeTasks();
          this.getErrors();
          this.name = (this.checklistTemplate?.name != '' ? this.checklistTemplate?.name : this.rssFunctionalTest?.name) ?? '';
          this.templateType = this.templateTypes?.find(t => t.id == this.checklistTemplate?.templateTypeID);
          this.resource = this.resources.find(r => r.id == this.checklistTemplate?.resourceID);
          this.cdRef.detectChanges();
          if (this.resource) {
            const selection = { option: { value: this.resource } } as MatAutocompleteSelectedEvent;
            this.resourceAutocomplete?.select(selection);
          }
          if (this.checklistTemplate?.resourceID) {
            if (this.checklistTemplate.resourceID <= 0) {
              this.checklistTemplate.resourceID = null;
            }
          }
          this.sections = this.checklistTemplate?.wfTable?.wfTableLocal?.wfSectionLocals?.sort((a, b) => this.utils.sort(a.order, b.order));
          this.loading = false;
        });
      }
    }, 300);
  }

  storeTasks() {
    const wfTaskLocals =
      this.checklistTemplate?.wfTable?.wfTableLocal?.wfSectionLocals?.flatMap(
        (s) => s.wfStepLocals?.flatMap((z) => z.wfTaskLocals ?? []) ?? []
      ) ?? [];

    this.store.dispatch(new TasksUpdateAll(wfTaskLocals));
  }

  getErrors() {
    setTimeout(() => {
      this.errorMessages = [];
      this.sectionsComponent?.sectionComponents.filter(s => !s.section.logID).map((s) => {
        s.stepsComponent?.stepComponents.filter(z => !z.step.logID).map((x) => {
          if (x.statusError) {
            const statusErrorMessage =
              'Wrong status Settings on Step: ' +
              s.section.number +
              ' ' +
              s.section.name +
              ', Step: ' +
              (x.step.name ? x.step.name : 'Unnamed Step');
            this.errorMessages?.push({
              stepId: x.step.id,
              text: statusErrorMessage,
            });
          }
          if (!x.enabledOn || x.enabledOn.length == 0) {
            this.errorMessages?.push({
              stepId: x.step.id,
              text:
                'No Status Set on Step: ' +
                s.section.number +
                ' ' +
                s.section.name +
                ', Step: ' +
                (x.step.name ? x.step.name : 'Unnamed Step'),
            });
          }
          x.tasksComponent?.taskComponents.filter(t => !t.task.logID).map((t) => {
            if (t.hasErrors()) {
              const errorMessage =
                t.errorMessage +
                ' in Section: ' +
                s.section.number +
                ' ' +
                s.section.name +
                ', Step: ' +
                x.step.name +
                ', Task: ' +
                t.task.name;
              this.errorMessages?.push({
                stepId: x.step.id,
                taskId: t.task.id,
                text: errorMessage,
              });
            }
          });
        });
      });
    }, 1000);
  }

  async cancel() {
    if (this.rssFunctionalTest || (await this.canDeactivate())) {
      this.dialogRef.close();
    }
  }


}
