import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RSSWAResource, SaveRSSWAResource, SaveRSSWAStatusResource } from './rsswa';
import { RefreshFormStatus } from 'src/app/services/forms/refresh-form-status';
import { FormType } from 'src/app/common/enumerations/enumerations';
import { BaseService } from 'src/app/common/base/base.service';
import { utils } from 'src/app/modules/libs/utils';

@Injectable({
  providedIn: 'root'
})
export class RsswaService extends BaseService<RSSWAResource | SaveRSSWAResource, number> {
  showAmendment: boolean = false;

  SerialNumber = '/SerialNumbers/' + FormType.RSSWA;
  Api = '/rsswa';

  constructor(
    protected override injector: Injector
  ) {
    super(injector, '/RSSWA');
  }

  getSerialNo(): Observable<SerialNumber> {
    const httpresult1 = this.http.get<SerialNumber>(this.BASE_URL + this.SerialNumber);
    return httpresult1;
  }

  deleteSerialNo(serialNo?: string) {
    return this.http.delete(this.BASE_URL + this.Api + '/SerialNo/' + serialNo);
  }

  getOpen(): Observable<RSSWAResource[]> {
    const httpresult = this.http.get<RSSWAResource[]>(this.BASE_URL + this.Api + '/Open');
    return httpresult;
  }

  getBySerialNo(sn: string): Observable<RSSWAResource> {
    const httpresult = this.http.get<RSSWAResource>(this.BASE_URL + this.Api + '/SerialNo/' + sn);
    return httpresult;
  }

  getStatus(id: number): Observable<RefreshFormStatus> {
    const httpresult = this.http.get<RefreshFormStatus>(this.BASE_URL + this.Api + '/GetStatus/' + id);
    return httpresult;
  }

  putStatus(saveRsswaResource: SaveRSSWAStatusResource) {
    return this.http.put<SaveRSSWAStatusResource>(this.BASE_URL + this.Api + '/Status/' + saveRsswaResource.id, saveRsswaResource);
  }

  requestAmendment(id: number): Observable<any> {
    return this.http.put<any>(this.BASE_URL + this.Api + '/RequestAmendment/' + id.toString(), null);
  }

  // finishAmendment(id: number): Observable<any> {
  //   return this.http.put<any>(this.BASE_URL + this.Api + '/FinishAmendment/' + id.toString(), null);
  // }

  setEditing(id: number, value?: boolean): Observable<number> {
    const URL = this.BASE_URL + this.Api + '/SetEditing/' + id;
    const httpresult2 = this.http.put<number>(URL, { Value: value });
    return httpresult2;
  }

  copyRSSWA(id: number, isAmendment: boolean): Observable<RSSWAResource> {
    return this.http.post<RSSWAResource>(this.BASE_URL + this.Api + '/copy/' + id + '/' + isAmendment, null);
  }

  isRequester(rsswa?: RSSWAResource | null) {
    return this.currentUser && (rsswa?.requesterId == this.currentUser?.id || utils.getAllRequesters(rsswa?.wfTable).map(u => u.id == this.currentUser?.id));
  }
}

interface SerialNumber {
  serialNo: string;
}
