<div class="d-flex" [ngClass]="{ 'align-chips-start': alignLeft }">
  <mat-chip-row
    *ngFor="let item of filteredRoles; let i = index"
    class="role"
    [matTooltip]="getTooltip(item)"
    [matTooltipDisabled]="false"
  >
    {{ item.code }}
  </mat-chip-row>
</div>
