import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScheduleStatusEnum } from '../../models/enums';
import { Schedule } from '../../models/schedule';
import { ScheduleNote } from '../../models/schedule-note';
import { NoteType } from 'src/app/services/notes/note.model';

@Component({
  selector: 'app-reject-extension',
  templateUrl: './reject-extension.component.html',
  styleUrls: ['./reject-extension.component.scss']
})
export class RejectExtensionComponent implements OnInit {
  formNote!: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<RejectExtensionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Schedule) { }

  ngOnInit(): void {
    this.formNote = this.formBuilder.group({
      description: ['', [Validators.required, this.noWhitespaceValidator]],
      scheduleId: [],
      isExtension: [],
      userId: []
    });
    this.formNote.controls['scheduleId'].setValue(this.data.statusId);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    const note = this.formNote.value as ScheduleNote;
    if (note.description.trim() === '' || note.description.trim() == null) {
      this.formNote.controls['description'].setErrors({ required: true });
      return;
    }
    note.type = NoteType.Rejection;
    this.data.notes.push(note);
    this.data.statusId = ScheduleStatusEnum.refuseExtension;
    this.dialogRef.close(this.data);
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public header(data: any) {
    return data.replace(/\<br>/gi, ", ");
  }
}
