import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef, ViewChildren, ViewContainerRef, QueryList, OnDestroy, Output, EventEmitter, Injector, SimpleChanges } from '@angular/core';
import { CompleteScheduleComponent } from './complete-schedule/complete-schedule.component';
import { ScheduleValidations } from './../share/schedule-validations';
import { AddScheduleComponent } from './add-schedule/add-schedule.component';
import { AddNoteComponent } from './add-note/add-note.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { TokenInfoService } from 'src/app/services/authentication/token-info.service';
import { Procedure } from 'src/app/components/procedure/models/procedure.model';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { AbsiService } from '../../absi/absi.service';
import { CatalogService } from '../../catalogs/catalog-service';
import { ProcedureRefresh } from '../../procedure/store/procedure/procedure.action';
import { ScheduleStatusEnum, ScheduleStatusTimeEnum, } from '../models/enums';
import { Schedule, ScheduleSubdetail } from '../models/schedule';
import { ScheduleNote } from '../models/schedule-note';
import { ScheduleResourcesByTab, ScheduleResources } from '../models/schedule-resources';
import { ScheduleSubtype } from '../models/schedule-subtype';
import { ScheduleType } from '../models/schedule-type';
import { ScheduleService } from '../services/schedule.service';
import { SchedulesCreate, SchedulesUpdate, SchedulesDelete } from '../store/schedules/schedule.action';
import { EditScheduledForComponent } from './edit-scheduled-for/edit-scheduled-for.component';
import { RejectExtensionComponent } from './reject-extension/reject-extension.component';
import { RequestExtensionComponent } from './request-extension/request-extension.component';
import { ScheduleNoteService } from '../services/schedule-note.service';
import { ScheduleSubdetailNoteService } from '../services/schedule-subdetail-note.service';
import { ScheduleSubTypeService } from '../services/schedule-subtype.service';
import * as moment from 'moment';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { Note, NoteType } from 'src/app/services/notes/note.model';
import { utils } from 'src/app/modules/libs/utils';

@Component({
  selector: 'app-schedule-tab',
  templateUrl: './schedule-tab.component.html',
  styleUrls: ['./schedule-tab.component.scss']
})
export class ScheduleTabComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() showCompleted!: boolean;
  @Input() scheduleType?: ScheduleType;
  @Input() scheduleSubtype!: ScheduleSubtype;
  @Input() showFilter: boolean = true;
  @Input() showHeader: boolean = false;
  @Input() readOnly!: boolean;
  @Input() refreshData!: boolean;
  @Input() tabIndex!: number;
  @Input() tabName!: string;
  @Input() filter!: string;
  @Output() locations = new EventEmitter<ScheduleResourcesByTab>();
  @Output() loading = new EventEmitter<boolean>();

  @ViewChild('scheduleTable')
  scheduleTable!: ElementRef;

  tableWidth = '';
  displayedColumnsSchedule: string[] = ['resource', 'procedureNumber', 'lastDate', 'dueDate', 'scheduleDate', 'extendedDate', 'notes', 'status', 'options'];
  schedulesData!: Schedule[];


  // @Input() scheduleTab: ScheduleCustomTab;
  dataSource = new MatTableDataSource<Schedule>();
  ScheduleStatus = ScheduleStatusEnum;
  ScheduleStatusTime = ScheduleStatusTimeEnum;
  ScheduleNoteType = NoteType;

  resourceType!: number;
  // @ViewChild('typeContainer') typeContainer: ElementRef;
  currentColor!: string;
  currentSubColor!: string;
  iteraciones = 0;
  showTypeCol = false;
  allSubtypes: ScheduleSubtype[] = [];
  sv = ScheduleValidations;
  messageTime = 3000;
  headerOnTop = false;
  closeToTop = false;
  maxHeight = null;

  procedures!: Procedure[];
  procedures$!: Observable<Procedure[]>;
  procedureSubscription!: Subscription;
  functionalKeyWord = 'Functional';
  hutchKeyWord = 'Hutch';
  locationsByTab!: ScheduleResourcesByTab;
  // locations: Resource[];
  resources!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  schedules!: Schedule[];
  schedules$!: Observable<Schedule[]>;
  schedulesSubs!: Subscription;

  selectedRow?: number;
  dateFormat?: string;

  @ViewChildren('matrow', { read: ViewContainerRef })
  rows!: QueryList<ViewContainerRef>;

  constructor(
    protected override injector: Injector,
    private serviceSchedule: ScheduleService,
    private serviceNotes: ScheduleNoteService,
    private serviceSubdetailNotes: ScheduleSubdetailNoteService,
    public tokenInfo: TokenInfoService,
    public subtypeService: ScheduleSubTypeService,
    private catalogService: CatalogService,
  ) {
    super(injector);
    // Schedule
    this.changeStatus = this.changeStatus.bind(this);
    this.openEditSchedule = this.openEditSchedule.bind(this);
    this.deleteNote = this.deleteNote.bind(this);
    this.deleteNoteSub = this.deleteNoteSub.bind(this);
    this.openAddNote = this.openAddNote.bind(this);
    this.openCompleteSchedule = this.openCompleteSchedule.bind(this);
    this.validateAction = this.validateAction.bind(this);
    this.grantExtension = this.grantExtension.bind(this);
    this.deleteSchedule = this.deleteSchedule.bind(this);
    this.openEditScheduledFor = this.openEditScheduledFor.bind(this);

    //ScheduleSubdetail
    this.requestRejectExtensionSubdetail = this.requestRejectExtensionSubdetail.bind(this);
    this.grantExtensionSubdetail = this.grantExtensionSubdetail.bind(this);
    this.openAddNoteSub = this.openAddNoteSub.bind(this);
    this.deleteNoteSub = this.deleteNoteSub.bind(this);
    this.openCompleteSubdetail = this.openCompleteSubdetail.bind(this);

  }

  override ngOnDestroy(): void {
    this.procedureSubscription?.unsubscribe();
    this.resourcesSubs?.unsubscribe();
    this.procedureSubscription?.unsubscribe();
    this.schedulesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  refreshLocations() {
    this.resources$ = this.store.select(state => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe(data => {
      const resourceTypesArray =
        this.scheduleSubtype ?
          this.scheduleSubtype?.resourceTypeIds ? JSON.parse(this.scheduleSubtype?.resourceTypeIds).map(Number) :
            (this.scheduleType?.resourceTypeIds ?
              JSON.parse(this.scheduleType?.resourceTypeIds).map(Number) : new Array()) : this.scheduleType?.resourceTypeIds ?
            JSON.parse(this.scheduleType?.resourceTypeIds).map(Number) : new Array();
      this.locationsByTab = {
        tabId: this.tabIndex,
        scheduleType: this.scheduleType,
        scheduleSubtype: this.scheduleSubtype,
        resources: data.filter(x => resourceTypesArray.includes(x.type)).sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name)).filter(x => !this.serviceSchedule.locationIDs.includes(x.id))
      } as ScheduleResourcesByTab;
      this.locations.emit(this.locationsByTab);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabIndex']) {
      if (!this.tabIndex) {
        this.headerOnTop = false;
        this.closeToTop = false;
        if (this.tableWidth === '0px') {
          this.tableWidth = '100%';
        }
      }
    }
    this.getSchedules();
    this.refreshLocations();
    this.showTypeCol = this.scheduleType?.id == 1 || this.scheduleType?.id == 5 || !this.scheduleType?.tabColumns;
  }

  ngOnInit(): void {
    this.loadProcedures();
    this.refreshLocations();
    this.dataSource.filterPredicate = (data, filter) => {
      filter = filter.toLowerCase();

      if (this.functionalKeyWord.toLowerCase().includes(filter) && data.subdetail != null && data.subtype?.name?.toLowerCase().includes(this.hutchKeyWord.toLowerCase())) {
        return data.subtype?.name?.toLowerCase().includes(this.hutchKeyWord.toLowerCase());
      }
      return data.name?.toLowerCase().includes(filter) ||
        data.procedure?.procedureNumber?.toLowerCase().includes(filter) ||
        data.subtype?.name?.toLowerCase().includes(filter) ||
        this.ScheduleStatusTime[data.statusTime].toLowerCase().includes(filter);
    };
  }

  loadProcedures() {
    this.procedures$ = this.store.select(state => state.Procedures.data);
    this.procedureSubscription = this.procedures$.subscribe(procedures => {
      if (procedures) {
        this.procedures = procedures;
        this.updateScheduleData(this.schedulesData);
      }
      // else {
      //   this.store.dispatch(new ProcedureRefresh());
      // }
    });
  }

  getRowPosition(row: any): number {
    let i = this.dataSource.data.findIndex(r => {
      return r.id == row.id;
    });
    return i;
  }

  showIndex(id: number) {
    const element = this.dataSource.data.findIndex(x => x.id == id);
    setTimeout(() => {
      this.showElement(element, 7);
      this.loading.emit(false);
    }, 300);
  }

  showElement(index: number, height: number) {
    const indexstr = index.toString();
    const row = this.rows.find(row => row.element.nativeElement.getAttribute('position') === indexstr);
    if (row != null) {
      const rect = row.element.nativeElement.getBoundingClientRect();
      if ((rect.y <= 0) || ((rect.y + rect.height) > height)) {
        row.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
      }
      return;
    }
    console.log('There was not possible scroll to the element specified. Element not found.');
  }

  changeSort(event: any) {
    let result: Schedule[] = [];
    switch (event.active) {
      case 'resource':
        switch (event.direction) {
          case 'asc':
            result = this.dataSource.data.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name));
            break;
          case 'desc':
            result = this.dataSource.data.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name)).reverse();
            break;
          default:
            result = this.dataSource.data.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name));
            break;
        }
        break;
      case 'procedureNumber':
        switch (event.direction) {
          case 'asc':
            result = this.dataSource.data.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.objectType, b.objectType));
            break;
          case 'desc':
            result = this.dataSource.data.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.objectType, b.objectType)).reverse();
            break;
          default:
            result = this.dataSource.data.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.objectType, b.objectType));
            break;
        }
        break;
      case 'dueDate':
        switch (event.direction) {
          case 'asc':
            result = this.sortByDueDate(this.dataSource.data, 'asc');
            break;
          case 'desc':
            result = this.sortByDueDate(this.dataSource.data, 'desc');
            break;
          default:
            result = this.dataSource.data.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name));
            break;
        }
        break;
      case 'lastDate':
        switch (event.direction) {
          case 'asc':
            result = this.dataSource.data.sort((a, b) => moment(a.lastCompleted).isAfter(b.lastCompleted) ? 1 : -1);
            break;
          case 'desc':
            result = this.dataSource.data.sort((a, b) => moment(a.lastCompleted).isAfter(b.lastCompleted) ? -1 : 1);
            break;
          default:
            result = this.dataSource.data.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name));
            break;
        }
        break;
      case 'scheduleDate':
        switch (event.direction) {
          case 'asc':
            result = this.dataSource.data.sort((a, b) => !a.scheduleFor ? 1 : !b.scheduleFor ? -1 : a.scheduleFor > b.scheduleFor ? 1 : -1);
            break;
          case 'desc':
            result = this.dataSource.data.sort((a, b) => !a.scheduleFor ? 1 : !b.scheduleFor ? -1 : a.scheduleFor > b.scheduleFor ? -1 : 1);
            break;
          default:
            result = this.dataSource.data.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name));
            break;
        }
        break;
      case 'extendedDate':
        switch (event.direction) {
          case 'asc':
            result = this.dataSource.data.sort((a, b) => moment(a.extendedDate).isAfter(b.extendedDate) ? 1 : -1);
            break;
          case 'desc':
            result = this.dataSource.data.sort((a, b) => moment(a.extendedDate).isAfter(b.extendedDate) ? -1 : 1);
            break;
          default:
            result = this.dataSource.data.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name));
            break;
        }
        break;
      case 'status':
        switch (event.direction) {
          case 'asc':
            result = this.dataSource.data.sort((a, b) => ScheduleStatusTimeEnum[a.statusTime].localeCompare(ScheduleStatusTimeEnum[b.statusTime]));
            break;
          case 'desc':
            result = this.dataSource.data.sort((a, b) => ScheduleStatusTimeEnum[b.statusTime].localeCompare(ScheduleStatusTimeEnum[a.statusTime]));
            break;
          default:
            result = this.dataSource.data.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name));
            break;
        }
        break;
      case 'subType':
        switch (event.direction) {
          case 'asc':
            result = this.dataSource.data.sort((a, b) => this.utils.sort(a.scheduleSubtypeId, b.scheduleSubtypeId));
            break;
          case 'desc':
            result = this.dataSource.data.sort((a, b) => this.utils.sort(a.scheduleSubtypeId, b.scheduleSubtypeId, false));
            break;
          default:
            result = this.dataSource.data.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name));
            break;
        }
        break;
    }
    this.dataSource.data = result;
  }

  applyFilterSchedule() {
    const filterValue = this.filter;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getLocationsByTab(): ScheduleResources[] {
    return this.locationsByTab.resources.map(res => {
      return { resource: res } as ScheduleResources;
    });
  }

  addSchedule() {
    const allLocations: ScheduleResources[] = this.getLocationsByTab();
    const dialogRef = this.dialog.open(AddScheduleComponent, {
      width: '40vw',
      data: { scheduleType: this.scheduleType, scheduleSubtype: this.scheduleSubtype, allLocations, absiType: null, schedule: null, scheduleLocationsId: this.serviceSchedule.locationIDs }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading.emit(true);
        this.serviceSchedule.create(result).subscribe(response => {
          if (response) {
            this.selectedRow = response.id;
            this.acquaintSchedule(response);
            this.schedulesData.push(response);

            response.scheduleResources.map(scheduleResource => {
              if (scheduleResource.resourceId) {
                this.serviceSchedule.locationIDs.push(scheduleResource.resourceId);
              }
            });
            this.updateScheduleData(this.schedulesData);
            this.alert.message('create', [new MessagePlaceholder('{what}', 'Schedule')]);
            this.store.dispatch(new SchedulesCreate(response));
          }
        });
      }
    });
  }

  openAddNote(schedule: Schedule) {
    const dialogRef = this.dialog.open(AddNoteComponent, {
      width: '20em',
      data: schedule
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedRow = schedule.id;
        this.loading.emit(true);
        this.serviceNotes.create(result).subscribe(response => {
          if (response) {
            schedule.notes.push(response);
            this.updateScheduleData(this.dataSource.data);
            this.alert.message('add', [new MessagePlaceholder('{what}', 'Note')]);
            this.loading.emit(false);
          }
        });
      }
    });
  }

  openAddNoteSub(schedule: Schedule) {
    const dialogRef = this.dialog.open(AddNoteComponent, {
      width: '20em',
      data: schedule
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedRow = schedule.id;
        this.loading.emit(true);
        result.scheduleSubdetailId = schedule.subdetail?.id;
        this.serviceSubdetailNotes.create(result).subscribe(response => {
          if (response) {
            // if (schedule.subdetail && !schedule.subdetail?.notes) {
            //   schedule.subdetail.notes = [];
            // }
            schedule.subdetail?.notes.push(response);
            // this.updateScheduleData(this.dataSource.data);
            this.alert.message('add', [new MessagePlaceholder('{what}', 'Note')]);
            this.loading.emit(false);
          }
        });
      }
    });
  }

  deleteNote(schedule: Schedule, note: Note) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: "500px", data: {
        message: this.getMessage('Confirm_Note_Delete_Question').description,
        icon: "stop",
      },
    });
    confirm.afterClosed().subscribe(async (data) => {
      if (data) {
        this.selectedRow = schedule.id;
        this.loading.emit(true);
        this.serviceNotes.delete(note.id).subscribe(response => {
          if (response) {
            // schedule.notes = (schedule.notes as Note[])?.filter((n: { id: number }) => n.id !== note.id);
            // this.updateScheduleData(this.dataSource.data);
            this.alert.message('CommentDeleted', [new MessagePlaceholder('{what}', 'Note')]);
          }
          this.loading.emit(false);
        });
      }
    });

  }

  deleteNoteSub(schedule: Schedule, note: Note) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: "500px", data: {
        message: this.getMessage('Confirm_Note_Delete_Question').description,
        icon: "stop",
      },
    });
    confirm.afterClosed().subscribe(async (data) => {
      if (data) {
        this.selectedRow = schedule.id;
        this.loading.emit(true);
        this.serviceSubdetailNotes.delete(note.id).subscribe(response => {
          if (response && schedule.subdetail) {
            schedule.subdetail.notes = (schedule.subdetail?.notes as Note[])?.filter((n: { id: number }) => n.id !== note.id);
            this.updateScheduleData(this.dataSource.data);
            this.alert.message('CommentDeleted', [new MessagePlaceholder('{what}', 'Note')]);
          }
          this.loading.emit(false);
        });
      }
    });
  }

  async openRequestExtension(schedule: Schedule | ScheduleSubdetail | null): Promise<Schedule | ScheduleSubdetail | null> {
    const dialogRef = this.dialog.open(RequestExtensionComponent, {
      width: '20em',
      data: schedule
    });
    const resultSch = await dialogRef.afterClosed().toPromise();
    this.selectedRow = schedule?.id;
    if (resultSch) {
      if (typeof (resultSch) === 'string') {
        this.alert.error(resultSch);
        return null;
      }
    }
    return resultSch;
  }

  async getSubtypes() {
    this.allSubtypes = (await this.subtypeService.getAll().toPromise()) ?? [];
  }

  async openRejectExtension(schedule: Schedule | ScheduleSubdetail | null): Promise<Schedule | ScheduleSubdetail | null> {
    const dialogRef = this.dialog.open(RejectExtensionComponent, {
      width: '20em',
      data: schedule
    });
    const resultSch = await dialogRef.afterClosed().toPromise();
    this.selectedRow = schedule?.id;
    if (resultSch) {
      if (typeof (resultSch) === 'string') {
        this.alert.error(resultSch);
        return null;
      }
    }
    return resultSch;
  }

  openEditScheduledFor(schedule: Schedule) {
    const dialogRef = this.dialog.open(EditScheduledForComponent, {
      data: schedule
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedRow = schedule?.id;
        this.loading.emit(true);
        const scheduleIndex = this.schedulesData.indexOf(schedule);
        this.serviceSchedule.update(result).toPromise().then(response => {
          if (response) {
            const schedule = response as Schedule;
            schedule.scheduleResources.map(scheduleResource => {
              if (scheduleResource.resourceId) {
                this.serviceSchedule.locationIDs.splice(this.serviceSchedule.locationIDs.indexOf(scheduleResource.resourceId), 1);
              }
            });
            schedule.scheduleResources.map(scheduleResource => {
              if (scheduleResource.resourceId) {
                this.serviceSchedule.locationIDs.push(scheduleResource.resourceId);
              }
            });
            this.acquaintSchedule(schedule);
            this.schedulesData.splice(scheduleIndex, 1, schedule);
            this.updateScheduleData(this.schedulesData);
            this.alert.message('update', [new MessagePlaceholder('{what}', 'Schedule')]);
            this.store.dispatch(new SchedulesUpdate(schedule.id, schedule));
          }
        });
      }
    });
  }

  getSchedules() {
    this.loading.emit(true);
    if (this.scheduleType) {
      if (this.scheduleType.id === 5 || (!this.scheduleType.tabColumns && this.scheduleType.procedureTypeID)) {
        this.displayedColumnsSchedule = ['resource', 'procedureNumber', 'subType', 'lastDate', 'dueDate', 'scheduleDate', 'extendedDate', 'notes', 'status', 'options'];
      }
      else if (this.scheduleType.procedureTypeID || this.scheduleSubtype?.procedureTypeID) {
        this.displayedColumnsSchedule = ['resource', 'procedureNumber', 'lastDate', 'dueDate', 'scheduleDate', 'extendedDate', 'notes', 'status', 'options'];
      } else {
        this.displayedColumnsSchedule = ['resource', 'lastDate', 'dueDate', 'scheduleDate', 'extendedDate', 'notes', 'status', 'options'];
      }

      this.schedules$ = this.store.select(state => state.Schedules.data);
      this.schedulesSubs = this.schedules$.subscribe(data => {
        this.schedules = data;
        const allSchedules = this.schedules.filter(x => x.typeId == this.scheduleType?.id);
        if (this.scheduleType?.tabColumns) {
          allSchedules.map(s => {
            if (s.scheduleSubtypeId == null) {
              s.scheduleSubtypeId = this.scheduleType?.scheduleSubtypes[0]?.id;
              s.subtype = this.scheduleType?.scheduleSubtypes[0];
            }
          });
          this.schedulesData = allSchedules.filter(x => x.scheduleSubtypeId == this.scheduleSubtype?.id).map(v => {
            this.acquaintSchedule(v);
            return v;
          });
        }
        else {
          this.schedulesData = allSchedules.map(v => {
            this.acquaintSchedule(v);
            return v;
          });
        }
        // this.updateScheduleData(this.schedulesData);
        this.serviceSchedule.getLocationsIdBySchedules(this.schedulesData);
        if (this.catalogService.currentDocSchedule !== undefined && this.catalogService.currentDocSchedule !== null) {
          this.showIndex(this.catalogService.currentDocSchedule.id);
        }
        this.loadProcedures();
        this.loading.emit(false);
        this.dateFormat = this.scheduleSubtype ? this.scheduleSubtype.dateFormat : this.scheduleType?.dateFormat;
      });
    }
  }

  async changeStatus(schedule: Schedule | null, status: ScheduleStatusEnum) {
    switch (status) {
      case ScheduleStatusEnum.requestExtension:
        schedule = (await this.openRequestExtension(schedule)) as Schedule;
        break;
      case ScheduleStatusEnum.refuseExtension:
        schedule = (await this.openRejectExtension(schedule)) as Schedule;
        break;
    }

    if (schedule != null) {
      schedule.statusId = status;
      this.updateScheduleStatus(schedule);
    }
  }

  async requestRejectExtensionSubdetail(schedule: Schedule | null, status: ScheduleStatusEnum) {
    if (schedule?.subdetail) {
      this.selectedRow = schedule?.id;
      schedule.subdetail.name = schedule.name + ' -- Hutch Functional';
      let scheduleSubdetail = {} as ScheduleSubdetail;
      switch (status) {
        case ScheduleStatusEnum.requestExtension:
          scheduleSubdetail = (await this.openRequestExtension(schedule.subdetail)) as ScheduleSubdetail;
          break;
        case ScheduleStatusEnum.refuseExtension:
          scheduleSubdetail = (await this.openRejectExtension(schedule.subdetail)) as ScheduleSubdetail;
          break;
      }

      if (scheduleSubdetail != null) {
        scheduleSubdetail.statusId = status;
        this.changeStatusSubdetail(scheduleSubdetail, status);
      }
    }
  }

  editScheduledFor(schedule: Schedule) {
    if (schedule) {
      this.selectedRow = schedule?.id;
      this.openEditScheduledFor(schedule);
    }
  }


  changeStatusSubdetail(subdetail: ScheduleSubdetail, status: ScheduleStatusEnum) {
    if (subdetail) {
      subdetail.statusId = status;
      this.selectedRow = subdetail.scheduleId;
      const lastSubdetail = this.schedulesData.find(x => x.subdetail?.id === subdetail.id);
      if (lastSubdetail) {
        this.loading.emit(true);
        const last = this.schedulesData.indexOf(lastSubdetail);
        this.serviceSchedule.updateSubdetailStatus(subdetail).subscribe(response => {
          this.alert.message('update', [new MessagePlaceholder('{what}', 'Schedule')]);
        });
      }
    }
  }

  updateScheduleStatus(schedule: Schedule) {
    this.loading.emit(true);
    this.selectedRow = schedule?.id;
    const last = this.schedulesData.indexOf(schedule);
    if (schedule.statusId === ScheduleStatusEnum.completed) {
      this.serviceSchedule.updateStatus(schedule).subscribe(data => {
        this.alert.message('update', [new MessagePlaceholder('{what}', 'Schedule')]);
      });
    } else {
      this.serviceSchedule.updateStatus(schedule).subscribe(response => {
        const schedulechange = response as Schedule;
        this.acquaintSchedule(schedulechange);
        this.schedulesData.splice(last, 1, schedulechange);
        this.updateScheduleData(this.schedulesData);
        this.alert.message('update', [new MessagePlaceholder('{what}', 'Schedule')]);
        this.store.dispatch(new SchedulesUpdate(schedule.id, schedule));
      });
    }
  }

  removeItem(array: any, value: any): any {
    const index: number = array.indexOf(value);
    array.splice(index, 1);
    return index;
  }

  validateAction(currentStatus: ScheduleStatusEnum, nextStatus: ScheduleStatusEnum): boolean {
    if (currentStatus === ScheduleStatusEnum.completed) {
      return false;
    }
    switch (nextStatus) {
      case ScheduleStatusEnum.completed:
        if (currentStatus === ScheduleStatusEnum.new || currentStatus === ScheduleStatusEnum.extensionGranted) {
          return true;
        }
        return true;
      case ScheduleStatusEnum.requestExtension:
        if (currentStatus === ScheduleStatusEnum.new || currentStatus === ScheduleStatusEnum.extensionGranted) {
          return true;
        }
        return false;
      case ScheduleStatusEnum.extensionGranted:
      case ScheduleStatusEnum.refuseExtension:
        if (currentStatus === ScheduleStatusEnum.requestExtension) {
          return true;
        }
        return false;
      case ScheduleStatusEnum.remove:
        return true;
    }
    return false;
  }

  changeCompleted(data: boolean) {
    this.showCompleted = data;
    this.updateScheduleData(this.schedulesData);
  }

  updateScheduleData(data: Schedule[]) {
    data.map(schedule => {
      this.sortNotesDesc(schedule.notes as ScheduleNote[]);
    });
    data = this.sortByDueDate(data, 'asc');
    this.dataSource = new MatTableDataSource(this.showCompleted ? data : data.filter(x => x.statusId !== ScheduleStatusEnum.completed));

    this.dataSource.data.map(s => {
      s.procedure = this.procedures.find(p => p.procedureMasterID == s.procedureMasterID && p.active);
    });
    this.dataSource.filter = this.filter?.trim().toLowerCase();
  }

  openEditSchedule(schedule: Schedule) {
    const scheduleCloned = utils.cloneDeep(schedule);
    let absiType = null;
    if (this.scheduleType?.id === 1) {
      absiType = scheduleCloned?.scheduleResources[0]?.resource?.absiTemplateTypeID ? scheduleCloned?.scheduleResources[0]?.resource?.absiTemplateTypeID : 0;
    }
    const allLocations: ScheduleResources[] = this.getLocationsByTab();

    const dialogRef = this.dialog.open(AddScheduleComponent, {
      width: '40vw',
      data: { scheduleType: this.scheduleType, scheduleSubtype: this.scheduleSubtype, allLocations, absiType, schedule: scheduleCloned, scheduleLocationsId: this.serviceSchedule.locationIDs }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedRow = schedule?.id;
        this.loading.emit(true);
        this.serviceSchedule.update(result).toPromise().then(response => {

        });

      }
    });
  }

  async openCompleteSchedule(schedule: Schedule, status: ScheduleStatusEnum) {
    const dialogRef = this.dialog.open(CompleteScheduleComponent, {
      width: '20em',
      data: schedule
    });
    const resultSch = await dialogRef.afterClosed().toPromise();
    if (resultSch) {
      this.loading.emit(true);
      this.selectedRow = schedule?.id;
      schedule.completedDate = resultSch;
      this.changeStatus(schedule, status);
    }
  }

  async openCompleteSubdetail(element: ScheduleSubdetail, status: ScheduleStatusEnum) {
    const dialogRef = this.dialog.open(CompleteScheduleComponent, {
      width: '20em',
      data: element
    });
    const resultSch = await dialogRef.afterClosed().toPromise();
    if (resultSch) {
      this.selectedRow = element.scheduleId;
      element.completedDate = resultSch;
      this.changeStatusSubdetail(element, status);
    }
  }

  grantExtension(schedule: Schedule, extensionGranted: ScheduleStatusEnum) {
    const removeRef = this.dialog.open(YesNoDialogComponent, {
      width: '20em',
      data: {
        message: 'Are you sure you want to grant this extension?',
        icon: 'warn',
        val: false
      }
    });
    removeRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.selectedRow = schedule?.id;
        this.changeStatus(schedule, extensionGranted);
      }
    });
  }

  grantExtensionSubdetail(element: ScheduleSubdetail, extensionGranted: ScheduleStatusEnum) {
    const removeRef = this.dialog.open(YesNoDialogComponent, {
      width: '20em',
      data: {
        message: 'Are you sure you want to grant this extension?',
        icon: 'warn',
        val: false
      }
    });
    removeRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.selectedRow = element.scheduleId;
        this.changeStatusSubdetail(element, extensionGranted);
      }
    });
  }

  deleteSchedule(element: Schedule) {
    const scheduleIndex = this.schedulesData.indexOf(element);
    const removeRef = this.dialog.open(YesNoDialogComponent, {
      width: '20em',
      data: {
        message: 'Are you sure you want to remove this schedule?',
        icon: 'stop',
        val: true
      }
    });
    removeRef.afterClosed().subscribe(result => {
      if (result != null && result) {
        this.serviceSchedule.delete(element.id).subscribe(response => {
          if (response) {
            this.store.dispatch(new SchedulesDelete(element.id));
            this.schedulesData.splice(scheduleIndex, 1);
            element.scheduleResources.map(scheduleResource => {
              if (scheduleResource.resourceId) {
                this.serviceSchedule.locationIDs.splice(this.serviceSchedule.locationIDs.indexOf(scheduleResource.resourceId), 1);
              }
            });
            this.updateScheduleData(this.schedulesData);
          }
        });
      }
    });
  }

  acquaintSchedule(schedule: Schedule) {
    if (schedule.statusId === ScheduleStatusEnum.completed) {
      schedule.colorCompletedCol = '#ff0000';
    }
    if (schedule.notes?.length) {
      this.sortNotesDesc(schedule.notes as ScheduleNote[]);
      schedule.notes = this.getNotes(schedule.notes as ScheduleNote[]);
    }
    if (schedule.subdetail?.notes?.length) {
      schedule.subdetail.notes = this.getNotes(schedule.subdetail.notes as ScheduleNote[])
    }
    schedule.scheduleResources = schedule.scheduleResources?.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.resource?.name, b.resource?.name));
    schedule.name = schedule.scheduleResources?.map(val => {
      if (val.resourceId == null) {
        return val.resourceString;
      } else {
        return val.resource?.name;
      }
    }).join('<br>');
  }

  sortNotesDesc(notes: ScheduleNote[]) {
    notes?.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());
  }

  validateShowMenu(currentStatus: ScheduleStatusEnum, schedule: Schedule) {
    if (this.readOnly) { return false; }
    if (this.sv.validateRole('updateSchedule', this.tokenInfo, this.scheduleType, schedule)) { return true; }
    if (this.sv.validateRole('editScheduledFor', this.tokenInfo, this.scheduleType, schedule)) { return true; }
    if (this.validateAction(currentStatus, this.ScheduleStatus.completed) && this.sv.validateRole('createNote', this.tokenInfo, this.scheduleType)) { return true; }
    if (this.validateAction(currentStatus, this.ScheduleStatus.completed) && this.sv.validateRole('completeSchedule', this.tokenInfo, this.scheduleType)) { return true; }
    if (this.validateAction(currentStatus, this.ScheduleStatus.requestExtension) && this.sv.validateRole('requestExtension', this.tokenInfo, this.scheduleType)) { return true; }
    if (this.validateAction(currentStatus, this.ScheduleStatus.extensionGranted) && this.sv.validateRole('grantExtension', this.tokenInfo, this.scheduleType, schedule)) { return true; }
    if (this.validateAction(currentStatus, this.ScheduleStatus.refuseExtension) && this.sv.validateRole('refuseSchedule', this.tokenInfo, this.scheduleType, schedule)) { return true; }
    if (this.validateAction(currentStatus, this.ScheduleStatus.remove) && this.sv.validateRole('deleteSchedule', this.tokenInfo, this.scheduleType, schedule)) { return true; }
    return false;
  }



  hutchGreaterThanHutchFunctional(schedule: Schedule): boolean {
    if (schedule.typeId === 5 && schedule.subdetail && schedule.scheduleSubtypeId === 2) {
      if (schedule.subdetail.nextDue < schedule.nextDue) {
        return true;
      }
    }
    return false;
  }

  sortByDueDate(schedules: Schedule[], order: string) {
    if (this.scheduleType?.id === 5) {
      schedules.map(x => {
        if (this.hutchGreaterThanHutchFunctional(x)) {
          x.nextDueToSort = x.subdetail?.nextDue;
        } else {
          x.nextDueToSort = x.nextDue;
        }
      });
    } else {
      schedules.map(x => {
        x.nextDueToSort = x.nextDue;
      });
    }
    if (order === 'asc') {
      schedules.sort((a, b) => moment(a.nextDueToSort).isAfter(b.nextDueToSort) ? 1 : -1);
    }
    if (order === 'desc') {
      schedules.sort((a, b) => moment(a.nextDueToSort).isAfter(b.nextDueToSort) ? -1 : 1);
    }
    return schedules;
  }

  getCellColor(item: Schedule, subDetail?: boolean) {

    const element = subDetail ? item.subdetail : item;
    let rowColor = 'transparent';
    if (element) {
      const currentDate = new Date();

      let expireDate = new Date();
      if (element.isExtended) {
        if (element.extendedDate)
          expireDate = new Date(element.extendedDate);
      } else {
        expireDate = new Date(element.nextDue);
      }
      const diffDates = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(expireDate.getFullYear(), expireDate.getMonth(), expireDate.getDate())) / (1000 * 3600 * 24));
      const diffInDays = moment(currentDate).diff(moment(expireDate), 'days');

      const dateAbsoluteValue: number = Math.abs(diffDates);

      if (element.statusTime == ScheduleStatusTimeEnum.Good && dateAbsoluteValue <= 7) {
        rowColor = '#fcfbac80'; // yellow
      }
      else if (element.statusTime == ScheduleStatusTimeEnum.Grace || element.statusTime == ScheduleStatusTimeEnum.ExtensionGranted) {
        rowColor = '#ffc69480'; // orange
      }
      else if (element.statusTime == ScheduleStatusTimeEnum.Expired) {
        rowColor = '#ff949480'; // red
      }
    }
    return rowColor;
  }

  getNextDueTitle() {
    if (this.scheduleType?.id) {
      switch (this.scheduleType.id) {
        case 1: return 'Next Inspection Due';
        case 2: return 'Next Test Due';
        case 3: return 'Next Survey Due';
        case 4: return 'Next Survey Due';
        case 5: return 'Next Test Due';
        case 6:
        default: return 'Next Due';
      }
    }
    else return false;
  }

  getNotes(snotes?: ScheduleNote[] | null) {
    const notes: Note[] = [];
    snotes?.map(n => {
      notes.push({ id: n.id, user: n.user, userID: n.user?.id, description: n.description, date: n.createdOn, type: n.type, createdOn: n.createdOn });
    });
    return notes;
  }
}
