import {
  Component, OnInit, Output, EventEmitter, OnDestroy, Input, OnChanges, SimpleChanges, AfterViewInit, Injector,
} from "@angular/core";
import { AbstractControl, FormControl, Validators } from "@angular/forms";
import { MatDialogConfig } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription, Observable } from "rxjs";
import { ActionPendingCatalog, Catalog, ResourceType } from "src/app/common/enumerations/enumerations";
import { RestrictionsStoreAndListenersService } from "src/app/components/scf-v2/store/restrictions/restrictions.store";
import { DisapprovalReasonComponent } from "src/app/controls/disapproval-reason/disapproval-reason.component";
import { YesNoDialogComponent } from "src/app/controls/yes-no-dialog/yes-no-dialog.component";
import { AcceleratorCatalog, AcceleratorCatalogDetails } from "src/app/components/catalogs/accelerator-catalog/services/accelerator-catalog";
import { Resource, ResourceRelation } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import { Restriction } from "src/app/controls/restrictions/services/restriction";
import { PrivilegeEnum } from "src/app/services/role-privilege/privilege-enum";
import { CatalogModel } from "../../catalog-model";
import { CatalogService } from "../../catalog-service";
import { Shielding } from "./accelerator-shielding/accelerator-shielding.component";
import { BaseComponent } from "src/app/common/base/base.component";
import { AcceleratorLocationComponent } from "../dialogs/location/location.component";

@Component({
  selector: "app-accelerator-catalog-details",
  templateUrl: "./accelerator-catalog-details.component.html",
  styleUrls: ["./accelerator-catalog-details.component.scss"],
})
export class AcceleratorCatalogDetailsComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() acceleratorCatalog?: AcceleratorCatalog;
  @Input() showModification!: boolean;
  @Input() showHistory!: boolean;
  @Input() enableMove: boolean = false;

  @Output() loading = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<number>();
  @Output() changed = new EventEmitter<boolean>();
  @Output() showModificationChanged = new EventEmitter<boolean>();

  catalogModel!: CatalogModel;
  accelerator!: Resource;

  restrictionsParent: any;
  locationsParent: any;

  restrictions!: Restriction[];
  restrictionsFiltered!: Restriction[];
  restrictions$!: Observable<Restriction[]>;
  restrictionsSubs!: Subscription;

  resources!: Resource[];
  resourcesFiltered!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  errorMsg!: string[];

  oldAcceleratorName?: string | null;

  canModify!: boolean;
  canApprove?: boolean | null;
  isEditing!: boolean;
  isCreating!: boolean;
  canCancel?: boolean | null;

  originalAccelerator?: AcceleratorCatalog | null;
  valid!: boolean;

  displayedColumnsLocations: string[] = ["location"];
  dataSourceLocation!: MatTableDataSource<Resource>;
  dataSourceRestriction!: MatTableDataSource<Restriction>;

  locations!: Resource[];
  shieldings: Shielding[] = [];

  formGroupSubs!: Subscription;

  public get nameCtrl(): AbstractControl | null {
    return this.formGroup.get("nameCtrl");
  }
  public get desciptionCtrl(): AbstractControl | null {
    return this.formGroup.get("descriptionCtrl");
  }
  public get showCtrl(): AbstractControl | null {
    return this.formGroup.get("showCtrl");
  }
  public get activeCtrl(): AbstractControl | null {
    return this.formGroup.get("activeCtrl");
  }
  restrictionsCtrl = new FormControl();
  locationsCtrl = new FormControl();
  shieldingsCtrl = new FormControl();

  acceleratorHistory?: AcceleratorCatalog[];

  constructor(
    protected override injector: Injector,
    restrictionsStore: RestrictionsStoreAndListenersService,
    private catalogService: CatalogService) {
    super(injector);
    restrictionsStore.initStore();
  }

  override  ngOnDestroy(): void {
    this.restrictionsSubs?.unsubscribe();
    this.resourcesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  async ngOnInit() {
    this.initializeForm();
    this.clear(true);
    this.loadResources();
    this.loadRestrictions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showModification'] && this.originalAccelerator && !this.showModification) {
      this.acceleratorCatalog = this.utils.cloneDeep(this.originalAccelerator);
    }
    if (changes["acceleratorCatalog"]) {
      this.isCreating = false;
      this.isEditing = false;
      this.showModification = false;
      this.showHistory = false;
      this.originalAccelerator = this.utils.cloneDeep(this.acceleratorCatalog);
    }
    this.initializeForm();
    this.getDetails(this.acceleratorCatalog);
    if (this.showHistory)
      this.getHistory();
  }

  loadRestrictions() {
    this.restrictions$ = this.store.select((state) => state.Restrictions.data);
    this.restrictionsSubs = this.restrictions$.subscribe((data) => {
      if (data?.length) {
        this.restrictions = data;
      }
    });
  }

  loadResources() {
    this.resources$ = this.store.select((state) => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe((data) => {
      if (data.length) {
        this.resources = data;
      }
    });
  }

  ngAfterViewInit(): void { }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      nameCtrl: [null, [Validators.required, this.utils.IsWhiteSpaceReactiveForm]],
      descriptionCtrl: [
        null,
        [Validators.required, this.utils.IsWhiteSpaceReactiveForm],
      ],
      showCtrl: [null],
      activeCtrl: [null],
      restrictionsCtrl: [null],
      shieldingsCtrl: [null],
    });
    this.disableControls(!this.isEditing);
    this.changed.emit(false);
    this.formGroupSubs = this.formGroup.valueChanges.subscribe((formValue) => {
      if (formValue) {
        this.changed.emit(this.formGroup.dirty);
      }
    });
    this.enableMove = false;
  }

  disableControls(disable: boolean = true) {
    if (disable) {
      this.formGroup.disable();
      this.nameCtrl?.disable();
      this.desciptionCtrl?.disable();
      this.showCtrl?.disable();
    } else {
      this.formGroup.enable();
      this.nameCtrl?.enable();
      this.desciptionCtrl?.enable();
      this.showCtrl?.enable();
    }
  }

  getDetails(acceleratorCatalog?: AcceleratorCatalog) {
    this.formGroup?.markAsPristine();
    if (acceleratorCatalog && !acceleratorCatalog?.id) {
      this.canModify = this.hasPrivilege(
        PrivilegeEnum.AcceleratorCatalogCreate
      );
      this.isCreating = true;
      this.setValues(acceleratorCatalog.details ?? acceleratorCatalog.pendingDetails);
      this.disableControls(false);
    } else if (acceleratorCatalog?.id) {
      this.loading.emit(true);
      this.accelerator = acceleratorCatalog.details.accelerator ?? acceleratorCatalog.pendingDetails.accelerator;
      const pendingAccelerator = acceleratorCatalog.pendingAcceleratorCatalog;
      this.canCancel =
        pendingAccelerator &&
        this.currentUser?.id == pendingAccelerator?.createdBy;
      if (pendingAccelerator && (this.showModification || this.showHistory)) {
        if (this.acceleratorCatalog?.pendingDetails)
          this.setValues(this.acceleratorCatalog.pendingDetails);
      } else {
        this.setValues(acceleratorCatalog.details.accelerator ? acceleratorCatalog.details : acceleratorCatalog.pendingDetails);
      }
      this.canModify =
        this.hasPrivilege(PrivilegeEnum.AcceleratorCatalogCreate) &&
        ((pendingAccelerator
          ? pendingAccelerator.createdBy == this.currentUser?.id
          : false) ||
          !pendingAccelerator);
      this.canApprove =
        pendingAccelerator &&
        pendingAccelerator.createdBy != this.currentUser?.id &&
        this.hasPrivilege(PrivilegeEnum.AcceleratorCatalogApprove);
      this.loading.emit(false);
    } else if ((acceleratorCatalog?.id ?? 0) < 0) {
      this.loading.emit(true);
    } else {
      this.clear();
    }
  }

  async save() {
    this.catalogModel.acceleratorCatalog = this.getValues();
    if (await this.isValid()) {
      this.loading.emit(true);
      this.showModificationChanged.emit(false);
      this.isCreating = false;
      this.isEditing = false;
      this.catalogService
        .SaveAsDraft(this.catalogModel, Catalog.AcceleratorCatalog)
        .toPromise()
        .then(() => {
          this.alert.info(
            this.isCreating
              ? "The New Accelerator Has Been Sent To Approval!"
              : "The Accelerator Modification Has Been Sent To Approval!"
          );
          this.saved.emit(this.accelerator.id);
          this.acceleratorCatalog = this.catalogModel.acceleratorCatalog;
          this.getDetails(this.acceleratorCatalog);
          this.setFormDirty(false);
          this.showModification = false;
          // this.clear();
        }).catch(error => {
          console.log(error);
          this.alert.defaultError();
        });
    } else {
      this.alertError();
    }
    this.errorMsg = [];
  }

  async delete() {
    this.catalogModel.acceleratorCatalog = this.getValues();
    this.catalogModel.action = ActionPendingCatalog.Delete;
    this.loading.emit(true);
    this.showModificationChanged.emit(false);
    this.isCreating = false;
    this.isEditing = false;
    this.catalogService
      .SaveAsDraft(this.catalogModel, Catalog.AcceleratorCatalog)
      .toPromise()
      .then(() => {
        this.alert.info(
          "The Accelerator Deletion Has Been Sent To Approval!"
        );
        this.saved.emit(this.accelerator.id);
        this.clear();
      }).catch(error => {
        console.log(error);
        this.alert.defaultError();
      }).finally(() => this.loading.emit(false));
    this.errorMsg = [];
  }

  modifyAccelerator() {
    if (this.acceleratorCatalog?.pendingDetails)
      this.showModificationChanged.emit(true);
    this.isEditing = true;
    this.originalAccelerator = this.utils.cloneDeep(this.acceleratorCatalog);
    this.disableControls(false);
  }

  approve() {
    this.loading.emit(true);
    this.showModificationChanged.emit(false);
    this.catalogService
      .Approve(
        this.accelerator.id ? this.accelerator.id : -(this.acceleratorCatalog?.id ?? 0),
        Catalog.AcceleratorCatalog
      )
      .toPromise().then(id => {
        this.alert.success(
          "Congratulations !! Accelerator Changes Approved Successfully!!"
        );
        this.saved.emit(id);
      }).catch(() => {
        if (this.acceleratorCatalog?.pendingAcceleratorCatalog?.action == ActionPendingCatalog.Delete) {
          this.alert.error('Error Approving Deletion of Accelerator. The Accelerator may be in use in a Form or somewhere else.');
        }
        else {
          this.alert.defaultError();
        }
      }).finally(() => this.loading.emit(false));
    this.errorMsg = [];
  }
  /**
   *
   *
   * @param {string} disapprovalReason
   * @memberof AcceleratorCatalogDetailsComponent
   */
  disapprove(disapprovalReason: string) {
    this.loading.emit(true);
    this.catalogService
      .Unapprove(
        this.accelerator.id,
        Catalog.AcceleratorCatalog,
        disapprovalReason
      )
      .subscribe(() => {
        this.alert.info(
          "Accelerator Change Disapproved!"
        );
        this.saved.emit(this.accelerator.id);
        this.clear();
      });
  }

  openAddLocationDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = new Resource();
    const dialogRef = this.dialog.open(AcceleratorLocationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (
          this.isResourceRepeated(
            this.dataSourceLocation.data,
            data,
            "Location"
          )
        ) {
          this.addNewResource(data, this.dataSourceLocation);
          this.locationsParent = { list: this.dataSourceLocation.data };
          this.locationsCtrl.setValue(true);
          this.setFormDirty();
        } else {
          this.alertError();
        }
        this.errorMsg = [];
      }
    });
  }

  openEditLocationDialog(resource: Resource) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = resource;
    const dialogRef = this.dialog.open(AcceleratorLocationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (
          this.isResourceRepeated(
            this.dataSourceLocation.data,
            data,
            "Location"
          )
        ) {
          this.addExistingResource(data, resource, this.dataSourceLocation);
          this.locationsParent = { list: this.dataSourceLocation.data };
          this.locationsCtrl.setValue(true);
          this.setFormDirty();
        } else {
          this.alertError();
        }
        this.errorMsg = [];
      }
    });
  }

  openAddDisapprovalDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = "";
    const dialogRef = this.dialog.open(
      DisapprovalReasonComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.disapprove(data);
      }
    });
  }

  deleteLocation(resource: Resource) {
    this.dialogService.ConfirmationDialog('Remove', 'this <b>Location</b>').subscribe((result) => {
      if (result) {
        const index = this.dataSourceLocation.data.indexOf(resource);
        this.dataSourceLocation.data.splice(index, 1);
        this.dataSourceLocation.filter = "";
        this.locationsCtrl.setValue(true);
        this.setFormDirty();
      }
    });
  }

  setAcceleratorName() {
    if (this.accelerator.name) {
      this.accelerator = this.createNewResource(this.accelerator, 1);
    }
    this.setFormDirty();
  }

  getDataFromRestriction(restrictions: Restriction[]) {
    this.restrictionsCtrl.setValue(restrictions);
    this.setFormDirty();
    this.dataSourceRestriction.data = restrictions;
  }

  shieldingsChanged(shieldings: Shielding[]) {
    this.shieldingsCtrl.setValue(shieldings);
    this.setFormDirty();
    this.shieldings = shieldings;
  }

  addNewResource(
    data: Resource,
    dataSourceResource: MatTableDataSource<Resource>
  ) {
    const resource = this.createNewResource(
      data,
      ResourceType.AcceleratorLocation,
      dataSourceResource.data.length + 1
    );
    dataSourceResource.data.push(resource);
    dataSourceResource.filter = "";
  }

  addExistingResource(
    data: Resource,
    dataToUpdate: Resource,
    dataSourceResource: MatTableDataSource<Resource>
  ) {
    const index = dataSourceResource.data.indexOf(dataToUpdate);
    dataSourceResource.data[index].name = data.name;
    dataSourceResource.filter = "";
  }

  createNewResource(
    resource: Resource,
    type: number,
    position?: number
  ): Resource {
    const resourceCreated: Resource = {
      id: resource.id,
      name: resource.name,
      type,
      status: 1,
      position,
    };
    return resourceCreated;
  }

  getColumns() {
    return this.isEditing || this.isCreating ? ["location", "add"] : ["location"];
  }

  getValues(): AcceleratorCatalog | undefined {
    this.accelerator.name = this.nameCtrl?.value;
    this.accelerator.description = this.desciptionCtrl?.value;
    this.accelerator.checkboxVisible = this.showCtrl?.value;
    this.accelerator.status = this.activeCtrl?.value ? 1 : 0;
    if (this.dataSourceLocation.data.length > 0) {
      this.locations = this.dataSourceLocation.data;
    } else {
      this.locations = [];
    }
    if (this.dataSourceRestriction.data.length > 0) {
      this.restrictions = this.dataSourceRestriction.data;
    } else {
      this.restrictions = [];
    }
    if (this.acceleratorCatalog) {
      this.acceleratorCatalog.pendingDetails = new AcceleratorCatalogDetails();
      this.acceleratorCatalog.pendingDetails.shieldings = this.shieldings.filter(s => !s.isDeleted).map(
        (x) => {
          if (x.resource) {
            x.resource.checkboxVisible = x.checkboxVisible;
            x.resource.longShutdown = x.longShutdown;
            x.resource.inTunnel = x.inTunnel;
            x.resource.isRSSD = x.isRSSD;
            x.resource.position = x.position;
            x.resource.cssClass = x.resource.cssClass;
            if (x.resource.parentResources) {
              const parentResource = x.resource.parentResources.find(
                (p) => p.parentResourceID == this.accelerator.id
              );
              if (parentResource) {
                parentResource.position = x.position;
              }
            }
            x.resource.childResources = x.childResources;
            x.resource.childResources?.map((x) => {
              x.childResource = null;
              x.parentResource = null;
            });
          }
          return x.resource;
        }

      ) as Resource[];
      this.acceleratorCatalog.pendingDetails.accelerator = this.accelerator;
      this.acceleratorCatalog.pendingDetails.restrictions = this.restrictions;
      this.acceleratorCatalog.pendingDetails.locations = this.locations;
      this.acceleratorCatalog.pendingAcceleratorCatalog = null;
      this.acceleratorCatalog.details.locations = [];
      this.acceleratorCatalog.details.restrictions = [];
      this.acceleratorCatalog.details.shieldings = [];
    }
    return this.acceleratorCatalog;
  }

  setValues(data: AcceleratorCatalogDetails) {
    const fields = ["locations", "restrictions", "shieldings"];
    fields.map((f) => this.isModified(f));
    if (this.showHistory || this.showModification)
      this.checkAddedAndDeletedRows();
    this.accelerator = data.accelerator;
    this.accelerator?.childResources?.map((c) => {
      const resource = this.resources.find((r) => r.id == c.childResourceID);
      c.childResourceType = resource?.type;
      c.childResourceName = resource?.name;
      c.childResource = resource;
    });
    const locations = data.locations;
    this.dataSourceLocation = new MatTableDataSource(locations);
    const restrictions = data.restrictions;
    this.dataSourceRestriction = new MatTableDataSource(restrictions);
    this.restrictionsParent = { list: restrictions };
    this.locationsParent = { list: locations };
    this.oldAcceleratorName = data.accelerator?.name;
    this.shieldings = this.getShieldings(this.utils.cloneDeep(data));
    this.nameCtrl?.setValue(this.accelerator?.name);
    this.desciptionCtrl?.setValue(this.accelerator?.description);
    this.showCtrl?.setValue(this.accelerator?.checkboxVisible);
    this.activeCtrl?.setValue(this.accelerator?.status);
    this.restrictionsCtrl.setValue(false);
    this.locationsCtrl.setValue(false);
    this.shieldingsCtrl.setValue(false);
  }

  getShieldings(data: AcceleratorCatalogDetails) {
    return data.shieldings?.map((s) => {
      const parentResource = s.parentResources?.find(
        (p) => p.parentResourceID == this.accelerator.id
      );
      const childResources = this.getSubLocations(s);
      const shielding: Shielding = {
        name: s.name,
        resourceID: s.id,
        resource: s,
        position: s.isDeleted ? (s.position ?? 0) : (parentResource ? (parentResource.position ?? 0) : (s.position ?? 0)),
        childResources,
        checkboxVisible: s.checkboxVisible ?? false,
        longShutdown: s.longShutdown ?? false,
        inTunnel: s.inTunnel,
        isRSSD: s.isRSSD,
        isModified: s.isModified,
        isDeleted: s.isDeleted,
        isAdded: s.isAdded,
        subLocationNames: childResources
          ?.map((x) =>
            x.childResourceName
              ? x.childResourceName
              : this.resources.find((r) => r.id == x.childResourceID)?.name
          )
          .join(", "),
      };
      return shielding;
    }).sort((a, b) => this.utils.sort(a.position, b.position));
  }

  getSubLocations(s: Resource): ResourceRelation[] {
    let resourceRelations: ResourceRelation[] = [];
    let locationIDs = s.childResources?.map((c) => c.childResourceID);
    if (!locationIDs?.length) {
      const locs = s.parentResources?.filter((x) => x.locationsIDs && x.parentResourceID == this.accelerator.id).map((p) => p.locationsIDs).join(',');
      locationIDs = this.utils.JSONparse(locs) as number[];
    }
    if (locationIDs?.length) {
      const resources = this.resources.filter((r) => locationIDs?.includes(r.id));
      resources.map((r) => {
        const rr: ResourceRelation = {
          id: 0,
          childResourceID: r.id,
          childResource: r,
          parentResourceID: s.id,
          parentResource: s,
        };
        resourceRelations.push(rr);
      });
    }
    return resourceRelations;
  }

  cancel() {
    if (this.isEditing || this.isCreating) {
      this.formGroup.reset();
      if (this.isEditing && this.originalAccelerator) {
        this.acceleratorCatalog = this.originalAccelerator;
        this.getDetails(this.originalAccelerator);
        this.originalAccelerator = null;
      }
      if (this.isCreating) {
        this.canModify = false;
      }
      this.isCreating = false;
      this.isEditing = false;
      this.disableControls();
      this.setFormDirty(false);
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        message: "Are you sure you want to Cancel your changes?",
        icon: "question",
      };
      const dialogRef = this.dialog.open(YesNoDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((data) => {
        if (data) {
          this.catalogService
            .Unapprove(this.accelerator.id, Catalog.AcceleratorCatalog, '')
            .subscribe(() => {
              this.alert.info("Your changes were Cancelled");
              this.saved.emit(this.accelerator.id);
              this.clear();
            });
        }
      });
    }
  }

  isResourceRepeated(
    resources: Resource[],
    resource: Resource,
    typeName: string
  ) {
    this.valid = true;
    if (resources.filter((x) => x.name?.toLowerCase() === resource.name?.toLowerCase()
    ).length !== 0
    ) {
      this.errorMsg.push(
        typeName +
        " <b>" +
        resource.name?.toUpperCase() +
        "</b> is already added"
      );
      this.valid = false;
    }
    return this.valid;
  }

  async isValid() {
    this.valid = true;
    if (!this.nameCtrl?.value) {
      this.errorMsg.push("Field <b>Accelertor Name</b> should not be blank!");
      this.valid = false;
    }
    return this.valid;
  }

  clear(isInit?: boolean) {
    this.catalogModel = new CatalogModel();
    this.acceleratorCatalog = new AcceleratorCatalog();

    this.dataSourceLocation = new MatTableDataSource();
    this.dataSourceRestriction = new MatTableDataSource();

    this.shieldings = [];
    this.restrictionsParent = { list: [] };
    this.locationsParent = { list: [] };

    this.errorMsg = [];
    this.isCreating = false;
    this.isEditing = false;
    this.canApprove = false;
    this.canModify = false;
    this.canCancel = false;

    this.setFormDirty(false);
    this.formGroup.reset();
    this.disableControls();
  }

  alertError() {
    this.alert.error(this.errorMsg.join(", "));
    const that = this;
    setTimeout(() => {
      that.alert.clear();
    }, 3000);
  }

  isModified(fieldName: string) {
    if (this.acceleratorCatalog && (this.showModification || (this.showHistory && this.acceleratorHistory)) && this.acceleratorCatalog.details?.accelerator && this.acceleratorCatalog.pendingDetails?.accelerator) {
      switch (fieldName) {
        case "name":
          return (
            this.acceleratorCatalog.details.accelerator?.name !=
            this.acceleratorCatalog.pendingDetails?.accelerator?.name
          );
          break;
        case "description":
          return (
            this.acceleratorCatalog.details.accelerator?.description !=
            this.acceleratorCatalog.pendingDetails?.accelerator.description
          );
          break;
        case "show":
          return (
            this.acceleratorCatalog.details.accelerator?.checkboxVisible !=
            this.acceleratorCatalog.pendingDetails?.accelerator.checkboxVisible
          );
          break;
        case "active":
          return (
            this.acceleratorCatalog.details.accelerator?.status !=
            this.acceleratorCatalog.pendingDetails?.accelerator.status
          );
          break;
        case "restrictions":
          const r1 = this.acceleratorCatalog.details.restrictions?.sort(
            (a, b) => this.utils.sort(a.id, b.id));
          const r2 = this.acceleratorCatalog.pendingDetails?.restrictions.sort(
            (a, b) => this.utils.sort(a.id, b.id));
          if (r2) {
            r2.map((r, i) => {
              const s = r1[i];
              if (!s) return;
              r.isModified =
                r.restrictionLanguage != s.restrictionLanguage ||
                r.scf != s.scf ||
                r.rsswa != s.rsswa ||
                r.ebf != s.ebf ||
                r.ppstb != s.ppstb ||
                r.locationID != s.locationID ||
                r.resourceID != s.resourceID;
            });
            return r2.some((r) => r.isModified);
          }
          break;
        case "locations":
          const l1 = this.acceleratorCatalog.details.locations?.sort(
            (a, b) => this.utils.sort(a.position, b.position));
          const l2 = this.acceleratorCatalog.pendingDetails?.locations.sort(
            (a, b) => this.utils.sort(a.position, b.position));
          if (l2) {
            l2.map((l, i) => {
              const m = l1[i];
              l.isModified = !m || l.id != m.id || l.name != m.name;
            });
            return l2.some((l) => l.isModified);
          }
          break;
        case "shieldings":
          const s1 = this.getShieldings(this.acceleratorCatalog.details).sort(
            (a, b) => this.utils.sort(a.position, b.position));
          const s2 = this.getShieldings(
            this.acceleratorCatalog.pendingDetails
          ).sort((a, b) => this.utils.sort(a.position, b.position));
          if (s2) {
            s2.map((s, i) => {
              if (!s.isDeleted) {
                const t = s1.find(x => x.resourceID == s.resourceID);
                const shielding = this.shieldings?.find(
                  (x) => x.name == s.name
                );
                if (shielding)
                  shielding.isModified =
                    !t ||
                    s.name != t.name ||
                    s.resource?.cssClass != t.resource?.cssClass ||
                    s.position != t.position ||
                    s.resource?.keText != t.resource?.keText ||
                    (s.longShutdown ?? false) != (t.longShutdown ?? false) ||
                    (s.inTunnel ?? false) != (t.inTunnel ?? false) ||
                    (s.isRSSD ?? false) != (t.isRSSD ?? false) ||
                    s.subLocationNames != t.subLocationNames ||
                    (s.checkboxVisible ?? false) != (t.checkboxVisible ?? false);
              }
            });
            return this.shieldings.some((s) => s.isModified);
          }
          break;
      }
    }
    return false;
  }

  getHistory() {
    this.loading.emit(true);
    this.acceleratorHistory = [];
    this.catalogService.GetHistory(Catalog.AcceleratorCatalog, this.accelerator.id).toPromise().then(data => {
      if (data) {
        this.acceleratorHistory = data.acceleratorCatalogs;
        if (this.acceleratorHistory) {
          this.acceleratorHistory.map(h => {
            if (h.pendingAcceleratorCatalog) {
              h.pendingAcceleratorCatalog.userCreatedBy = this.users.find(u => u.id == h.pendingAcceleratorCatalog?.createdBy);
              h.pendingAcceleratorCatalog.statusByUser = this.users.find(u => u.id == h.pendingAcceleratorCatalog?.statusBy);
            }
          });
        }
        this.loading.emit(false);
      }
    });
  }

  checkAddedAndDeletedRows() {
    if (this.showHistory || this.showModification) {
      // Restrictions
      const currentRestrictions = this.acceleratorCatalog?.pendingDetails?.restrictions;
      const prevRestrictions = this.acceleratorCatalog?.details?.restrictions;

      if (currentRestrictions && prevRestrictions) {
        prevRestrictions.map(p => {
          if (p.id && !currentRestrictions.find(c => c.id == p.id)) {
            p.isDeleted = true;
            currentRestrictions.push(p);
          }
        });
      }
      currentRestrictions?.filter(c => !c.isDeleted).map(c => {
        c.isAdded = c.id == 0 || !prevRestrictions?.find(p => p.id == c.id);
      });

      // Locations
      const currentLocations = this.acceleratorCatalog?.pendingDetails?.locations;
      const prevLocations = this.acceleratorCatalog?.details?.locations;

      if (currentLocations && prevLocations) {
        prevLocations.map(p => {
          if (p.id && !currentLocations.find(c => c.id == p.id)) {
            p.isDeleted = true;
            currentLocations.push(p);
          }
        });
      }
      currentLocations?.filter(c => !c.isDeleted).map(c => {
        c.isAdded = c.id == 0 || !prevLocations?.find(p => p.id == c.id);
      });

      // Shieldings
      const currentShieldings = this.acceleratorCatalog?.pendingDetails?.shieldings;
      const prevShieldings = this.acceleratorCatalog?.details?.shieldings;
      if (prevShieldings && currentShieldings) {
        prevShieldings.map(p => {
          const parentResource = p.parentResources?.find(
            r => r.parentResourceID == this.accelerator.id
          );
          if (p.id && !currentShieldings.find(c => c.id == p.id)) {
            p.isDeleted = true;
            p.position = parentResource ? (parentResource.position ?? 0) : (p.position ?? 0);
            currentShieldings.push(p);
          }
        });
      }
      currentShieldings?.filter(c => !c.isDeleted).map(c => {
        c.isAdded = c.id == 0 || !prevShieldings?.find(p => p.name == c.name);
      });
    }
  }

  selectedHistory(acceleratorCatalog: AcceleratorCatalog) {
    this.loading.emit(true);
    this.acceleratorCatalog = acceleratorCatalog;
    this.checkAddedAndDeletedRows();
    this.getDetails(this.acceleratorCatalog);
    this.loading.emit(false);
    if (acceleratorCatalog.noDataFound) {
      this.alert.warning("No Detailed Data found for this Modification!");
    }
  }

}
