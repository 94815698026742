import { Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../common/base/base.component';
import { MatTableDataSource } from '@angular/material/table';
import { Privilege, PrivilegeCategory, RolePrivilege } from '../../roles/services/role-privilege';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'privileges-catalog-main',
  templateUrl: './privileges-catalog-main.component.html',
  styleUrls: ['./privileges-catalog-main.component.scss']
})
export class PrivilegesCatalogMainComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() privilegeCategory?: PrivilegeCategory;

  rolePrivileges?: RolePrivilege[];
  rolePrivilegesFiltered?: RolePrivilege[];
  rolePrivileges$!: Observable<RolePrivilege[]>;
  rolePrivilegesSubs!: Subscription;

  dataSource = new MatTableDataSource<Privilege>();
  displayedColumns: string[] = ["privilege", "roles"];

  constructor(protected override injector: Injector) {
    super(injector)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = new MatTableDataSource(this.privilegeCategory?.privileges.sort((a, b) => this.utils.sort(a.name, b.name)));
  }

  ngOnInit() {
    this.loadRolePrivileges();
  }

  override ngOnDestroy(): void {
    this.rolePrivilegesSubs?.unsubscribe();
  }

  loadRolePrivileges() {
    this.rolePrivileges$ = this.store.select(state => state.RolePrivileges.data);
    this.rolePrivilegesSubs = this.rolePrivileges$.subscribe(data => {
      if (data?.length) {
        this.rolePrivileges = data.sort((a, b) => this.utils.sort(a.role.name, b.role.name));
      }
    });
  }

  getRoles(privilege: Privilege) {
    return this.rolePrivileges?.filter(x => x.privilege == privilege.id);
  }

}
