import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { RoleOption } from 'src/app/components/checklists/checklists';

@Component({
  selector: 'cl-checkbox-multiple-sign',
  templateUrl: './cl-checkbox-multiple-sign.component.html',
  styleUrls: ['./cl-checkbox-multiple-sign.component.scss']
})
export class ClCheckboxMultipleSignComponent extends ClBaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() roleOptions!: RoleOption[];

  @Output() data = new EventEmitter<any>();

  questionSanitized!: SafeHtml;

  public role!: string;
  public name!: string;

  public visible = false;

  public icon = 'fa-check';

  roles!: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  /*------- Control variables ------*/
  allowMultiple!: boolean;

  /*--------------------------------*/

  ngOnInit(): void {
    this.loadRoles();
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.valueString)
    this.setValues();
  }

  override ngOnDestroy(): void {
    this.rolesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      this.roles = data;
    });
  }

  setValues() {
    if (this.valueString) {
      this.roleOptions = this.utils.JSONparse(this.valueString);
    }
    this.roleOptions = this.roleOptions.filter(x => x != null);
    this.roleOptions?.map(o => {
      if (!this.roles?.length) this.loadRoles();
      o.roleCodes = this.roles?.filter(r => o.roleIDs?.includes(r.id)).map(r => r.code).join('/');
      o.user = this.getUsers()?.find(u => u.id == o.userID);
      o.checked = o.user != null;
    });
  }

  checkboxChanged(e: any, val: any) {
    this.roleOptions.filter(x => x.id == val).map(x => {
      x.checked = e.checked;
      x.userID = e.checked ? this.getCurrentUser()?.id : null;
      x.user = e.checked ? this.getCurrentUser() : null;
      x.date = e.checked ? new Date() : null;
    });
    this.saveValues();
  }

  override saveValues() {
    this.save.emit(this.getValueString());
  }

  getValueString() {
    this.valueObjects = this.utils.cloneDeep(this.roleOptions);
    this.valueObjects.map(v => {
      v.user = null;
      v.roles = null;
    })
    let values = JSON.stringify(this.valueObjects);
    return values;
  }

  checkboxDisabled(o: RoleOption) {
    const userSignedOption = this.roleOptions.find(o => o.userID == this.getCurrentUser()?.id);
    if (o.id == userSignedOption?.id) {
      return false;
    }
    if (userSignedOption) {
      return true;
    }
    if (!this.hasRoles(o.roleIDs)) {
      return true;
    }
    return o.checked ? o.userID != this.getCurrentUser()?.id : false;
  }

}
