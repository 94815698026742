import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy, Injector } from '@angular/core';
import { Review, ReviewType, ReviewCycle, ReviewTypesEnum, ReviewActionItem } from '../../reviews.models';
import { ReviewsService } from '../../reviews.service';
import { ReviewsUpdate } from '../../store/reviews/reviews.action';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'reviews-boards-reviews',
  templateUrl: './reviews-boards-reviews.component.html',
  styleUrls: ['./reviews-boards-reviews.component.scss']
})
export class ReviewsBoardsReviewsComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() reviewCycle!: ReviewCycle;
  @Output() selectedReview = new EventEmitter<Review>();
  reviewTypes!: ReviewType[];

  reviews!: Review[];
  reviews$!: Observable<Review[]>;
  reviewsSubs!: Subscription;

  actionItems!: ReviewActionItem[];
  actionItems$!: Observable<ReviewActionItem[]>;
  actionItemsSubs!: Subscription;

  reviewCycles!: ReviewCycle[];
  reviewCycles$!: Observable<ReviewCycle[]>;
  reviewCyclesSubs!: Subscription;

  reviewTypesEnum = ReviewTypesEnum;

  constructor(
    protected override injector: Injector,
    private service: ReviewsService
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.reviewsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit(): void {
    // this.loadReviewCycles();
    this.loadReviews();
    this.loadActionItems();
  }

  loadReviews() {
    this.reviews$ = this.store.select(state => state.Reviews.data);
    this.reviewsSubs = this.reviews$.subscribe(data => {
      if (data.length) {
        this.reviews = data.filter(x => x.reviewCycleID == this.reviewCycle.id).sort((a, b) => this.utils.sort(a.reviewDate, b.reviewDate, true));
      }
      this.reviewTypes = [];
      this.reviews?.filter(r => !r.reviewCycle).map(r => r.reviewCycle = this.reviewCycle);

      // this.reviews?.map(x => x.reviewType).sort((a, b) => a.order - b.order).map(r => {
      //   if (!this.reviewTypes.map(x => x.id).includes(r.id)) {
      //     this.reviewTypes.push(r);
      //   }
      // });
      this.reviews?.map(x => x.reviewType).map(r => {
        if (!this.reviewTypes.map(x => x.id).includes(r.id)) {
          this.reviewTypes.push(r);
        }
      });

    });
  }

  loadActionItems() {
    this.actionItems$ = this.store.select(state => state.ReviewActionItems.data);
    this.actionItemsSubs = this.actionItems$.subscribe(data => {
      this.actionItems = data;
    });
  }

  getReviews(reviewType: ReviewType) {
    return this.reviews?.filter(x => x.reviewTypeID == reviewType?.id);
  }

  getReviewDetails(review?: Review) {
    setTimeout(async () => {
      if (review) review = await this.service.getReviewById(review.id).toPromise();
      if (review) this.store.dispatch(new ReviewsUpdate(review.id, review));
      this.selectedReview.emit(review);
      this.scrollToTop('sidenav-content');
    }, 100);
  }

  getClass(color?: string) {
    return color + '-background';
  }

  getActionItems(review: Review, isStopper: boolean) {
    let result = 0;
    review.reviewActionItems = this.actionItems.filter(a => a.reviewID == review.id);
    if (isStopper) {
      result = review.reviewActionItems?.filter(x => x.reviewActionItemType?.isStopper && x.status == 1).length;
    }
    else {
      result = review.reviewActionItems?.filter(x => !x.reviewActionItemType?.isStopper && x.status == 1).length;
    }
    if (result == null) {
      result = 0;
    }
    return result;
  }

}
