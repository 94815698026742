<div class="review-detail">
  <div class="row">
    <div class="col-12 col-md-6 pr-md-2">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-4">
              <h6>General Information</h6>
            </div>
            <div class="col-7">
              <div class="full-width">
                <span class="hilite"
                  >{{ label() }} {{ review?.reviewType?.code }} -
                  {{ review?.reviewCycle?.reviewBoard?.resource?.name }}</span
                >
                <span id="serialNumber">{{
                  review?.reviewCycle?.name + "/" + review?.id
                }}</span>

                <span class="float-right">
                  <span
                    class="status {{
                      review?.reviewStatus?.color + '-background'
                    }}"
                    >{{ review?.reviewStatus?.name }}</span
                  >
                </span>
              </div>
            </div>
            <div class="col-1">
              <mat-icon class="float-right">info</mat-icon>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-12 request">
              <div class="row">
                <div class="col-4">Review Request:</div>
                <div class="col-8">
                  <span class="hilite">{{ review?.reviewCycle?.name }}</span>
                </div>
                <div class="col-4 small text-right">Description:</div>
                <div class="col-8">
                  <span
                    class="small"
                    [innerHTML]="review?.reviewCycle?.description"
                  ></span>
                </div>
                <div class="col-4 small text-right">Requested by:</div>
                <div class="col-8">
                  <span class="hilite">{{
                    review?.reviewCycle?.createdByUser?.name
                  }}</span>
                  on
                  <span class="date">{{
                    review?.reviewCycle?.createdOn | date
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-4">Review Type:</div>
            <div class="col-8">
              <span class="hilite"
                >{{ label() }} {{ review?.reviewType?.name }} ({{
                  review?.reviewType?.code
                }})</span
              >
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-4">
              {{
                review?.reviewCycle?.reviewBoard?.reviewBoardType?.resourceType
                  ?.name
              }}:
            </div>
            <div class="col-8">
              <span class="hilite">{{
                review?.reviewCycle?.resource?.name
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-4">Review Date:</div>
            <div class="col-8" *ngIf="!dateEditing">
              <span class="hilite date">{{ review?.reviewDate | date }} </span>
              <span class="float-right"
                ><button
                  mat-icon-button
                  color="accent"
                  (click)="changeDate(review)"
                  *ngIf="checkPrivilege(privilege.ChangeReviewDate)"
                >
                  <mat-icon>edit</mat-icon>
                </button></span
              >
            </div>
            <div class="col-8" *ngIf="dateEditing">
              <div class="row">
                <div class="col-9">
                  <datetime-picker
                    [dateTime]="reviewDateTimeCtrl.value"
                    (changed)="reviewDateTimeCtrl.setValue($event)"
                  ></datetime-picker>
                </div>
                <div class="col-3 text-right pt-2 pl-0">
                  <button
                    [disabled]="dateChanged()"
                    mat-flat-button
                    class="small-button green-background"
                    (click)="saveDate()"
                  >
                    <mat-icon class="button-icon">save</mat-icon>
                  </button>
                  <button
                    mat-flat-button
                    class="small-button"
                    color="warn"
                    (click)="dateEditing = false"
                  >
                    <mat-icon class="button-icon">cancel</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- <reviews-extensions-details
            *ngIf="review?.reviewExtensions"
            [review]="review"
          ></reviews-extensions-details> -->
        </div>
        <div
          class="card-footer"
          *ngIf="
            review?.reviewStatus?.reviewStatusActions && checkActionsRoles()
          "
        >
          <div class="row">
            <div class="col-12 d-flex justify-content-end">
              <button
                mat-flat-button
                *ngFor="
                  let action of review?.reviewStatus?.reviewStatusActions;
                  let first = first
                "
                [ngClass]="
                  getActionButtonColor(
                    action,
                    hasStopper &&
                      review?.reviewStatus?.id !== reviewStatusEnum.Draft &&
                      action.nextReviewStatus?.type !== statusType.Canceled
                  )
                "
                (click)="
                  executeAction(action.id, review, action.nextReviewStatusID)
                "
                class="ml-2"
                [disabled]="
                  hasStopper &&
                  review?.reviewStatus?.id !== reviewStatusEnum.Draft &&
                  action.nextReviewStatus?.type !== statusType.Canceled
                "
              >
                {{ action.name }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-3">
              <h6>Member Roster</h6>
            </div>
            <div class="col-5 text-right">
              <!-- {{ reviewMembers?.similarRoster?.name }} -->
            </div>
            <!-- Copy roster email list -->
            <div class="col-3">
              <button
                [disabled]="!rosterCheck(review)"
                mat-flat-button
                color="primary"
                (click)="copyEmails(review)"
              >
                Copy Roster Emails
              </button>
            </div>

            <div class="col-1">
              <mat-icon class="float-right">account_circle</mat-icon>
            </div>
          </div>
        </div>
        <div class="card-body">
          <app-reviews-boards-reviews-members
            [review]="review"
            [canDelete]="checkPrivilege(privilege.AddRemoveRosterMembers)"
            [canRegister]="checkPrivilege(privilege.RegisterAttendance)"
          ></app-reviews-boards-reviews-members>
        </div>
        <div
          class="card-footer d-flex justify-content-between"
          *ngIf="
            checkPrivilege(privilege.AddRemoveRosterMembers) ||
            checkPrivilege(privilege.ManageRosterCategories)
          "
        >
          <button
            *ngIf="checkPrivilege(privilege.AddRemoveRosterMembers)"
            mat-flat-button
            color="primary"
            (click)="reviewMembers?.reviewMembersDialog()"
          >
            Add Member
          </button>
          <!-- <div class="small d-flex flex-wrap justify-content-center">
            <button
              mat-stroked-button
              color="primary"
              class="mx-1 mb-1"
              (click)="reviewMembers?.addFromSavedDialog()"
              *ngIf="reviewMembers?.reviewAttendanceSaved?.length ?? 0"
            >
              Load Roster
            </button>
            <button
              mat-stroked-button
              color="primary"
              class="mx-1 mb-1"
              (click)="reviewMembers?.saveRoster()"
              *ngIf="
                !reviewMembers?.similarRoster &&
                (reviewMembers?.reviewCategoriesToShow?.length ?? 0)
              "
            >
              Save Roster
            </button>
            <button
              mat-stroked-button
              color="accent"
              class="mx-1 mb-1"
              (click)="reviewMembers?.editSavedRoster()"
              *ngIf="checkPrivilege(privilege.ManageRosterCategories)"
            >
              Manage Rosters
            </button>
          </div>
          <button
            mat-stroked-button
            color="accent"
            (click)="reviewMembers?.reviewAttendanceCategoryDialog()"
            *ngIf="checkPrivilege(privilege.ManageRosterCategories)"
          >
            Manage Categories
          </button> -->
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 pl-md-2">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-4">
              <h6>BRC Chair Comments</h6>
            </div>
            <div class="col-7"></div>
            <div class="col-1">
              <mat-icon class="float-right">3p</mat-icon>
            </div>
          </div>
        </div>
        <div class="card-body">
          <comments-and-attachments
            [currentDocument]="review"
            [SerialNo]="serialNo"
            [ShowFiles]="false"
            Title=""
            (changed)="loadComments()"
            [disable]="!checkPrivilege(privilege.AddBRCComments)"
          ></comments-and-attachments>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-4">
              <h6>Documents</h6>
            </div>
            <div class="col-7"></div>
            <div class="col-1">
              <mat-icon class="float-right">folder</mat-icon>
            </div>
          </div>
        </div>
        <div class="card-body">
          <review-attachment-documents
            [review]="review"
            [canDelete]="checkPrivilege(privilege.AddRemoveDocuments)"
          >
          </review-attachment-documents>
        </div>
        <div
          class="card-footer d-flex justify-content-between"
          *ngIf="
            checkPrivilege(privilege.AddRemoveDocuments) ||
            checkPrivilege(privilege.ManageDocumentCategories)
          "
        >
          <div>
            <button
              mat-flat-button
              color="primary"
              (click)="reviewAttachments.addReviewDocument()"
              *ngIf="checkPrivilege(privilege.AddRemoveDocuments)"
            >
              Attach Documents
            </button>
          </div>
          <div class="centerText">
            <button
              mat-flat-button
              color="primary"
              (click)="reviewAttachments.addLinkDocument()"
              *ngIf="checkPrivilege(privilege.AddRemoveDocuments)"
            >
              Add Link
            </button>
          </div>
          <div>
            <button
              mat-stroked-button
              class="float-right"
              color="accent"
              (click)="reviewAttachments.manageCategories()"
              *ngIf="checkPrivilege(privilege.ManageDocumentCategories)"
            >
              Manage Categories
            </button>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-4">
              <h6>Action Items</h6>
            </div>
            <div class="col-7"></div>
            <div class="col-1">
              <mat-icon class="float-right">fact_check</mat-icon>
            </div>
          </div>
        </div>
        <div class="card-body">
          <reviews-action-items
            [review]="review"
            [canDelete]="checkPrivilege(privilege.AddRemoveActionItems)"
          ></reviews-action-items>
        </div>
        <div
          class="card-footer d-flex justify-content-between"
          *ngIf="
            checkPrivilege(privilege.AddRemoveActionItems) ||
            checkPrivilege(privilege.ManageActionItemCategories)
          "
        >
          <div *ngIf="checkPrivilege(privilege.AddRemoveActionItems)">
            <button
              mat-flat-button
              color="primary"
              (click)="reviewActionItems.addActionItem()"
            >
              Create Action Item
            </button>
          </div>
          <div *ngIf="checkPrivilege(privilege.ManageActionItemCategories)">
            <button
              mat-stroked-button
              class="float-right"
              color="accent"
              (click)="reviewActionItems.manageCategories()"
            >
              Manage Categories
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-4">
              <h6>Checklist Template</h6>
            </div>
            <div class="col-7"></div>
            <div class="col-1">
              <mat-icon class="float-right">dns</mat-icon>
            </div>
          </div>
        </div>
        <div class="card-body">
          <reviews-boards-reviews-details-template
            [review]="review"
            (isLoading)="service.loading = $event"
          ></reviews-boards-reviews-details-template>
        </div>
        <div class="p-2" *ngIf="review?.checklist">
          <checklist-exec-standalone
            [currentChecklist]="review?.checklist"
          ></checklist-exec-standalone>
        </div>
        <div
          class="card-footer"
          *ngIf="
            review?.checklistTemplate?.status == 1 &&
            !review?.checklist &&
            checkPrivilege(privilege.ChangeReviewTemplate)
          "
        >
          <div class="row">
            <div class="col-12 d-flex justify-content-end">
              <button
                mat-stroked-button
                class="green mr-2"
                (click)="createChecklist()"
              >
                Generate Checklist
              </button>
              <button
                mat-stroked-button
                class="green"
                (click)="selectChecklist()"
              >
                Select Checklist
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
