import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Resource } from 'src/app/components/catalogs/beamline-catalog/resource/resources';
import { ScheduleStatusEnum } from './models/enums';
import { RoleScheduleStatus } from './models/role-schedule-status';
import { Schedule } from './models/schedule';
import { ScheduleResourcesByTab, ScheduleResources } from './models/schedule-resources';
import { ScheduleSubtype } from './models/schedule-subtype';
import { ScheduleType } from './models/schedule-type';
import { UserScheduleStatus } from './models/user-schedule-status';
import { ScheduleService } from './services/schedule.service';
import { Location } from '@angular/common';
import { ScheduleValidations } from './share/schedule-validations';
import { TokenInfoService } from 'src/app/services/authentication/token-info.service';
import { AddScheduleComponent } from './schedule-tab/add-schedule/add-schedule.component';
import { ChooseRolesComponent } from './dialogs/choose-roles/choose-roles.component';
import { ChooseUsersComponent } from './dialogs/choose-users/choose-users.component';
import { UserCheck } from 'src/app/common/models/user-check';
import { UserScheduleStatusService } from './services/user-schedule-status.service';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { RoleScheduleStatusService } from './services/role-schedule-status.service';
import { RoleCheck } from 'src/app/common/models/role-check';
import { EditRequestMessageComponent } from './dialogs/edit-request-message/edit-request-message.component';
import { ReminderTabComponent } from './reminder-tab/reminder-tab.component';

@Component({
  selector: 'app-schedules-v2',
  templateUrl: './schedules-v2.component.html',
  styleUrls: ['./schedules-v2.component.scss']
})
export class SchedulesV2Component extends BaseComponent implements OnInit {

  vs = ScheduleValidations;

  @ViewChild('sidenav') sidenav!: MatSidenav;
  @ViewChild('headerTop') headerTop!: ElementRef;
  @ViewChild(ReminderTabComponent) reminderTab!: ReminderTabComponent;

  showSubMenu = false;

  disabled!: boolean;
  loading = false;
  loadingMessage!: string;

  resourceRequired!: boolean;

  filterCtrl = new FormControl();

  errorMessages!: string[];
  headerOnTop!: boolean;

  valueFilter!: string;
  createdOn!: string;

  ///////
  tmpFilter!: string;
  condition!: string;
  filteredFilter!: any[];
  currentFilter: any;
  filter: any;


  scheduleTypeId!: number;
  scheduleType?: ScheduleType;
  scheduleTypes!: ScheduleType[];
  scheduleType$!: Observable<ScheduleType[]>;
  scheduleTypeSubs!: Subscription;

  scheduleSubtype?: ScheduleSubtype;
  locationsByTab: ScheduleResourcesByTab[] = [];

  isReminderTestActive = true;

  scheduleTabs: any[] = [];
  selectedTab: number = 0;
  tabIndex = 0;
  tabName = '';
  remindersTabSelected!: boolean;

  refreshData = false;
  showCompleted = false;

  resources!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;

  constructor(
    protected override injector: Injector,
    private route: ActivatedRoute,
    private scheduleService: ScheduleService,
    public serviceUserStatus: UserScheduleStatusService,
    public serviceRoleStatus: RoleScheduleStatusService,
    private routeLocation: Location,
    public tokenInfo: TokenInfoService
  ) {
    super(injector);

  }


  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.route.params.subscribe(params => {
      this.scheduleTypeId = params['type'];
      this.loadScheduleTypes();
    });
    if (location.origin.includes('ascc')) {
      this.isReminderTestActive = false;
    }
  }

  loadScheduleTypes() {
    this.scheduleType$ = this.store.select(state => state.ScheduleTypes.data);
    this.scheduleTypeSubs = this.scheduleType$.subscribe(data => {
      if (data.length) {
        this.scheduleTypes = data;
        this.scheduleType = this.scheduleTypes.find(x => x.id == this.scheduleTypeId);
        this.loadCustomTabs();
      }
    });
  }

  loadCustomTabs() {
    this.scheduleTabs = [];
    if (this.scheduleType?.tabColumns && this.scheduleType.scheduleSubtypes.filter(x => x.isActive).length) {
      this.scheduleType.scheduleSubtypes.filter(x => x.isActive).map((s, i) => {
        const scheduleTab = {
          name: s.name,
          scheduleTypeId: this.scheduleType?.id,
          scheduleType: this.scheduleType,
          scheduleSubtypeId: s.id,
          scheduleSubtype: s,
        };
        if (i == 0) {
          this.scheduleSubtype = s;
        }
        this.scheduleTabs.push(scheduleTab);
      });
    }
    else {
      const scheduleTab = {
        name: 'Schedules',
        scheduleTypeId: this.scheduleType?.id,
        scheduleType: this.scheduleType,
      };
      this.scheduleTabs.push(scheduleTab);
    }
  }

  applyFilter(e: any) {
    const filter = e;
    this.filter = filter;
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.tabName = tabChangeEvent.tab.textLabel;
    this.tabIndex = tabChangeEvent.index;
    this.remindersTabSelected = this.tabIndex == this.scheduleTabs.length;
    this.scheduleSubtype = this.scheduleType?.scheduleSubtypes.find(x => x.id == this.scheduleTabs[this.tabIndex]?.scheduleSubtypeId);
    if (this.scheduleType?.id) {
      const newRoute = '/schedules/' + this.scheduleType.id + '?tab=' + this.tabIndex;
      this.routeLocation.replaceState(newRoute);
    }
  }

  setLocations(e: any) {
    const index = this.locationsByTab.findIndex(x => x.scheduleSubtype == e.scheduleSubtype && x.scheduleType == e.scheduleType);
    if (index >= 0) {
      this.locationsByTab.splice(index, 1);
    }
    this.locationsByTab.push(e);
  }

  addSchedule() {
    const allLocations: ScheduleResources[] = this.getLocationsByTab();
    const dialogRef = this.dialog.open(AddScheduleComponent, {
      width: '40vw',
      data: { scheduleType: this.scheduleType, scheduleSubtype: this.scheduleSubtype, allLocations, absiType: null, schedule: null, scheduleLocationsId: this.scheduleService.locationIDs }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.scheduleService.create(result).toPromise().then(() => this.loading = false);
      }
    });
  }

  addReminder() {
    this.reminderTab.addReminder();
  }

  getLocationsByTab(): ScheduleResources[] {
    return this.locationsByTab.find(x => x.scheduleType.id == this.scheduleType?.id && (this.scheduleSubtype && x.scheduleSubtype?.id == this.scheduleSubtype?.id || !this.scheduleSubtype))?.resources.map(res => {
      return { resource: res } as ScheduleResources;
    }) ?? [];
  }

  acquaintSchedule(schedule: Schedule) {
    if (schedule.statusId === ScheduleStatusEnum.completed) {
      schedule.colorCompletedCol = '#ff0000';
    }
    schedule.scheduleResources = schedule.scheduleResources?.sort((a, b) => this.utils.SortBeamlines(a.resource?.name, b.resource?.name));
    schedule.name = schedule.scheduleResources?.map(val => {
      if (!val.resourceId)
        return val.resourceString;
      else
        return val.resource?.name;
    }).join('<br>');
  }

  async openChooseUsers(scheduleId: number) {
    const dialogRef = this.dialog.open(ChooseUsersComponent, {
      width: '50em',
      data: await this.getUserStatusByStatus(scheduleId)
    });
    dialogRef.afterClosed().subscribe((result: UserCheck[] | null) => {
      if (result) {
        let userreminder = result.map(value => {
          return {
            firstId: scheduleId,
            secondId: value.id
          } as UserScheduleStatus;
        });
        if (userreminder.length <= 0) {
          userreminder = [{ firstId: scheduleId, secondId: 0 } as UserScheduleStatus];
        }
        this.loading = true;
        this.serviceUserStatus.createRange(userreminder).subscribe(response => {
          this.alert.message('updates', [new MessagePlaceholder('{what}', 'Users')]);
          this.loading = false;
        });
        console.log(userreminder);
      }
    });
  }

  async openChooseRoles(scheduleId: number) {
    const dialogRef = this.dialog.open(ChooseRolesComponent, {
      width: '50em',
      data: await this.getRolStatusByStatus(scheduleId)
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let rolreminder = result.map((value: RoleCheck) => {
          return {
            firstId: scheduleId,
            secondId: value.id
          } as RoleScheduleStatus;
        });
        if (rolreminder.length <= 0) {
          rolreminder = [{ firstId: scheduleId, secondId: 0 } as RoleScheduleStatus];
        }
        this.loading = true;
        this.serviceRoleStatus.createRange(rolreminder).subscribe(response => {
          this.alert.message('updates', [new MessagePlaceholder('{what}', 'Roles')]);
          this.loading = false;
        });
        console.log(rolreminder);
      }
    });
  }

  async getUserStatusByStatus(statusId: number) {
    this.loading = true;
    const value = await this.serviceUserStatus.readByStatus(statusId).toPromise();
    this.loading = false;
    return value?.map(val => val.secondId);
  }

  async getRolStatusByStatus(statusId: number) {
    this.loading = true;
    const value = await this.serviceRoleStatus.readByStatus(statusId).toPromise();
    this.loading = false;
    return value?.map(val => val.secondId);
  }

  // async openDoTest() {
  //   if (this.refreshData) {
  //     this.refreshData = false;
  //   }
  //   const dialogRef = this.dialog.open(SchedulerTestComponent, {
  //     width: '50em',
  //   });
  //   await dialogRef.afterClosed().toPromise();
  //   this.refreshData = true;
  // }

  openEditMessage() {
    const dialogRef = this.dialog.open(EditRequestMessageComponent, {
      width: '50em',
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }


  setLoading(e: any) {
    this.loading = e;
  }

}
