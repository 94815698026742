import { TokenInfoService } from "src/app/services/authentication/token-info.service";
import { PrivilegeEnum } from "src/app/services/role-privilege/privilege-enum";
import { ScheduleStatusEnum } from "../models/enums";
import { Schedule } from "../models/schedule";
import { ScheduleType } from "../models/schedule-type";

export class ScheduleValidations {

  static validateRole(controlName: string, tokenInfo: TokenInfoService, scheduleType?: ScheduleType, schedule: Schedule | null = null): boolean {
    const privileges: PrivilegeEnum[] = tokenInfo.getPrivileges() ?? [];
    if (!privileges.length) {
      return false;
    }
    switch (controlName) {
      case 'createSchedule':
        if (tokenInfo.hasPrivilege(PrivilegeEnum.CreateSchedule)) {
          return true;
        }
        return false;
      case 'updateSchedule':
        switch (scheduleType?.id) {
          case 1:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.EditABSI)) {
              if (schedule != null && schedule?.statusId !== ScheduleStatusEnum.completed) {
                return true;
              }
            }
            return false;
          case 2:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.EditEPS)) {
              if (schedule != null && schedule?.statusId !== ScheduleStatusEnum.completed) {
                return true;
              }
            }
            return false;
          case 3:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.EditTOCA)) {
              if (schedule != null && schedule?.statusId !== ScheduleStatusEnum.completed) {
                return true;
              }
            }
            return false;
          case 4:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.EditABCRS)) {
              if (schedule != null && schedule?.statusId !== ScheduleStatusEnum.completed) {
                return true;
              }
            }
            return false;
          case 5:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.EditRSS)) {
              if (schedule != null && schedule?.statusId !== ScheduleStatusEnum.completed) {
                return true;
              }
            }
            return false;
          case 13:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.EditSRVIS)) {
              if (schedule != null && schedule?.statusId !== ScheduleStatusEnum.completed) {
                return true;
              }
            }
            return false;
        }
        return false;
      case 'completeSchedule':
        switch (scheduleType?.id) {
          case 1:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.CompleteABSI)) {
              return true;
            }
            return false;
          case 2:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.CompleteEPS)) {
              return true;
            }
            return false;
          case 3:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.CompleteTOCA)) {
              return true;
            }
            return false;
          case 4:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.CompleteABCRS)) {
              return true;
            }
            return false;
          case 5:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.CompleteRSS)) {
              return true;
            }
            return false;
          case 13:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.CompleteSRVIS)) {
              return true;
            }
            return false;
        }
        return false;
      case 'createNote':
        switch (scheduleType?.id) {
          case 1:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.CreateNoteABSI)) {
              return true;
            }
            return false;
          case 2:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.CreateNoteEPS)) {
              return true;
            }
            return false;
          case 3:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.CreateNoteTOCA)) {
              return true;
            }
            return false;
          case 4:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.CreateNoteABCRS)) {
              return true;
            }
            return false;
          case 5:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.CreateNoteRSS)) {
              return true;
            }
            return false;
          case 13:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.CreateNoteSRVIS)) {
              return true;
            }
            return false;
          default:
            return true;
        }
        return false;
      case 'requestExtension':
        switch (scheduleType?.id) {
          case 1:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.RequestExtensionABSI)) {
              return true;
            }
            return false;
          case 2:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.RequestExtensionEPS)) {
              return true;
            }
            return false;
          case 3:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.RequestExtensionTOCA)) {
              return true;
            }
            return false;
          case 4:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.RequestExtensionABCRS)) {
              return true;
            }
            return false;
          case 5:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.RequestExtensionRSS)) {
              return true;
            }
            return false;
          case 13:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.RequestExtensionSRVIS)) {
              return true;
            }
            return false;
        }
        return false;
      case 'grantExtension':
        if (tokenInfo.hasPrivilege(PrivilegeEnum.GrantScheduleExtension)) {
          if (schedule != null && schedule?.statusId === ScheduleStatusEnum.requestExtension) {
            return true;
          }
        }
        return false;
      case 'grantExtensionSubdetail':
        if (tokenInfo.hasPrivilege(PrivilegeEnum.GrantScheduleExtension)) {
          if (schedule != null && schedule?.subdetail?.statusId === ScheduleStatusEnum.requestExtension) {
            return true;
          }
        }
        return false;
      case 'subscriberExtension':
        if (tokenInfo.hasPrivilege(PrivilegeEnum.SubscribePeopleRequestExtension)) {
          return true;
        }
        return false;
      case 'deleteReminder':
        if (tokenInfo.hasPrivilege(PrivilegeEnum.DeleteReminder)) {
          return true;
        }
        return false;
      case 'updateReminder':
        switch (scheduleType?.id) {
          case 1:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.EditReminderABSI)) {
              return true;
            }
            return false;
          case 2:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.EditReminderEPS)) {
              return true;
            }
            return false;
          case 3:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.EditReminderTOCA)) {
              return true;
            }
            return false;
          case 4:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.EditReminderABCRS)) {
              return true;
            }
            return false;
          case 5:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.EditReminderRSS)) {
              return true;
            }
            return false;
          case 13:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.EditReminderSRVIS)) {
              return true;
            }
            return false;
        }
        return false;
      case 'createReminder':
        switch (scheduleType?.id) {
          case 1:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.AddReminderABSI)) {
              return true;
            }
            return false;
          case 2:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.AddReminderEPS)) {
              return true;
            }
            return false;
          case 3:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.AddReminderTOCA)) {
              return true;
            }
            return false;
          case 4:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.AddReminderABCRS)) {
              return true;
            }
            return false;
          case 5:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.AddReminderRSS)) {
              return true;
            }
            return false;
          case 13:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.AddReminderSRVIS)) {
              return true;
            }
            return false;
        }
        return false;
      case 'subscriberReminder':
        if (tokenInfo.hasPrivilege(PrivilegeEnum.SubscribePeopleReminder)) {
          return true;
        }
        return false;
      case 'refuseSchedule':
        if (tokenInfo.hasPrivilege(PrivilegeEnum.RefuseScheduleExtension)) {
          return true;
        }
        return false;
      case 'deleteSchedule':
        if (tokenInfo.hasPrivilege(PrivilegeEnum.RemoveSchedule)) {
          return true;
        }
        return false;
      case 'editScheduledFor':
        switch (scheduleType?.id) {
          case 13:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.EditScheduledForSRVIS)) {
              return true;
            }
            return false;
          default:
            if (tokenInfo.hasPrivilege(PrivilegeEnum.EditScheduledFor)) {
              return true;
            }
            return false;
        }
    }
    return false;
  }

  static validateCommons(codes: string): boolean {
    if (codes.includes('ADM') || codes.includes('PC') || codes.includes('SA') || codes.includes('DAO')) {
      return true;
    }
    return false;
  }
}
