<mat-form-field
  [ariaDisabled]="disabled"
  appearance="outline"
  [ngClass]="{ 'no-subscript': noSubscript }"
>
  <mat-label>{{ label }}</mat-label>
  <mat-chip-grid #chipListRole aria-label="Role selection">
    <mat-chip-row
      class="role-chip"
      *ngFor="let role of roles"
      [removable]="!disabled"
      (removed)="remove(role)"
      [matTooltip]="role?.name ?? ''"
    >
      {{ showNames ? role.name : role?.code }}
      <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
    </mat-chip-row>
    <input
      [ngClass]="{ hidden: disabled }"
      *ngIf="!control"
      #roleInput
      [matAutocomplete]="autoRole"
      [matChipInputFor]="chipListRole"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      (keyup)="change($event)"
      [disabled]="disabled"
      (mousedown)="mousedown.emit()"
      (matChipInputTokenEnd)="clearInputValue($event)"
      #trigger="matAutocompleteTrigger"
    />

    <input
      *ngIf="control"
      [ngClass]="{ hidden: disabled }"
      #roleInput
      [matAutocomplete]="autoRole"
      [matChipInputFor]="chipListRole"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      (keyup)="change($event)"
      (mousedown)="mousedown.emit()"
      (matChipInputTokenEnd)="clearInputValue($event)"
      #trigger="matAutocompleteTrigger"
      formControlName="control"
    />
  </mat-chip-grid>
  <mat-autocomplete
    #autoRole="matAutocomplete"
    (optionSelected)="select($event)"
  >
    <mat-option
      class="role-option"
      *ngFor="let role of rolesFiltered"
      [value]="role"
    >
      <div class="row no-gutters full-width">
        <div class="col-3 d-flex justify-content-end">
          <mat-chip class="role-chip">{{ role.code }} </mat-chip>
        </div>
        <div class="col-9 small nowrap overflow-ellipsis pl-1">
          {{ role.name }}
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="control?.invalid"
    >You must choose at least one Role</mat-error
  >
  <mat-hint>{{ hint }}</mat-hint>
</mat-form-field>
