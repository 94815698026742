import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { ErrorStateMatcher, MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuillModule } from 'ngx-quill';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { ChecklistComponentsModule } from 'src/app/controls/checklist-components/checklist-components.module';
import { SharedModule } from 'src/app/modules/shared.module';
import { RsswaComponent } from './rsswa.component';
import { RsswaSidePanelComponent } from './rsswa-side-panel/rsswa-side-panel.component';
import { RsswaMainPanelComponent } from './rsswa-main-panel/rsswa-main-panel.component';
import { AppComponent } from 'src/app/app.component';
import { httpInterceptorProviders } from 'src/app/interceptors/interceptorProviders';
import { RsswaSection1Component } from './rsswa-main-panel/rsswa-section1/rsswa-section1.component';
import { RsswaShutterChipsComponent } from './rsswa-main-panel/rsswa-chips/rsswa-shutter-chips/rsswa-shutter-chips.component';
import { RsswaPerformingWorkUserChipsComponent } from './rsswa-main-panel/rsswa-chips/rsswa-performing-work-user-chips/rsswa-performing-work-user-chips.component';
import { RsswaAcceleratorLocationChipsComponent } from './rsswa-main-panel/rsswa-chips/rsswa-accelerator-location-chips/rsswa-accelerator-location-chips.component';
import { ChecklistsModule } from '../checklists/checklists.module';
import { RssFunctionalTestChipsComponent } from './rsswa-main-panel/rsswa-chips/rss-functional-test-chips/rss-functional-test-chips.component';
import { RsswaSection3Component } from './rsswa-main-panel/rsswa-section3/rsswa-section3.component';
import { RsswaSection4Component } from './rsswa-main-panel/rsswa-section4/rsswa-section4.component';
import { RsswaSection5Component } from './rsswa-main-panel/rsswa-section5/rsswa-section5.component';
import { RsswaSection6Component } from './rsswa-main-panel/rsswa-section6/rsswa-section6.component';
import { RsswaLogComponent } from './rsswa-main-panel/rsswa-log/rsswa-log.component';
import { RSSFunctionalTestPopupComponent } from './rsswa-main-panel/rss-functional-test-popup/rss-functional-test-popup.component';
import { RsswaOtherSystemChipsComponent } from './rsswa-main-panel/rsswa-chips/rsswa-other-system-chips/rsswa-other-system-chips.component';
import { RsswaSection1FunctionalTestsComponent } from './rsswa-main-panel/rsswa-section1/rsswa-section1-functional-tests/rsswa-section1-functional-tests.component';
import { RssFunctionalTestListComponent } from './rsswa-main-panel/rss-functional-test-list/rss-functional-test-list.component';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

class TouchedErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}

@NgModule({
  declarations: [
    RsswaComponent,
    RsswaSidePanelComponent,
    RsswaMainPanelComponent,
    RsswaSection1Component,
    RsswaSection3Component,
    RsswaSection4Component,
    RsswaSection5Component,
    RsswaSection6Component,
    RsswaLogComponent,
    // Other
    RsswaShutterChipsComponent,
    RsswaPerformingWorkUserChipsComponent,
    RsswaAcceleratorLocationChipsComponent,
    RsswaOtherSystemChipsComponent,
    RssFunctionalTestChipsComponent,
    RSSFunctionalTestPopupComponent,
    RsswaSection1FunctionalTestsComponent,
    RssFunctionalTestListComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    HttpClientModule,
    ScrollingModule,
    HttpClientModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatChipsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatRadioModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatGridListModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    DragDropModule,
    MatTreeModule,
    MatExpansionModule,
    MatOptionModule,
    MatSidenavModule,
    MatTooltipModule,
    QuillModule.forRoot(),
    SharedModule,
    ChecklistComponentsModule,
    ChecklistsModule
  ],
  exports: [
    RouterModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance
    },
    httpInterceptorProviders,
    { provide: ErrorStateMatcher, useClass: TouchedErrorStateMatcher }
  ],
  bootstrap: [AppComponent, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RsswaModule { }
