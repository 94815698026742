import { Component, OnInit, Inject, Injector } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/common/base/base.component';
import { ScheduleStatusEnum } from '../../models/enums';
import { Schedule } from '../../models/schedule';
import { ScheduleNote } from '../../models/schedule-note';
import { NoteType } from 'src/app/services/notes/note.model';

@Component({
  selector: 'app-request-extension',
  templateUrl: './request-extension.component.html',
  styleUrls: ['./request-extension.component.scss']
})
export class RequestExtensionComponent extends BaseComponent implements OnInit {

  formNote!: FormGroup;

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<RequestExtensionComponent>, @Inject(MAT_DIALOG_DATA) public data: Schedule) {
    super(injector);
  }

  ngOnInit(): void {
    this.formNote = this.formBuilder.group({
      description: ['', [Validators.required, this.noWhitespaceValidator]],
      scheduleId: [],
      isExtension: [],
      userId: [],
      extendedDate: ['', Validators.required]
    });
    this.formNote.controls['scheduleId'].setValue(this.data.statusId);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.formNote.controls['isExtension'].setValue(true);
    const note = this.formNote.value as ScheduleNote;
    note.type = NoteType.Extension;
    this.data.notes.push(note);
    this.data.isExtended = this.formNote.controls['isExtension'].value;
    this.data.extendedFor = this.formNote.controls['extendedDate'].value;
    this.data.beforeStatusId = this.data.statusId;
    this.data.beforeStatusTime = this.data.statusTime;
    this.data.statusId = ScheduleStatusEnum.requestExtension;
    this.dialogRef.close(this.data);
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public header(data: any) {
    return data.replace(/\<br>/gi, ", ");
  }
}
