<div class="container-fluid">
  <div class="mat-container mt-2">
    <table mat-table #table [dataSource]="dataSource">
      <ng-container matColumnDef="privilege">
        <th mat-header-cell *matHeaderCellDef class="mat-column-privilege">
          Type
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="white-back mat-column-privilege"
        >
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="roles">
        <th mat-header-cell *matHeaderCellDef class="mat-column-role">Roles</th>
        <td mat-cell *matCellDef="let element" class="py-2 mat-column-role">
          <div *ngFor="let rolePrivilege of getRoles(element)" class="py-1">
            {{ rolePrivilege.role.name + " (" + rolePrivilege.role.code + ")" }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" cdkDrag></tr>
    </table>
  </div>
</div>
