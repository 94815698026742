import { Component, EventEmitter, Injector, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Checklist } from 'src/app/components/checklists/checklists';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { RSSFunctionalTest } from '../../../rss-functional-test';
import { RSSFunctionalTestService } from 'src/app/services/rss-functional-test/rss-functional-test.service';
import { ChecklistsService } from 'src/app/components/checklists/checklists.service';
import { ChecklistTempComponent } from 'src/app/components/checklists/checklist-temp/checklist-temp.component';
import { BaseComponent } from 'src/app/common/base/base.component';
import { RSSWAResource } from '../../../rsswa';

@Component({
  selector: 'rss-functional-test-chips',
  templateUrl: './rss-functional-test-chips.component.html',
  styleUrls: ['./rss-functional-test-chips.component.scss']
})
export class RssFunctionalTestChipsComponent extends BaseComponent implements OnInit {
  @Input() placeholder!: string;
  @Input() disabled!: boolean;
  @Input() rsswa?: RSSWAResource | null;

  @Input() rssFunctionalTests?: RSSFunctionalTest[];
  @Input() showAmendment: boolean = false;
  @Input() control!: FormControl;

  @Output() edit = new EventEmitter<RSSFunctionalTest>();
  @Output() remove = new EventEmitter<number>();
  @Output() error = new EventEmitter<string>();

  loading: boolean = false;
  editPrivilege: boolean | null = false;
  deletePrivilege: boolean | null = false;

  constructor(
    private rssFunctionalTestService: RSSFunctionalTestService,
    private checklistService: ChecklistsService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.editPrivilege = this.hasPrivilege(PrivilegeEnum.RSSFunctionalTestEdit);
    this.deletePrivilege = this.hasPrivilege(PrivilegeEnum.RSSFunctionalTestDelete);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.control.disable();
    // if (!this.disabled)
    //   this.control.enable();
  }

  removeRSSFunctionalTest(rssFunctionalTest: RSSFunctionalTest) {
    this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: 'Are you sure you want to delete the RSS functional test?',
        icon: 'warn'
      },
    }).afterClosed().subscribe(
      data => {
        if (data && rssFunctionalTest?.id) {
          this.loading = true;
          this.rssFunctionalTestService.delete(rssFunctionalTest.id, this.showAmendment).subscribe(
            () => {
              this.alert.success("The RSS functional test was deleted");
              this.remove.emit(rssFunctionalTest.id);
            },
            error => this.error.emit(error.error),
            () => this.loading = false,
          );
        }
      }
    );
  }

  editRSSFunctionalTest(rssFunctionalTest: RSSFunctionalTest) {
    this.edit.emit(rssFunctionalTest);
  }

  openViewMode(rssFunctionalTest: RSSFunctionalTest) {
    this.dialog.open(ChecklistTempComponent, {
      minWidth: '60vw',
      disableClose: true,
      data: {
        checklistTemplate: rssFunctionalTest.checklistTemplate,
      }
    })
      .afterClosed().toPromise().then((checklist: Checklist) => {
        if (checklist) {
          this.checklistService.deleteChecklist(checklist.id).toPromise();
        }
      });
  }

  checkDifferences() {
    if (this.showAmendment) {
      return !this.utils.isEquivalent(this.rsswa?.rssFunctionalTests ?? [], this.rssFunctionalTests ?? []);
    }
    return false;
  }

}
