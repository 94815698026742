import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BaseComponent } from 'src/app/common/base/base.component';
import { PrivilegeCategory } from '../../roles/services/role-privilege';
import { Observable, Subscription } from 'rxjs';
import { event } from 'jquery';

@Component({
  selector: 'privileges-catalog-table',
  templateUrl: './privileges-catalog-table.component.html',
  styleUrls: ['./privileges-catalog-table.component.scss']
})
export class PrivilegesCatalogTableComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() filter?: string;
  @Output() rowSelected = new EventEmitter<PrivilegeCategory>();

  dataSource = new MatTableDataSource<PrivilegeCategory>();

  privilegeCategory?: PrivilegeCategory;
  privilegeCategories?: PrivilegeCategory[];
  privilegeCategoriesFiltered?: PrivilegeCategory[];
  privilegeCategories$!: Observable<PrivilegeCategory[]>;
  privilegeCategoriesSubs!: Subscription;
  displayedColumns: string[] = ["name"];
  selectedRowIndex?: number;

  constructor(protected override injector: Injector) {
    super(injector)
  }

  ngOnInit() {
  }

  override ngOnDestroy(): void {
    this.privilegeCategoriesSubs?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadPrivilegeCategorys();
  }

  loadPrivilegeCategorys() {
    this.privilegeCategories$ = this.store.select(state => state.PrivilegeCategories.data);
    this.privilegeCategoriesSubs = this.privilegeCategories$.subscribe(data => {
      if (data?.length) {
        this.privilegeCategories = data.sort((a, b) => this.utils.sort(a.name, b.name)).filter(d => this.filter ? d.name?.toLowerCase().includes(this.filter.toLowerCase()) : true);
        this.dataSource = new MatTableDataSource(this.privilegeCategories)
      }
    });
  }

  rowClicked(category: PrivilegeCategory) {
    this.rowSelected.emit(category);
  }


}
