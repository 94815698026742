<div
  class="cell pt-1"
  [ngClass]="{ 'bottom-border': schedule.subdetail }"
  [ngStyle]="{ 'background-color': getColor(schedule, false) }"
>
  <button
    mat-icon-button
    *ngIf="
      validateShowMenu(schedule.statusId, schedule) &&
      validateAction(schedule.statusId, ScheduleStatus.completed)
    "
    [matMenuTriggerFor]="menu"
    aria-label="Schedules menu"
  >
    <mat-icon color="primary">more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <!--If a user can create a schedule they can also edit it by default-->
    <button
      mat-menu-item
      *ngIf="
        sv.validateRole('createSchedule', tokenInfo, scheduleType) ||
        sv.validateRole('updateSchedule', tokenInfo, scheduleType, schedule)
      "
      (click)="openEditSchedule(schedule)"
    >
      <mat-icon class="yellow">edit</mat-icon>
      <span>Edit</span>
    </button>
    <button
      mat-menu-item
      *ngIf="
        validateAction(schedule.statusId, ScheduleStatus.completed) &&
        sv.validateRole('createNote', tokenInfo, scheduleType)
      "
      (click)="openAddNote(schedule)"
    >
      <mat-icon class="yellow">note_add</mat-icon>
      <span>Create Note</span>
    </button>
    <button
      mat-menu-item
      *ngIf="
        validateAction(schedule.statusId, ScheduleStatus.completed) &&
        sv.validateRole('completeSchedule', tokenInfo, scheduleType)
      "
      (click)="openCompleteSchedule(schedule, ScheduleStatus.completed)"
    >
      <mat-icon class="green">done_outline</mat-icon>
      <span>Complete</span>
    </button>
    <button
      mat-menu-item
      *ngIf="
        validateAction(schedule.statusId, ScheduleStatus.requestExtension) &&
        sv.validateRole('requestExtension', tokenInfo, scheduleType)
      "
      (click)="changeStatus(schedule, ScheduleStatus.requestExtension)"
    >
      <mat-icon class="blue">today</mat-icon>
      <span>Request extension</span>
    </button>
    <button
      mat-menu-item
      *ngIf="
        validateAction(schedule.statusId, ScheduleStatus.extensionGranted) &&
        sv.validateRole('grantExtension', tokenInfo, scheduleType, schedule)
      "
      (click)="grantExtension(schedule, ScheduleStatus.extensionGranted)"
    >
      <mat-icon class="orange">offline_pin</mat-icon>
      <span>Grant extension</span>
    </button>
    <button
      mat-menu-item
      *ngIf="
        validateAction(schedule.statusId, ScheduleStatus.refuseExtension) &&
        sv.validateRole('refuseSchedule', tokenInfo, scheduleType, schedule)
      "
      (click)="changeStatus(schedule, ScheduleStatus.refuseExtension)"
    >
      <mat-icon class="red">unpublished</mat-icon>
      <span>Reject extension</span>
    </button>
    <button
      mat-menu-item
      *ngIf="
        validateAction(schedule.statusId, ScheduleStatus.remove) &&
        sv.validateRole('deleteSchedule', tokenInfo, scheduleType, schedule)
      "
      (click)="deleteSchedule(schedule)"
    >
      <mat-icon class="red">delete</mat-icon>
      <span>Delete schedule</span>
    </button>
    <button
      mat-menu-item
      *ngIf="sv.validateRole('editScheduledFor', tokenInfo, scheduleType)"
      (click)="openEditScheduledFor(schedule)"
    >
      <mat-icon class="purple">today</mat-icon>
      <span>Scheduled For</span>
    </button>
  </mat-menu>
</div>
