import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, } from "@angular/core";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { Store } from "@ngxs/store";
import { Observable, Subscription } from "rxjs";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { Role } from "src/app/components/catalogs/roles/services/role";
import { RoleOption } from "src/app/components/checklists/checklists";

@Component({
  selector: "cl-editor-checkbox-roles-selection",
  templateUrl: "./cl-editor-checkbox-roles-selection.component.html",
  styleUrls: ["./cl-editor-checkbox-roles-selection.component.scss"],
})
export class ClEditorCheckboxRolesSelectionComponent
  implements OnInit, OnDestroy, OnChanges {
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @Input() disabled!: boolean;
  @Input() roleOption?: RoleOption;
  @Output() roleOptionChanged = new EventEmitter<RoleOption>();

  role?: Role | null;
  roles?: Role[];
  allRoles?: Role[];
  rolesFiltered?: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  constructor(private store: Store, private cdRef: ChangeDetectorRef) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.loadRoles();
  }

  ngOnDestroy(): void {
    this.rolesSubs?.unsubscribe();
  }

  ngOnInit(): void { }

  loadRoles() {
    this.roles$ = this.store.select((state) => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe((data) => {
      this.allRoles = data;
      this.rolesFiltered = data;
      if (this.roleOption?.roleIDs?.length) {
        this.roles = this.allRoles.filter((x) =>
          this.roleOption?.roleIDs?.includes(x.id ?? 0)
        );
      } else {
        this.cdRef.detectChanges();
      }
      this.filterRoles();
    });
  }

  selectedRole(event: MatAutocompleteSelectedEvent) {
    if (this.roles == null) {
      this.roles = [];
    }
    const role: Role = event.option.value;
    this.roles.push({ id: role.id, code: role.code });
    this.role = null;
    this.filterRoles();
    this.valueChanged();
  }

  filterRoles() {
    this.rolesFiltered = this.allRoles?.filter(
      (x) => !this.roles?.map((r) => r.id).includes(x.id)
    );
  }

  onKeyUp(e: any) {
    const val = e.target.value;
    this.filterRoles();
    this.rolesFiltered = this.rolesFiltered?.filter(
      (x) =>
        x.name?.toLowerCase().includes(val.toLowerCase()) ||
        x.code?.toLowerCase().includes(val.toLowerCase())
    );
  }

  removeRole(role: Role) {
    const index = this.roles?.indexOf(role) ?? -1;
    this.roles?.splice(index, 1);
    this.valueChanged();
  }

  clearInputValue(event: MatChipInputEvent) {
    const input = event.input;
    if (input) {
      input.value = "";
    }
  }

  valueChanged() {
    if (this.roleOption) {
      const roles = this.roles?.map((x) => x && x.id) ?? [];
      this.roleOption.roleIDs = roles;
      this.roleOptionChanged.emit(this.roleOption);
    }
  }
}
