import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter, Injector } from '@angular/core';
import { config, Observable, Subscription } from 'rxjs';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { FormStatusEnum, NotificationType } from 'src/app/common/enumerations/enumerations';
import { Checklist, ChecklistTemplate, Annotation, ChecklistExecStatus, AnnotationStatus, SaveChecklistStatusResource } from 'src/app/components/checklists/checklists';
import { ChecklistsService } from 'src/app/components/checklists/checklists.service';
import { YesNoDialogComponent } from '../../yes-no-dialog/yes-no-dialog.component';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { map } from 'rxjs/operators';
import { NotificationTemplate } from 'src/app/components/catalogs/notifications/services/notification-template';
import { NotificationComponent } from '../../notifications/notification.component';
import { NotificationMessage } from 'src/app/components/catalogs/notifications/services/notification.models';
import { NotificationService } from 'src/app/components/catalogs/notifications/services/notification.service';

@Component({
  selector: 'cl-status-change',
  templateUrl: './cl-status-change.component.html',
  styleUrls: ['./cl-status-change.component.scss']
})
export class ClStatusChangeComponent extends ClBaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() currentChecklistTemplate?: ChecklistTemplate;
  @Output() loading = new EventEmitter<boolean>();

  currentChecklist?: Checklist;

  totalAnnotations!: number;
  activeAnnotations!: number;

  annotations!: Annotation[];
  annotations$!: Observable<Annotation[]>;
  annotationsSubs!: Subscription;

  clStatus?: ChecklistExecStatus;
  statusId!: number;
  statuses?: ChecklistExecStatus[];

  rolesToNotify?: Role[];
  roleCodesToNotify?: string;
  roles?: Role[];
  roles$!: Observable<Role[]>;
  rolesSubs!: Subscription;

  notificationTemplate?: NotificationTemplate;
  notificationTemplates$!: Observable<NotificationTemplate[]>;
  notificationTemplatesSubs!: Subscription;

  notificationMessage?: NotificationMessage;
  toAddresses?: string[];

  constructor(
    protected override injector: Injector,
    private service: ChecklistsService,
    private notificationService: NotificationService
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.annotationsSubs?.unsubscribe();
    this.rolesSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadRoles();
    this.currentChecklist = this.checklist as Checklist;
    const configuration = this.utils.JSONparse(this.configuration);
    this.statusId = configuration?.status;
    this.roleIds = configuration?.roles;
    this.rolesToNotify = this.roles?.filter(r => this.roleIds?.includes(r.id));
    this.roleCodesToNotify = this.rolesToNotify?.map(r => r.code).join(', ');

    if (this.currentChecklist?.checklistTemplate) {
      this.currentChecklistTemplate = this.currentChecklist.checklistTemplate;
    }
    this.statuses = this.currentChecklistTemplate?.documentType?.checklistExecStatuses;
    this.clStatus = this.statuses?.find(x => x.id == this.statusId);
    this.loadAnnotations();
    if (this.rolesToNotify?.length)
      this.loadNotificationTemplates();
  }

  ngOnInit(): void {

  }

  loadRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.rolesSubs = this.roles$.subscribe(data => {
      if (data?.length) {
        this.roles = data;
      }
    });
  }

  loadAnnotations() {
    this.annotations$ = this.store.select(state => state.Annotations.data);
    this.annotationsSubs = this.annotations$.subscribe(data => {
      if (this.currentChecklist) {
        this.annotations = data.filter(x => x.checklistID == this.currentChecklist?.id);
        this.activeAnnotations = this.annotations?.filter(x => x.status == AnnotationStatus.Active).length;
        this.totalAnnotations = this.annotations?.filter(x => x.checklistID == this.currentChecklist?.id).length;
      }
    });
  }

  loadNotificationTemplates() {
    this.notificationTemplates$ = this.store.select(state => state.NotificationTemplates.data);
    this.notificationTemplatesSubs = this.notificationTemplates$.subscribe(async data => {
      if (data?.length) {
        this.notificationTemplate = data.find(n => n.type == NotificationType.ChecklistStatusChange);
        const notificationMessage = await this.notificationService.getNotification(25, this.currentChecklist?.id ?? 0, [], this.statusId.toString()).toPromise().catch(error => console.log(error));
        if (notificationMessage) {
          this.notificationMessage = notificationMessage;
          const emlObj = JSON.parse(notificationMessage.toAddresses);
          this.toAddresses = emlObj.computed;
        }
        else this.createNotification();
      }
    });
  }

  createNotification() {
    if (this.rolesToNotify?.length) {
      this.toAddresses = this.users.filter(u => this.utils.intersect(this.rolesToNotify?.map(r => r.id), u.userRole?.map(ur => ur.roleID))).map(u => '(' + u.name + ') ' + u.emailAddress);
      const messageBody = this.notificationTemplate?.messageBody
        .replaceAll('{serialNo}', this.currentChecklist?.serialNo ?? '')
        .replaceAll('{statusName}', this.clStatus?.name ?? '');

      this.notificationMessage = {
        serialNo: this.currentChecklist?.serialNo,
        notificationId: 0,
        documentId: this.currentChecklist?.id,
        toAddresses: this.utils.JSONstringify({ computed: this.toAddresses }),
        messageSubject: this.notificationTemplate?.messageSubject,
        messageBody,
        type: 25,
        code: this.statusId.toString(),
        messageAdditionalText: '',
        signature: this.notificationTemplate?.signature,
      } as NotificationMessage;
      this.notificationService.createNotification(this.notificationMessage).toPromise().then(data => {
        if (data)
          this.notificationMessage = data;
      }).catch(error => {
        console.log(error);
        this.alert.defaultError();
      });
    }
  }

  viewNotification() {
    if (!this.editor && !this.builder) {
      const dialogRef = this.dialog.open(NotificationComponent, {
        height: "fit-content",
        width: "80%",
        data: {
          message: this.notificationMessage,
          templateID: 25,
          emailAddresses: this.toAddresses,
          readOnly: false,
          disableSend: true,
          code: this.statusId
        },
      });

      dialogRef
        .afterClosed()
        .toPromise()
        .then(data => {

        });
    }
    else {
      this.alert.warning('This only work on Checklist Execution');
    }
  }

  setStatus() {
    if (this.currentChecklist?.checklistStatus?.formStatusID == FormStatusEnum.Reviewing) {
      if (this.activeAnnotations) {
        this.clStatus = this.statuses?.find(x => x.formStatusID == FormStatusEnum.Correcting);
      }
      else {
        this.clStatus = this.statuses?.find(x => x.formStatusID == FormStatusEnum.Approvals);
      }
    }
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        message: 'Are you sure to change the Status to <b>' + this.clStatus?.description + '</b> ?',
      }
    });
    confirm.afterClosed().toPromise().then(async data => {
      if (data && !(this.editor || this.builder)) {
        this.loading.emit(true);
        const saveChecklistStatus = {
          id: this.currentChecklist?.id,
          status: this.clStatus?.id,
          checklistStatusID: this.clStatus?.id,
          rolesToNotify: this.roleIds,
          wfTaskLocalID: this.signature?.wfTaskLocal?.id
        } as SaveChecklistStatusResource;
        await this.service.putStatus(saveChecklistStatus).toPromise().then(() => {
          if (this.notificationMessage && this.rolesToNotify?.length) {
            this.notificationService.sendNotification(this.notificationMessage).toPromise().then(() => {
              this.alert.info('Email Notifications sent to <b>' + this.roleCodesToNotify + '</b>');
            }).catch(error => {
              console.log(error);
              this.alert.defaultError();
            });
          }
          this.loading.emit(false);
        });
      }
    });
  }

  checkDisabled() {
    if (this.activeAnnotations && this.clStatus == this.statuses?.find(x => x.formStatusID == FormStatusEnum.PendingReview && this.currentChecklist?.checklistStatus?.formStatusID == FormStatusEnum.Correcting)) {
      return true;
    }
    else {
      return this.disabled;
    }
  }
}
