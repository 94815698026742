import { Component, Input } from '@angular/core';
import { Schedule } from '../../models/schedule';
import { ScheduleStatusEnum } from '../../models/enums';
import { ScheduleValidations } from '../../share/schedule-validations';
import { TokenInfoService } from 'src/app/services/authentication/token-info.service';
import { ScheduleType } from '../../models/schedule-type';

@Component({
  selector: 'schedule-menu',
  templateUrl: './schedule-menu.component.html',
  styleUrls: ['./schedule-menu.component.scss']
})
export class ScheduleMenuComponent {
  @Input() schedule!: Schedule;
  @Input() scheduleType?: ScheduleType;

  @Input() getColor!: (schedule: Schedule, isSubDetail: boolean) => string;
  @Input() validateShowMenu!: (currentStatus: ScheduleStatusEnum, schedule: Schedule) => boolean;
  @Input() validateAction!: (currentStatus: ScheduleStatusEnum, nextStatus: ScheduleStatusEnum) => boolean;
  @Input() openEditSchedule!: (element: Schedule) => void;
  @Input() openAddNote!: (schedule: Schedule) => void;
  @Input() openCompleteSchedule!: (element: Schedule, status: ScheduleStatusEnum) => void;
  @Input() changeStatus!: (schedule: Schedule | null, status: ScheduleStatusEnum) => void;
  @Input() grantExtension!: (element: Schedule, extensionGranted: ScheduleStatusEnum) => void;
  @Input() deleteSchedule!: (element: Schedule) => void;
  @Input() openEditScheduledFor!: (element: Schedule) => void;


  ScheduleStatus = ScheduleStatusEnum;
  sv = ScheduleValidations;

  constructor(public tokenInfo: TokenInfoService) { }
}
