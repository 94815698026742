<div class="card">
  <div class="card-header">
    <span class="card-title">
      Reject extension to <b>{{ header(data.name) }}</b></span
    >
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="formNote">
          <!-- <mat-form-field appearance="outline" class="no-subscript">
            <mat-label>Extension Date</mat-label>
            <input
              matInput
              formControlName="extensionDate"
              [matDatepicker]="picker"
              readonly
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field> -->
          <mat-form-field appearance="outline" class="no-subscript">
            <mat-label>Reason</mat-label>
            <textarea matInput formControlName="description"></textarea>
            <!-- <mat-error *ngIf="formNote.controls.description.hasError('required')">Reason is required</mat-error> -->
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="onNoClick()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        (click)="onSubmit()"
        [disabled]="!formNote.valid"
        cdkFocusInitial
      >
        Submit
      </button>
    </div>
  </div>
</div>
