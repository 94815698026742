<div class="map-container small">
  <mat-tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    cdkDropList
    [cdkDropListAutoScrollDisabled]="false"
    (cdkDropListDropped)="drop($event)"
  >
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node
      *matTreeNodeDef="let node"
      matTreeNodePadding
      cdkDrag
      [cdkDragData]="node"
      (mouseenter)="dragHover(node)"
      (mouseleave)="dragHoverEnd()"
      (cdkDragStarted)="dragStart()"
      (cdkDragReleased)="dragEnd()"
      cdkDropListLockAxis="y"
      (click)="scrollToNode(node)"
      [matTooltip]="node.name"
      id="{{ node.type + '-' + node.id }}"
    >
      <!-- use a disabled button to provide padding for tree leaf -->
      <button mat-icon-button disabled></button>
      <span
        [ngClass]="{
          'deleted-children': !node.deleted && node.deletedChildren,
          deleted: node.deleted,
          selected:
            node.type == 'task' &&
            service.visibleElementIds.includes('task-' + node.id)
        }"
        >{{ node.name }}</span
      >
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
      matTreeNodeToggle
      cdkDrag
      [cdkDragData]="node"
      (mouseenter)="dragHover(node)"
      (mouseleave)="dragHoverEnd()"
      (cdkDragStarted)="dragStart()"
      (cdkDragReleased)="dragEnd()"
      cdkDropList
      (cdkDropListDropped)="drop($event)"
      cdkDropListLockAxis="y"
      [cdkTreeNodeTypeaheadLabel]="node.name"
      (click)="scrollToNode(node)"
      [matTooltip]="node.name"
      id="{{ node.type + '-' + node.id }}"
    >
      <button
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name"
        (click)="onNodeToggle(node)"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </button>
      <span
        [ngClass]="{
          'deleted-children': !node.deleted && node.deletedChildren,
          deleted: node.deleted,
          selected:
            (node.type == 'section' &&
              service.visibleElementIds.includes('section-' + node.id)) ||
            (node.type == 'step' &&
              service.visibleElementIds.includes('step-' + node.id))
        }"
        >{{ node.name }}</span
      >
    </mat-tree-node>
  </mat-tree>
</div>
