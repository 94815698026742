<div class="card">
  <div class="card-header">
    <span class="card-title" [innerHTML]="title"></span>
  </div>

  <div class="card-body">
    <mat-form-field class="no-subscript">
      <mat-label><span innerHTML="{{ placeholder }}"></span></mat-label>
      <textarea
        matInput
        id="rejectReason"
        rows="5"
        [(ngModel)]="data.text"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="cancel()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="!data.text"
        (click)="accept()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
