<div class="card section">
  <div class="card-header d-flex">
    <h6 [innerHTML]="title ?? '' | placeholderHighlight : editor"></h6>
    <role-chips [roleCodes]="roleCodes" class="ml-3"></role-chips>
  </div>
  <div class="card-body">
    <comments-box
      [disabled]="disabled"
      (textChanged)="textChanged($event)"
      [textValue]="textValue"
      [formType]="formType ?? 'Preview'"
      [serialNo]="serialNo ?? 'Preview'"
      [title]="'Comments'"
      [noSubscript]="true"
    ></comments-box>
  </div>
</div>
