import { Component, OnDestroy, Input, Injector, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { RSSWAResource } from '../../rsswa';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormStatusEnum, OlogNotificationType } from 'src/app/common/enumerations/enumerations';
import { RSSFunctionalTestService } from 'src/app/services/rss-functional-test/rss-functional-test.service';
import { WFConfiguration, WfSignatureResource, WfSignature, WFTaskSignatureResource } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';
import { utils } from 'src/app/modules/libs/utils';
import { OLogService } from 'src/app/components/olog/olog.service';

@Component({
  selector: 'rsswa-section3',
  templateUrl: './rsswa-section3.component.html',
  styleUrls: ['./rsswa-section3.component.scss']
})
export class RsswaSection3Component extends BaseComponent implements OnDestroy, OnChanges {
  @Output() loading = new EventEmitter<boolean>();
  @Input() rsswa?: RSSWAResource | null;
  amendments!: any[];

  wfSignatures!: WfSignatureResource[];
  signatures!: WfSignature[];
  wfTaskSignature: WFTaskSignatureResource = new WFTaskSignatureResource();
  approvals!: number;
  disabled!: boolean;
  // Form

  constructor(
    private _wf: WorkFlowService,
    private rssFunctionalTestService: RSSFunctionalTestService,
    private ologService: OLogService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  clear() {
    this.wfSignatures = [];
    this.signatures = [];
    this.rsswa = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  refresh() {
    let data10: WfSignatureResource[];
    let configuration: WFConfiguration[] | undefined;

    if (this.rsswa?.id) {
      this.loading.emit(true);
      this.disabled = this.rsswa?.status != FormStatusEnum.WorkApprovals && this.rsswa?.status != FormStatusEnum.AmendmentSign;


      data10 = this.rsswa?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 10)?.wfSignatures?.filter(s => s.amendmentID) ?? [];
      this._wf.readConfiguration(3, this.rsswa.wfTableID ?? 0).toPromise().then(data => {
        configuration = data;
        const leftTasks = configuration?.filter(x => x.identificator == 21);
        const rightTasks = configuration?.filter(x => x.identificator == 20);

        this.wfSignatures = this.rsswa?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 3)?.wfSignatures?.filter(s => !s.amendmentID) ?? [];
        this.wfSignatures = this.wfSignatures.concat(data10);
        this.approvals = this.wfSignatures.filter(x => x.enabled).length;
        this.signatures = [];
        this.wfSignatures.sort((o1, o2) => {
          if ((o1.amendmentID == null ? 0 : o1.amendmentID) > (o2.amendmentID == null ? 0 : o2.amendmentID)) { return 1; }
          if ((o1.amendmentID == null ? 0 : o1.amendmentID) < (o2.amendmentID == null ? 0 : o2.amendmentID)) { return -1; }
          return 0;
        });
        let amendmentNumber = 0;
        let lastAmendmentID = 0;
        this.wfSignatures.map(signature => {
          if ((signature.amendmentID ?? 0) > 0 && signature.amendmentID != lastAmendmentID) {
            amendmentNumber++;
            lastAmendmentID = signature.amendmentID;
          }
          signature.amendmentNumber = amendmentNumber;

          let previousSigned = false;

          if (leftTasks?.some(x => x.wfTaskLocalID === signature.id)) {
            previousSigned = this.findSignature(rightTasks, signature.amendmentID) ?? false;
          } else if (rightTasks?.some(x => x.wfTaskLocalID === signature.id)) {
            previousSigned = this.findSignature(leftTasks, signature.amendmentID) ?? false;
          }

          if (signature.amendmentNumber && signature.requesterSignature && !signature.signed && this.rsswa?.rsswaAmendment?.id) {
            const lastAmendmentID = this.wfSignatures.map(x => x.amendmentID).sort((a, b) => utils.sort(a, b, false))[0];
            if (signature.amendmentID == lastAmendmentID) {
              signature.users = [this.getUser(this.rsswa?.rsswaAmendment?.requesterId) ?? {}];
              signature.approveEnabled = this.currentUser?.id == this.rsswa?.rsswaAmendment?.requesterId;
            }
          }

          const taskEnabled = (signature.approveEnabled || signature.disapproveEnabled || signature.unapproveEnabled) && signature.status == this.rsswa?.status;


          const s: WfSignature = {
            id: signature.id,
            approveId: signature.approveID,
            unapproveId: signature.unapproveID,
            disapproveId: signature.disapproveID,
            amendmentId: signature.amendmentID,
            name: signature.titleBefore,
            roles: signature.roles.map(r => r.id),
            roleCodes: signature.roles.map(r => r.code).join('/'),
            userNames: signature.users.map(user => user.name).join('/'),
            disabled: this.disabled || previousSigned || !taskEnabled,
            approvedBy: signature.signedBy,
            approved: signature.signed,
            approvedOn: signature.signedOn,
            approve: signature.approveEnabled,
            unapprove: signature.unapproveEnabled,
            disapprove: signature.disapproveEnabled,
            amendment: signature.amendmentEnabled,
            required: signature.required,
            taskId: signature.taskID,
            value: signature.value,
            cancelID: signature.cancelID,
            cancel: signature.cancelEnabled,
            enabled: signature.enabled,
            amendmentNumber: signature.amendmentNumber ?? 0
          };
          if (!this.signatures.some(x => x.taskId === s.taskId)) {
            this.signatures.push(s);
          }
        });
        this.amendments = [];
        this.signatures.map(s => {
          if (!this.amendments.includes(s.amendmentNumber)) {
            this.amendments.push(s.amendmentNumber);
          }
        });
        this.loading.emit(false);

      });

    }
  }

  findSignature(tasks: WFConfiguration[] | undefined, amendmentId: number) {
    return tasks?.some(task => this.wfSignatures.some(x => x.id === task.wfTaskLocalID && x.amendmentID === amendmentId && x.signedBy?.id === this.getCurrentUser()?.id));
  };

  getSignaturesByAmendment(a: any) {
    return this.signatures.filter(s => s.amendmentNumber == a);
  }

  getLastAmendment() {
    const last = this.signatures.filter(x => x.amendmentNumber).map(x => x.amendmentNumber).sort((a, b) => utils.sort(a, b, false));
    return last ? last[0] : 0;
  }

  async approve(taskId?: number, actionId?: number) {
    let rsswa = this.rsswa;
    const isAmendment = rsswa?.rsswaAmendment != null;
    this.wfTaskSignature.taskId = taskId;
    this.wfTaskSignature.actionId = actionId;
    let ok = true;
    if (rsswa?.id && taskId && isAmendment) {
      const amendmentID = this.signatures.find(s => s.taskId == taskId)?.amendmentId;
      const isFirstSignature = this.signatures.filter(s => s.amendmentId == amendmentID).findIndex(s => s.taskId == taskId) == 0;
      console.log(this.signatures);
      let toActive = this.checkToActiveStatus(rsswa, taskId);
      if (toActive && !isFirstSignature) {
        ok = await this.showAproveToActiveMessage();
        if (ok) {
          const response = await this.ologService.postToOlog(this.formTypeEnum.RSSWA, this.rsswa?.id ?? 0, isAmendment ? OlogNotificationType.Amended : OlogNotificationType.Activate, this.amendments[this.amendments.length - 1]);
          ok = response?.ok ?? false;

          if (this.rsswa?.id)
            await this.rssFunctionalTestService.activate(this.rsswa?.id).toPromise();
        }
      }
    }
    if (ok) {
      this.loading.emit(true);
      await this._wf.sign(this.wfTaskSignature).toPromise();
    }
    else {
      this.refresh();
    }
  }

  async unapprove(taskId?: number, actionId?: number) {
    this.loading.emit(true);
    this.wfTaskSignature.taskId = taskId;
    this.wfTaskSignature.actionId = actionId;
    await this._wf.sign(this.wfTaskSignature).toPromise();
  }

  async disapprove(taskID?: number, actionID?: number, data?: any) {
    this.loading.emit(true);
    this.wfTaskSignature.taskId = taskID;
    this.wfTaskSignature.actionId = actionID;
    this.wfTaskSignature.reason = data?.text;
    await this._wf.sign(this.wfTaskSignature).toPromise();
  }

  signCount(): number {
    if (!this.wfSignatures)
      return 0;
    return this.wfSignatures.filter(signature => signature.enabled && signature.signed).length;
  }

  checkToActiveStatus(rsswa: RSSWAResource, taskId: number) {
    const amendmentNo = this.amendments[this.amendments.length - 1] as number;
    const signatures3 = this.rsswa?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 10)?.wfSignatures?.filter(s => s.taskID != taskId && s.amendmentNumber == amendmentNo && s.enabled && (s.requesterSignature && !s.users.map(u => u.id).includes(this.currentUser?.id)));
    const signatures4 = this.rsswa?.wfTable?.wfTableLocal?.wfSectionLocals.find(s => s.number == 11)?.wfSignatures?.filter(s => s.enabled && s.amendmentNumber == amendmentNo);
    return !signatures3?.length && !signatures4?.length;
  }

}
