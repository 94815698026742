import { Component, Input } from '@angular/core';
import { Schedule, ScheduleSubdetail } from '../../models/schedule';
import { TokenInfoService } from 'src/app/services/authentication/token-info.service';
import { ScheduleStatusEnum } from '../../models/enums';
import { ScheduleType } from '../../models/schedule-type';
import { ScheduleValidations } from '../../share/schedule-validations';

@Component({
  selector: 'schedule-subdetail-menu',
  templateUrl: './schedule-subdetail-menu.component.html',
  styleUrls: ['./schedule-subdetail-menu.component.scss']
})
export class ScheduleSubdetailMenuComponent {
  @Input() schedule!: Schedule;
  @Input() scheduleType?: ScheduleType;

  @Input() getColor!: (schedule: Schedule, isSubDetail: boolean) => string;
  @Input() validateShowMenu!: (currentStatus: ScheduleStatusEnum, schedule: Schedule) => boolean;
  @Input() validateAction!: (currentStatus: ScheduleStatusEnum, nextStatus: ScheduleStatusEnum) => boolean;
  @Input() openEditSchedule!: (element: Schedule) => void;
  @Input() openAddNoteSub!: (schedule: Schedule) => void;
  @Input() openCompleteSubdetail!: (element: ScheduleSubdetail, status: ScheduleStatusEnum) => void;
  @Input() requestRejectExtensionSubdetail!: (schedule: Schedule | null, status: ScheduleStatusEnum) => void;
  @Input() grantExtensionSubdetail!: (element: ScheduleSubdetail, extensionGranted: ScheduleStatusEnum) => void;
  @Input() deleteSchedule!: (element: Schedule) => void;
  @Input() openEditScheduledFor!: (element: Schedule) => void;


  ScheduleStatus = ScheduleStatusEnum;
  sv = ScheduleValidations;

  constructor(public tokenInfo: TokenInfoService) { }
}
