<div [ngClass]="{ 'align-chips-start': true }">
  <mat-chip-row
    class="initials"
    [ngClass]="{ 'initials-green': !disabled }"
    [matTooltip]="getTooltip()"
    [matTooltipDisabled]="false"
    matTooltipClass="tooltip-green"
  >
    {{ user?.initials }}
  </mat-chip-row>
</div>
