<div class="side-panel-table">
  <div class="left-table-container table-container mat-container scrollbar">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="pl-16">
          Category
        </mat-header-cell>
        <mat-cell
          class="font-weight-bold pl-16"
          *matCellDef="let element"
          nowrap
          [ngClass]="{
            gray: element.privileges.length == 0
          }"
          ><span [innerHTML]="element.name | highlightSearch : filter"></span>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        #matrow
        *matRowDef="let row; columns: displayedColumns"
        (click)="rowClicked(row)"
        class="table-row"
        [matTooltip]="row.description"
        [matTooltipPosition]="'right'"
        matTooltipClass="tooltip-white tooltip-wide"
        [ngClass]="{ 'mat-row-highlight': selectedRowIndex == row.id }"
      ></mat-row>
    </mat-table>
  </div>
</div>
