<div class="pb-3" *ngFor="let reviewCategory of reviewCategoriesToShow">
  <div class="row">
    <div class="col-12 col-sm-12">
      <mat-label>{{ reviewCategory.name }}</mat-label>
    </div>
  </div>
  <div class="mat-container no-scroll">
    <mat-table
      [dataSource]="dataSourceMembersMap[reviewCategory.id]"
      matSort
      (matSortChange)="sortData($event, reviewCategory)"
    >
      <ng-container matColumnDef="member">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Member
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          {{ element.user?.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Role
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          {{ element.role?.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="attendance">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Attendance
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-break">
          <mat-checkbox
            [disabled]="!canRegister"
            [(ngModel)]="element.attended"
            (change)="setAttendance($event.checked, element)"
          ></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container
        matColumnDef="options"
        *ngIf="
          canDelete &&
          review?.reviewStatusID != reviewStatusEnum.Completed &&
          review?.reviewStatusID != reviewStatusEnum.Canceled
        "
      >
        <mat-header-cell *matHeaderCellDef class="w-80">
          Action
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="w-80">
          <button
            *ngIf="reviewCategory.id != -1"
            mat-icon-button
            color="accent"
            (click)="editReviewMember(element)"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            color="warn"
            (click)="deleteReviewMember(element)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row
        #header
        class="p-0 headerRow"
        *matHeaderRowDef="displayedColumnsMembers"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumnsMembers"
        class="cursor-arrow"
      ></mat-row>
    </mat-table>
  </div>
</div>
