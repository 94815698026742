import { Component, OnInit, inject, Injector } from '@angular/core';
import { BaseComponent } from '../../../common/base/base.component';
import { Privilege, PrivilegeCategory } from '../roles/services/role-privilege';

@Component({
  selector: 'privileges-catalog',
  templateUrl: './privileges-catalog.component.html',
  styleUrls: ['./privileges-catalog.component.scss']
})
export class PrivilegesCatalogComponent extends BaseComponent implements OnInit {

  privilegeCategory?: PrivilegeCategory;

  valueFilter!: string;
  filter: any;
  loading?: boolean;

  constructor(protected override injector: Injector) {
    super(injector)
  }

  ngOnInit() {
    this.moduleTitle = "Privilege Catalog";
  }

  applyFilter(e: any) {
    if (!e)
      this.valueFilter = '';
    this.filter = e;
  }

  rowSelected(category: PrivilegeCategory) {
    this.privilegeCategory = category;
  }

}
