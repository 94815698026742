import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Schedule } from '../../models/schedule';
import { Note } from 'src/app/services/notes/note.model';

@Component({
  selector: 'schedule-tab-cell',
  templateUrl: './schedule-tab-cell.component.html',
  styleUrls: ['./schedule-tab-cell.component.scss']
})
export class ScheduleTabCellComponent {
  @Input() schedule!: Schedule;
  @Input() mainProperty?: string; // Accepting actual values instead of strings
  @Input() mainPropertyName?: string;
  @Input() subdetailProperty?: string; // Accepting actual values instead of strings
  @Input() filter?: string;
  @Input() getColor!: (schedule: Schedule, isSubDetail: boolean) => string;
  @Input() deleteNote!: (schedule: Schedule, note: Note) => void;
  @Input() deleteNoteSub!: (schedule: Schedule, note: Note) => void;
  @Input() hutchCondition?: boolean;

  constructor(private datePipe: DatePipe) { }

  ngOnInit() {
    console.log('Main Property Name:', this.mainPropertyName);
    console.log('Main Property:', this.mainProperty);
    console.log('Subdetail Property:', this.subdetailProperty);
  }

  format(value: any, isSubdetail = false): string {
    // Special case for extendedDate
    if (this.mainPropertyName == 'extendedDate') {
      if (!isSubdetail)
        return (this.schedule.extendedDate ?
          this.datePipe.transform(this.schedule.extendedDate, 'M/d/yy') + '<br>' : '') +
          (this.schedule.extendedFor ? 'Requested for ' + this.datePipe.transform(this.schedule.extendedFor, 'M/d/yy') : '');
      if (isSubdetail)
        return (this.schedule.subdetail?.extendedDate ? this.datePipe.transform(this.schedule.subdetail?.extendedDate, 'M/d/yy') + '<br>' : '') + (this.schedule.subdetail?.extendedFor ? 'Requested for ' + this.datePipe.transform(this.schedule.subdetail?.extendedFor, 'M/d/yy') : '');
    }

    // Check if the value is null, undefined, or not a valid date string
    if (!value) return '';

    // Use a strict regex to validate date strings (e.g., 'YYYY-MM-DD', 'MM/DD/YYYY', etc.)
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?$/;

    // If the value doesn't match the regex, return it as is
    if (!dateRegex.test(value)) {
      return value;
    }
    // Try to create a Date object
    const parsedDate = new Date(value);

    // Check if the parsed date is valid
    if (isNaN(parsedDate.getTime())) {
      return value; // Return the original value if it's not a valid date
    }

    // Return the formatted date
    return this.datePipe.transform(parsedDate, 'M/d/yy') || '';
  }

}
