<div class="card">
  <div class="card-header">
    <span class="card-title">
      <span *ngIf="schedule == null"> New Schedule </span>
      <!--<label class="center bold" *ngIf="schedule != null"> {{data?.schedule?.name}} </label>-->
      <span *ngIf="schedule != null"> Edit Schedule </span>
    </span>
  </div>
  <div class="card-body">
    <form [formGroup]="scheduleForm">
      <div class="row">
        <div class="col-12">
          <mat-form-field class="locationInput">
            <mat-label>Location(s)...</mat-label>
            <mat-chip-grid
              #chipListLoc
              aria-label="Location selection"
              [disabled]="false"
            >
              <mat-chip-row
                *ngFor="let location of locations"
                [selectable]="true"
                [removable]="true"
                (removed)="removeLoc(location)"
              >
                {{
                  location.resource
                    ? location.resource.name
                    : location.resourceString
                }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip-row>
              <input
                placeholder="Location(s)..."
                #locationInput
                formControlName="locationCtrl"
                [matAutocomplete]="autoLoc"
                [matChipInputFor]="chipListLoc"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (keyup)="onLocChange($event.code)"
                (matChipInputTokenEnd)="addLoc($event)"
                #trigger="matAutocompleteTrigger"
              />
            </mat-chip-grid>
            <mat-autocomplete
              #autoLoc="matAutocomplete"
              (optionSelected)="selectedLoc($event)"
              #locationAutocomplete
            >
              <mat-option
                *ngFor="let location of filteredLocations"
                [value]="location"
              >
                {{ location.resource?.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error>You must choose at least one location</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="mb-2" *ngIf="showRemoveRequired">
        <mat-slide-toggle
          (change)="removeLastAndNextDateRequired($event.checked)"
          >New</mat-slide-toggle
        >
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <mat-form-field *ngIf="!isRemoveRequired">
            <mat-label>Last Completed</mat-label>
            <input
              matInput
              [matDatepicker]="lastCompleted"
              placeholder="mm/dd/yyyy"
              name="lastCompleted"
              formControlName="lastCompleted"
              (dateChange)="changeDate('lastCompleted')"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="lastCompleted"
            ></mat-datepicker-toggle>
            <mat-datepicker #lastCompleted></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-6">
          <mat-form-field>
            <mat-label>Next Inspection Due</mat-label>
            <input
              matInput
              [matDatepicker]="nextDue"
              placeholder="mm/dd/yyyy"
              name="nextDue"
              formControlName="nextDue"
              (dateChange)="changeDate('nextDue')"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="nextDue"
            ></mat-datepicker-toggle>
            <mat-datepicker #nextDue></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="showProcedures">
        <div class="col-12">
          <mat-form-field>
            <mat-label>Procedure Number</mat-label>
            <input
              #inputProcedure
              matInput
              placeholder="Procedure Number"
              formControlName="procedureNumber"
              [matAutocomplete]="autoProc"
              #trigger="matAutocompleteTrigger"
              (keyup)="onProcedureNumberChange()"
            />
            <mat-autocomplete
              #autoProc="matAutocomplete"
              #procedureAutocomplete
              [displayWith]="displayProcedure"
            >
              <mat-option
                class="procedure-option"
                *ngFor="let procedure of proceduresFiltered"
                [value]="procedure"
              >
                <div class="d-flex full-width justify-content-between">
                  <span> {{ procedure.procedureNumber }}</span>
                  <span class="small float-right" *ngIf="procedure.revision"
                    >Rev: {{ procedure.revision }}
                  </span>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="showHutch">
        <div class="col-12">
          <fieldset class="field-group full-width">
            <legend>Schedule SubType</legend>
            <div class="row">
              <div class="col-12">
                <div class="d-flex">
                  <mat-form-field appearance="outline">
                    <mat-label>Type</mat-label>
                    <input
                      type="text"
                      placeholder="Type"
                      matInput
                      name="subtypeCtrl"
                      formControlName="subtypeCtrl"
                      [matAutocomplete]="autoHutch"
                      [(ngModel)]="subtypeSelected"
                    />
                    <mat-autocomplete
                      #autoHutch="matAutocomplete"
                      #hutchTypeAutocomplete
                      [displayWith]="displayObject"
                      (optionSelected)="changedHutch($event)"
                    >
                      <mat-option
                        *ngFor="let type of allSubtypes"
                        [value]="type"
                      >
                        {{ type.name }}
                      </mat-option>
                    </mat-autocomplete>
                    <!-- <span matPrefix>
              <mat-spinner *ngIf="true" class="loadingHutch"></mat-spinner>
            </span> -->
                    <mat-error>You must choose a Type</mat-error>
                  </mat-form-field>
                  <div *ngIf="showLastC2" class="full-width pl-2">
                    <mat-form-field>
                      <mat-label>Hutch Functional Last Completed</mat-label>
                      <input
                        matInput
                        [matDatepicker]="lastCompleted2"
                        placeholder="mm/dd/yyyy"
                        name="lastCompleted2"
                        formControlName="lastCompleted2"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="lastCompleted2"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #lastCompleted2></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div *ngIf="showPeriodTime" class="full-width pl-2">
                    <mat-form-field>
                      <mat-label>Testing Period</mat-label>
                      <mat-select
                        [(value)]="nextDueDatePeriodSelected"
                        (selectionChange)="changeNextDueDatePeriod()"
                        formControlName="nextDueDatePeriodSelected"
                      >
                        <mat-option
                          *ngFor="let periodTime of periodsTime"
                          [value]="periodTime.value"
                        >
                          {{ periodTime.label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12" *ngIf="showLastC2">
                <mat-form-field>
                  <mat-label>Hutch Functional Procedure Number</mat-label>
                  <input
                    #inputProcedure2
                    matInput
                    placeholder="Procedure Number"
                    formControlName="procedureNumber2"
                    [matAutocomplete]="autoProc2"
                    #trigger="matAutocompleteTrigger"
                    (keyup)="onProcedureNumberChange()"
                  />
                  <mat-autocomplete
                    #autoProc2="matAutocomplete"
                    #procedureAutocomplete2
                    [displayWith]="displayProcedure"
                  >
                    <mat-option
                      class="procedure-option"
                      *ngFor="let procedure of proceduresFiltered"
                      [value]="procedure"
                    >
                      <div class="d-flex full-width justify-content-between">
                        <span> {{ procedure.procedureNumber }}</span>
                        <span
                          class="small float-right"
                          *ngIf="procedure.revision"
                          >Rev: {{ procedure.revision }}
                        </span>
                      </div>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <!-- <div class="row" *ngIf="scheduleForm?.controls?.scheduleFor?.errors?.minDate">
    <label> This date must be today or bigger </label>
  </div> -->
    </form>
  </div>

  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button color="warn" (click)="onNoClick(false)">
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="onSubmit()"
        [disabled]="!scheduleForm.valid"
      >
        Accept
      </button>
    </div>
  </div>
</div>
