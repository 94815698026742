import { Schedule } from "../../models/schedule";

export class SchedulesRefresh {
  static readonly type = '[Schedules] Refresh';
}

export class SchedulesRefreshByID {
  static readonly type = '[SchedulesRefreshByID] RefreshByID';
  constructor(public id: number) { }
}

export class SchedulesCreate {
  static readonly type = '[Schedules] Create';
  constructor(public item: Schedule) { }
}

export class SchedulesUpdate {
  static readonly type = '[Schedules] Update';
  constructor(public id: number, public item: Schedule) { }
}

export class SchedulesDelete {
  static readonly type = '[Schedules] Delete';
  constructor(public id: number) { }
}
