<div class="pb-4 pr-1">
  <div class="full-width mb-1">
    <mat-accordion>
      <div
        cdkDropList
        (cdkDropListDropped)="drop($event)"
        cdkDragLockAxis="y"
        [cdkDropListDisabled]="disabled"
      >
        <mat-expansion-panel
          class="section-expansion-panel"
          [ngClass]="{ 'mb-2 mt-2': section.expanded }"
          *ngFor="let section of sections"
          (afterExpand)="showSection(section)"
          (afterCollapse)="hideSection(section)"
          [expanded]="service.selectedNode?.sectionNode?.id == section.id"
          cdkDrag
          (cdkDragMoved)="service.onDragMoved($event)"
          cdkDragLockAxis="y"
          [cdkDragDisabled]="
            disabled || sections.length == 1 || section.expanded
          "
        >
          <mat-expansion-panel-header>
            <mat-panel-title
              *ngIf="service.selectedNode?.sectionNode?.id != section.id"
            >
              <button
                mat-icon-button
                cdkDragHandle
                [cdkDragHandleDisabled]="
                  disabled || sections.length == 1 || section.expanded
                "
                color="accent"
                style="cursor: move"
                *ngIf="!section.logID && !disabled"
              >
                <mat-icon>reorder</mat-icon>
              </button>
              <span class="h5 ml-3 mb-0">
                {{ section.number + ". " + section.name }}
              </span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div
            *ngIf="service.selectedNode?.sectionNode?.id == section.id"
            class="row no-gutters section-container"
            id="section-{{ section.id }}"
          >
            <div class="d-flex full-width">
              <div
                class="d-flex flex-column"
                *ngIf="showDeleted && section.logID"
              >
                <button
                  mat-icon-button
                  matTooltip="Restore"
                  class="restore-button"
                  (click)="restore(section)"
                >
                  <mat-icon color="primary">restore_from_trash</mat-icon>
                </button>

                <button
                  mat-icon-button
                  color="warn"
                  [matTooltip]="'Delete forever'"
                  (click)="deleteForever(section)"
                >
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </div>
              <div style="width: 100%">
                <checklist-builder-section
                  [section]="section"
                  [showDeleted]="showDeleted"
                  class="full-width"
                  [disabled]="disabled"
                  [currentVersion]="currentVersion"
                  (loading)="loading.emit($event)"
                  [sectionDropLists]="sectionDropLists"
                  [stepDropLists]="stepDropLists"
                  (refreshPreviousStep)="refreshPreviousStep($event)"
                ></checklist-builder-section>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
  </div>
  <button
    mat-stroked-button
    color="accent"
    class="ml-5 mb-5"
    *ngIf="sections?.length && !disabled"
    (click)="add()"
    [matTooltip]="'Add New Section'"
  >
    <mat-icon>add_box</mat-icon> Add Section
  </button>
</div>
