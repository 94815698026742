<div class="card">
  <div class="card-header pb-1 pt-1">
    <div class="d-flex justify-content-between">
      <div class="align-content-center card-title">Radiation Areas</div>
      <div class="d-flex justify-content-end header-buttons">
        <button
          mat-icon-button
          class="ml-1"
          color="accent"
          *ngIf="this.hasPrivilege(privilegeEnum.RadAreaCreate)"
          (click)="addRA()"
        >
          <mat-icon>add_box</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="card-body px-2 pb-1 pt-2 pb-2">
    <div class="d-flex flex-wrap">
      <div
        class="flex-fill rad-tile mb-2 mr-2 p-1 ra-item"
        *ngFor="let radArea of radAreas; let i = index"
      >
        <div class="d-flex justify-content-between pl-2">
          <div class="align-content-center">
            <span>{{ radArea.name }}</span>
          </div>
          <div class="justify-content-end">
            <button
              mat-icon-button
              *ngIf="
                this.hasPrivilege(privilegeEnum.RadAreaModify) ||
                this.hasPrivilege(privilegeEnum.RadAreaDelete)
              "
              class="focus-outline-none"
              color="primary"
              [matMenuTriggerFor]="options"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu
              #options="matMenu"
              id="{{ 'contentOptions-' + i }}"
              class="d-flex flex-column align-items-start"
            >
              <hr class="my-0" />
              <button
                mat-menu-item
                *ngIf="this.hasPrivilege(privilegeEnum.RadAreaModify)"
                (click)="editRA(radArea)"
              >
                <mat-icon color="accent">edit</mat-icon
                ><span class="Option">Edit</span>
              </button>
              <button
                mat-menu-item
                *ngIf="this.hasPrivilege(privilegeEnum.RadAreaDelete)"
                (click)="deleteRA(radArea)"
              >
                <mat-icon color="warn">delete</mat-icon
                ><span class="Option">Delete</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="d-flex flex-wrap ml-3">
          <div class="mr-2 mb-1 p-1" *ngFor="let file of radArea.files">
            <a [href]="file.url" target="_blank">
              <img class="rad-area-img" [src]="file.url" alt="Rad Area" />
            </a>
          </div>
        </div>
        <div class="row pl-2">
          <div class="col-12 small gray">
            <span>{{ radArea.description }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
