<div class="card">
  <div class="card-header">
    <span class="card-title">Email Notification ({{ serialNo }})</span>
  </div>
  <div class="card-body px-5">
    <div class="row">
      <div class="col-12 col-lg-2 float-lg-right">To:</div>
      <div class="col-12 col-lg-10 p-0">
        <mat-form-field appearance="outline" class="no-subscript">
          <mat-label>Emails</mat-label>
          <mat-chip-grid #chipList>
            <mat-chip-row
              *ngFor="let mail of emails"
              [removable]="mail?.isCustom"
              (removed)="remove(mail)"
            >
              {{
                mail?.name
                  ? mail.name + " <" + mail?.address + ">"
                  : mail?.address
              }}
              <mat-icon matChipRemove *ngIf="mail.isCustom">cancel</mat-icon>
            </mat-chip-row>
            <input
              #emailsInput
              aria-label="email"
              (keyup)="validateEmailAddresses($event)"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="add($event)"
            />
          </mat-chip-grid>
          <mat-hint class="error" *ngIf="emailError"
            >Enter a valid email address</mat-hint
          >
        </mat-form-field>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-2">Subject:</div>
      <div class="col-10 border">
        <mat-label [innerHTML]="Subject"></mat-label>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-2">Message:</div>
      <div class="col-10 border">
        <mat-label [innerHTML]="Message"></mat-label>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-2">Custom text:</div>
      <div class="col-10 p-0">
        <mat-form-field>
          <textarea
            matInput
            [disabled]="readOnly"
            placeholder="Write custom message for the notification"
            Rows="3"
            [(ngModel)]="AdditionalText"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-2">Signature:</div>
      <div class="col-10 p-0">
        <mat-label [innerHTML]="Signature"></mat-label>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <button
      mat-flat-button
      color="warn"
      class="float-left"
      (click)="cancel()"
      *ngIf="!confirmation || data.send"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="accent"
      class="float-left"
      (click)="cancel()"
      *ngIf="confirmation"
    >
      Continue without Sending
    </button>

    <button
      [matTooltip]="disabledTooltipMessage"
      matTooltipClass="warn-tooltip"
      mat-flat-button
      color="primary"
      class="float-right"
      (click)="disableSend ? continue() : sendNotification()"
      *ngIf="confirmation || data.send"
      [disabled]="disableSend"
      [matTooltipDisabled]="!disableSend"
    >
      Send Email Notification
    </button>
    <button
      mat-flat-button
      color="primary"
      class="float-right"
      style="width: 7em"
      [disabled]="readOnly || emailError"
      (click)="saveNotification()"
      *ngIf="!confirmation && !data.send"
    >
      Save
    </button>
  </div>
</div>
