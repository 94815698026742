import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { WFComponent } from '../workflow/workflow';
import { Checklist, ChecklistTemplateStatusResource, Option, SaveChecklistLocationsResource, SaveChecklistStatusResource, Shielding, TemplateType } from './checklists';
import { FormType, FormStatusEnum, ComponentType } from 'src/app/common/enumerations/enumerations';
import { utils } from 'src/app/modules/libs/utils';
import { OlogDataBaseObject } from 'src/app/components/olog/olog';
import { RadMonitorSaveResource } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-monitor';
import { RadMonitorService } from 'src/app/components/catalogs/rad-monitor/services/rad-monitor.service';
import { RadNoteSaveResource } from 'src/app/components/catalogs/rad-monitor/rad-monitor/rad-note';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { WfTaskLocalResource, WFSectionLocalResource, WFStepLocalResource, SaveTaskResource } from 'src/app/services/work-flow/work-flow';
import { RadMeter } from '../catalogs/meters-catalog/rad-meters';
import { BaseService } from 'src/app/common/base/base.service';
import { TasksUpdateAll } from './store/tasks/task.action';
import { ConditionParser } from 'src/app/modules/libs/condition-parser';

@Injectable()
export class ChecklistsService extends BaseService<Checklist, number> {

  SerialNumber = '/SerialNumbers/' + FormType.KE;
  Locations = '/Locations';

  Api = '/Checklist';

  isReviewer!: boolean;
  isPreparer!: boolean;
  precheck = new BehaviorSubject<boolean>(false);


  participants!: User[];

  components!: WFComponent[];
  components$!: Observable<WFComponent[]>;
  componentsSubs!: Subscription;

  expansionStatus: ExpansionStatus[] = [];

  wfTaskLocals?: WfTaskLocalResource[];
  wfTaskLocal$!: Observable<WfTaskLocalResource[]>;
  wfTaskLocalSubs!: Subscription;

  savingShieldings?: Shielding[] | null;
  savingTaskID?: number | null;

  constructor(
    protected override injector: Injector,
    private rmService: RadMonitorService,
  ) {
    super(injector, '/Checklist');
  }

  // Gets the Serial Number of the Form
  getSerialNo(): Observable<SerialNumber> {
    const httpresult1 = this.http.get<SerialNumber>(this.BASE_URL + this.SerialNumber);
    return httpresult1;
  }

  getAllByDocument(id: number): Observable<Checklist[]> {
    const httpresult = this.http.get<Checklist[]>(this.BASE_URL + this.Api + '/ByDocumentType/' + id);
    return httpresult;
  }

  getAllByDocumentMin(id: number): Observable<Checklist[]> {
    const httpresult = this.http.get<Checklist[]>(this.BASE_URL + this.Api + '/ByDocumentTypeMin/' + id);
    return httpresult;
  }

  getBySerialNo(sn: string): Observable<Checklist> {
    const httpresult = this.http.get<Checklist>(this.BASE_URL + this.Api + '/SerialNo/' + sn);
    return httpresult;
  }

  getStatusComplete(wfTableID: number, formStatus: FormStatusEnum): Observable<ChecklistTemplateStatusResource> {
    const httpresult = this.http.get<ChecklistTemplateStatusResource>(this.BASE_URL + this.Api + '/StatusComplete/' + wfTableID + '/' + formStatus);
    return httpresult;
  }

  getVerifiedById(id: number, showAmmendment?: boolean): Observable<Checklist> {
    const url = this.BASE_URL + this.Api + '/verified/' + id;
    const httpresult = this.http.get<Checklist>(url);
    return httpresult;
  }

  getOlog(serialNo: string): Observable<OlogDataBaseObject> {
    const httpresult = this.http.get<OlogDataBaseObject>(this.BASE_URL + this.Api + '/GetOlog/' + serialNo);
    return httpresult;
  }

  getFirstSignatureDate(wfTableId: number): Observable<Date> {
    const httpresult = this.http.get<Date>(this.BASE_URL + this.Api + '/FirstSignatureDate/' + wfTableId);
    return httpresult;
  }

  getWFTaskLocalsByStepLocalId(stepLocalId: number): Observable<WfTaskLocalResource[]> {
    return this.http.get<WfTaskLocalResource[]>(this.BASE_URL + this.Api + '/WFTaskLocals/' + stepLocalId);
  }

  post(checklist: Checklist) {
    return this.http.post<Checklist>(this.BASE_URL + this.Api, checklist);
  }

  putStatus(checklist: SaveChecklistStatusResource) {
    return this.http.put<Checklist>(this.BASE_URL + this.Api + '/SetStatus/' + checklist.id, checklist);
  }

  putLocations(checklist: SaveChecklistLocationsResource) {
    return this.http.put<Checklist>(this.BASE_URL + this.Api + '/SetLocations/' + checklist.id, checklist);
  }

  cancel(checklist: SaveChecklistStatusResource) {
    return this.http.put<Checklist>(this.BASE_URL + this.Api + '/Cancel/' + checklist.id, checklist);
  }

  put(checklist: Checklist) {
    return this.http.put<Checklist>(this.BASE_URL + this.Api + '/' + checklist.id, checklist);
  }

  refreshSection(id: number): Observable<WFSectionLocalResource> {
    const httpresult = this.http.get<WFSectionLocalResource>(this.BASE_URL + this.Api + '/RefreshSection/' + id);
    return httpresult;
  }

  getAllTemplateTypes(): Observable<TemplateType[]> {
    const httpresult = this.http.get<TemplateType[]>(this.BASE_URL + this.Api + '/AllTemplateTypes');
    return httpresult;
  }

  getTemplateTypesByDocumentId(id: number): Observable<TemplateType[]> {
    const httpresult = this.http.get<TemplateType[]>(this.BASE_URL + this.Api + '/TemplateTypesByDocument/' + id);
    return httpresult;
  }

  deleteChecklist(id: number) {
    return this.http.delete(this.BASE_URL + this.Api + '/' + id);
  }

  getTasksByStepID(id: number): Observable<WfTaskLocalResource[]> {
    return this.http.get<WfTaskLocalResource[]>(this.BASE_URL + this.Api + '/TasksByStepID/' + id);
  }

  duplicateStep(stepID: number) {
    return this.http.put<WFStepLocalResource>(this.BASE_URL + this.Api + '/DuplicateStep/' + stepID, null);
  }

  deleteStep(stepID: number) {
    return this.http.delete(this.BASE_URL + this.Api + '/DeleteStep/' + stepID);
  }

  duplicateSection(sectionID: number) {
    return this.http.put<WFSectionLocalResource>(this.BASE_URL + this.Api + '/DuplicateSection/' + sectionID, null);
  }

  deleteSection(sectionID: number) {
    return this.http.delete(this.BASE_URL + this.Api + '/DeleteSection/' + sectionID);
  }

  /////

  loadComponents() {
    this.components$ = this.store.select(state => state.Components.data);
    this.componentsSubs = this.components$.subscribe(data => {
      this.components = data;
    });
  }


  public sign(taskId: number, actionId: number, reason?: string | null, status?: FormStatusEnum | null, isASCC: boolean = false, data?: any): Observable<WfTaskLocalResource> {
    const statusValue = status ?? 0;
    const url = `${this.BASE_URL}${this.Api}/Sign?wfTaskId=${taskId}&actionId=${actionId}&status=${statusValue}&isASCC=${isASCC}${reason ? `&reason=${reason}` : ''}`;

    return this.http.put<any>(url, null);
  }


  public saveWFTask(s: SaveTaskResource, r: boolean = false): Observable<WfTaskLocalResource> {
    return this.http.put<WfTaskLocalResource>(this.BASE_URL + this.Api + '/Save/' + s.id + '/' + r, s);
  }

  setAllTasks(sections?: WFSectionLocalResource[]) {
    if (!sections?.length) return;

    this.loadComponents();

    const tasks: WfTaskLocalResource[] = sections.reduce((acc, section) => {
      section.wfStepLocals?.forEach(step => {
        step.wfTaskLocals.forEach(task => {
          if (!task.component) {
            task.component = this.components.find(x => x.id === (task.componentID || task.type));
            task.componentID = task.component?.id;
          }
          acc.push(task);
        });
      });
      return acc;
    }, [] as WfTaskLocalResource[]);

    const parsedTasks = ConditionParser.parseConditions(tasks);
    this.store.dispatch(new TasksUpdateAll(parsedTasks));
  }


  loadWFTaskLocalResources() {
    this.wfTaskLocal$ = this.store.select(state => state.WFTaskLocals.data);
    this.wfTaskLocalSubs = this.wfTaskLocal$.subscribe(data => {
      if (data?.length) {
        this.wfTaskLocals = data;
      }
    });
  }

  readyToClose() {
    this.loadWFTaskLocalResources();
    const unsignedTasks = this.wfTaskLocals?.filter(x => !x.wfTasks?.[0]?.signedBy && x.componentID != ComponentType.CloseButton && x.wfTasks?.[0]?.required && x.code != 'HANDSHAKE/BLSCI');
    return unsignedTasks?.length == 0;
  }

  taskTouched(task: WfTaskLocalResource) {
    switch (task.component?.type) {
      case 0: // Signature
        return task.isCompleted;

      case 1: // Normal
        return this.checkTouchedByComponent(task);

      default:
        return false;
    }
  }

  checkTouchedByComponent(task: WfTaskLocalResource) {
    let valueObjects = null;
    switch (task.componentID) {
      case ComponentType.CheckboxMultiple:
      case ComponentType.CheckboxMultipleWithTextbox:
        valueObjects = utils.JSONparse(utils.getTextValue(task.text));
        if (valueObjects) {
          const options = valueObjects?.find((x: any) => x.key == "checked")
            ?.val as Option[];
          return options.some(x => x.checked);
        }
        return false;
      case ComponentType.MultipleTextbox:
        valueObjects = utils.JSONparse(utils.getTextValue(task.text));
        if (valueObjects) {
          const options = valueObjects?.find((x: any) => x.key == "values")
            ?.val as Option[];
          return options.some(x => x.text);
        }
        break;
      case ComponentType.CheckboxMultipleSign:
        const options = utils.JSONparse(utils.getTextValue(task.text));
        return options ? options.some((x: any) => x.userID) : false;
      case ComponentType.RadMeters:
        valueObjects = utils.JSONparse(utils.getTextValue(task.text));
        if (valueObjects && valueObjects.data?.length) {
          const radMeters = valueObjects.data as RadMeter[];
          return radMeters.some(r => r.backgroundReadingChangedByID || r.sourceReadingChangedByID);
        }
        break;
      case ComponentType.ShieldingsComponent:
        const shieldings = utils.JSONparse(utils.getTextValue(task.text)) as Shielding[];
        const checked = shieldings?.map(s => s.roleOptions).filter(o => o?.some(r => r.checked));
        return checked?.length > 0;
      case ComponentType.Catl:
      case ComponentType.Olog:
      case ComponentType.StatusChange:
        return false;
      default:
        return task.wfTasks?.some(t => t.signedBy);
    }
  }

  async processTags(checklist: Checklist, task: WfTaskLocalResource) {
    const tag = task.code;
    switch (tag) {
      case 'RMON-SWAP':
        const incomingMonitor = this.rmService.incomingMonitorValue;
        const outgoingMonitor = this.rmService.outgoingMonitorValue;

        const faultNote = outgoingMonitor?.faultNotes?.[0];
        const faultNoteSave: RadNoteSaveResource = {
          radLocationId: faultNote?.radLocationId,
          radMonitorId: faultNote?.radMonitorId,
          description: faultNote?.description,
        };
        const outgoingSave: RadMonitorSaveResource = {
          id: outgoingMonitor?.id ?? 0,
          changeOutReason: '',
          faultNote: faultNote ? faultNoteSave : null
        };
        await this.rmService.SwapRadMonitors(incomingMonitor?.id ?? 0, outgoingSave).toPromise();
        break;
    }
  }

  saveExpansionStatus(checklist: Checklist, expandedAll: boolean) {
    let expansion = this.getExpansionByChecklist(checklist.id);
    expansion.sections = [];
    expansion.steps = [];
    checklist.wfTable?.wfTableLocal?.wfSectionLocals?.map((section) => {
      expansion.sections.push({ id: section.id ?? 0, val: section.collapsed ?? false });
      section.wfStepLocals?.map((step) => {
        expansion.steps.push({ id: step.id, val: step.collapsed ?? false });
      });
    });
    expansion.all = expandedAll;
  }

  saveSectionExpanded(checklistID: number, sectionID: number, value: boolean) {
    let expansion = this.getExpansionByChecklist(checklistID);
    let section = expansion.sections.find(x => x.id == sectionID);
    if (section) {
      section.val = value;
    }
    else {
      section = { id: sectionID, val: value } as Collapsed;
      expansion.sections.push(section);
    }
  }

  saveStepExpanded(checklistID: number, stepID: number, value: boolean) {
    let expansion = this.getExpansionByChecklist(checklistID);
    let step = expansion.steps.find(x => x.id == stepID);
    if (step) {
      step.val = value;
    }
    else {
      step = { id: stepID, val: value } as Collapsed;
      expansion.steps.push(step);
    }
  }

  getExpansionByChecklist(checklistID: number) {
    let expansion = this.expansionStatus.find(x => x.checklistID = checklistID);
    if (!expansion) {
      expansion = new ExpansionStatus();
      expansion.checklistID = checklistID;
      this.expansionStatus.push(expansion);
    };
    return expansion;
  }

  setExpansions(checklist: Checklist) {
    const expansions = this.expansionStatus.find(x => x.checklistID == checklist.id);
    if (expansions) {
      const sections = checklist.wfTable?.wfTableLocal?.wfSectionLocals;
      sections?.map(s => {
        s.collapsed = expansions.sections.find(x => x.id == s.id)?.val;
        s.wfStepLocals?.map(t => {
          t.collapsed = expansions.steps.find(x => x.id == t.id)?.val;
        });
      });
    }
  }
}



class SerialNumber {
  serialNo!: string;
}

export class ExpansionStatus {
  constructor() {
    this.sections = [];
    this.steps = [];
  }
  checklistID!: number;
  sections: Collapsed[];
  steps: Collapsed[];
  all!: boolean;
}

export interface Collapsed {
  id: number;
  val: boolean;
}
