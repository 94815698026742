<div #scheduleTable id="scheduleTable" class="mat-container mb-3">
  <mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="changeSort($event)"
    [style.max-height.px]="innerWidth < 992 ? innerHeight * 0.7 : null"
  >
    <ng-container matColumnDef="resource">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Location
      </mat-header-cell>
      <mat-cell
        [style.color]="element.colorCompletedCol"
        [ngStyle]="{ 'background-color': getCellColor(element, false) }"
        *matCellDef="let element"
      >
        <span
          class="p-2 bold text-center"
          [innerHTML]="element?.name | highlightSearch : filter"
        ></span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="procedureNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Procedure Number
      </mat-header-cell>
      <mat-cell
        [style.color]="element.colorCompletedCol"
        *matCellDef="let element"
      >
        <schedule-tab-cell
          [schedule]="element"
          [mainProperty]="element.procedure?.procedureNumber"
          [subdetailProperty]="element.subdetail?.procedure?.procedureNumber"
          [filter]="filter"
          [getColor]="getCellColor"
          [hutchCondition]="hutchGreaterThanHutchFunctional(element)"
        ></schedule-tab-cell>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="subType">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        ><span *ngIf="showTypeCol"> Type </span></mat-header-cell
      >
      <mat-cell
        [style.color]="element.colorCompletedCol"
        *matCellDef="let element"
      >
        <schedule-tab-cell
          *ngIf="element.typeId == 2"
          [schedule]="element"
          [mainProperty]="element.procedure?.procedureCategory.name"
          [subdetailProperty]="element.subdetail?.procedure?.procedureNumber"
          [filter]="filter"
          [getColor]="getCellColor"
          [hutchCondition]="hutchGreaterThanHutchFunctional(element)"
        ></schedule-tab-cell>
        <schedule-tab-cell
          *ngIf="element.typeId == 5"
          [schedule]="element"
          [mainProperty]="element.subtype?.name"
          [subdetailProperty]="hutchKeyWord + ' ' + functionalKeyWord"
          [filter]="filter"
          [getColor]="getCellColor"
          [hutchCondition]="hutchGreaterThanHutchFunctional(element)"
        ></schedule-tab-cell>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Last Completed
      </mat-header-cell>
      <mat-cell
        [style.color]="element.colorCompletedCol"
        *matCellDef="let element"
      >
        <schedule-tab-cell
          [schedule]="element"
          [mainProperty]="element?.lastCompleted"
          [subdetailProperty]="element?.subdetail?.lastCompleted"
          [filter]="filter"
          [getColor]="getCellColor"
          [hutchCondition]="hutchGreaterThanHutchFunctional(element)"
        ></schedule-tab-cell>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dueDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ getNextDueTitle() }}
      </mat-header-cell>
      <mat-cell
        [style.color]="element.colorCompletedCol"
        *matCellDef="let element"
      >
        <schedule-tab-cell
          [schedule]="element"
          [mainProperty]="element?.nextDue"
          [subdetailProperty]="element.subdetail?.nextDue"
          [filter]="filter"
          [getColor]="getCellColor"
          [hutchCondition]="hutchGreaterThanHutchFunctional(element)"
        ></schedule-tab-cell>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="scheduleDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Scheduled For
      </mat-header-cell>
      <mat-cell
        [style.color]="element.colorCompletedCol"
        *matCellDef="let element"
      >
        <schedule-tab-cell
          [schedule]="element"
          [mainProperty]="element?.scheduleFor"
          [subdetailProperty]="element.subdetail?.scheduledFor"
          [filter]="filter"
          [getColor]="getCellColor"
          [hutchCondition]="hutchGreaterThanHutchFunctional(element)"
        ></schedule-tab-cell>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="extendedDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Extended Date
      </mat-header-cell>
      <mat-cell
        [style.color]="element.colorCompletedCol"
        *matCellDef="let element"
      >
        <schedule-tab-cell
          [schedule]="element"
          [mainProperty]="element.extendedFor || element.extendedDate"
          [subdetailProperty]="
            element.subdetail?.extendedFor || element.subdetail?.extendedDate
          "
          mainPropertyName="extendedDate"
          [filter]="filter"
          [getColor]="getCellColor"
          [hutchCondition]="hutchGreaterThanHutchFunctional(element)"
        ></schedule-tab-cell>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="notes">
      <mat-header-cell *matHeaderCellDef> Notes</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <schedule-tab-cell
          [schedule]="element"
          [mainPropertyName]="'notes'"
          [filter]="filter"
          [getColor]="getCellColor"
          [deleteNote]="deleteNote"
          [deleteNoteSub]="deleteNoteSub"
          [hutchCondition]="hutchGreaterThanHutchFunctional(element)"
        ></schedule-tab-cell>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Status</mat-header-cell
      >
      <mat-cell
        *matCellDef="let element"
        [style.color]="element.colorCompletedCol"
      >
        <schedule-tab-cell
          [schedule]="element"
          [mainProperty]="
            element.statusTime == 4
              ? 'Extension Granted'
              : ScheduleStatusTime[element.statusTime]
          "
          [filter]="filter"
          [getColor]="getCellColor"
          [hutchCondition]="hutchGreaterThanHutchFunctional(element)"
        ></schedule-tab-cell>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="options">
      <mat-header-cell class="mat-column-opt" *matHeaderCellDef>
        Options
      </mat-header-cell>
      <mat-cell class="mat-column-opt" *matCellDef="let element">
        <div
          class="cell-container full-width d-flex flex-col justify-content-center"
          [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          *ngIf="!hutchGreaterThanHutchFunctional(element)"
        >
          <schedule-menu
            [schedule]="element"
            [scheduleType]="scheduleType"
            [getColor]="getCellColor"
            [validateShowMenu]="validateShowMenu"
            [validateAction]="validateAction"
            [openEditSchedule]="openEditSchedule"
            [openAddNote]="openAddNote"
            [openCompleteSchedule]="openCompleteSchedule"
            [changeStatus]="changeStatus"
            [grantExtension]="grantExtension"
            [deleteSchedule]="deleteSchedule"
            [openEditScheduledFor]="openEditScheduledFor"
          >
          </schedule-menu>
          <schedule-subdetail-menu
            [schedule]="element"
            [scheduleType]="scheduleType"
            [getColor]="getCellColor"
            [validateShowMenu]="validateShowMenu"
            [validateAction]="validateAction"
            [openEditSchedule]="openEditSchedule"
            [openAddNoteSub]="openAddNoteSub"
            [openCompleteSubdetail]="openCompleteSubdetail"
            [requestRejectExtensionSubdetail]="requestRejectExtensionSubdetail"
            [grantExtensionSubdetail]="grantExtensionSubdetail"
            [openEditScheduledFor]="openEditScheduledFor"
          >
          </schedule-subdetail-menu>
        </div>
        <div
          class="cell-container full-width d-flex flex-col justify-content-center"
          [ngStyle]="{ 'background-color': getCellColor(element, false) }"
          *ngIf="hutchGreaterThanHutchFunctional(element)"
        >
          <schedule-subdetail-menu
            [schedule]="element"
            [scheduleType]="scheduleType"
            [getColor]="getCellColor"
            [validateShowMenu]="validateShowMenu"
            [validateAction]="validateAction"
            [openEditSchedule]="openEditSchedule"
            [openAddNoteSub]="openAddNoteSub"
            [openCompleteSubdetail]="openCompleteSubdetail"
            [requestRejectExtensionSubdetail]="requestRejectExtensionSubdetail"
            [grantExtensionSubdetail]="grantExtensionSubdetail"
            [openEditScheduledFor]="openEditScheduledFor"
          >
          </schedule-subdetail-menu>
          <schedule-menu
            [schedule]="element"
            [scheduleType]="scheduleType"
            [getColor]="getCellColor"
            [validateShowMenu]="validateShowMenu"
            [validateAction]="validateAction"
            [openEditSchedule]="openEditSchedule"
            [openAddNote]="openAddNote"
            [openCompleteSchedule]="openCompleteSchedule"
            [changeStatus]="changeStatus"
            [grantExtension]="grantExtension"
            [deleteSchedule]="deleteSchedule"
            [openEditScheduledFor]="openEditScheduledFor"
          >
          </schedule-menu>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row
      #header
      *matHeaderRowDef="displayedColumnsSchedule; sticky: true"
    >
    </mat-header-row>
    <mat-row
      #matrow
      *matRowDef="let row; columns: displayedColumnsSchedule"
      [attr.position]="getRowPosition(row)"
      [ngClass]="{ selected: row.id == selectedRow }"
    >
    </mat-row>
  </mat-table>
</div>
