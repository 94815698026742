<div class="card">
  <div class="card-header">
    <div class="d-flex">
      <div class="col-2 p-0">
        <div class="card-title">Current Activity</div>
      </div>
      <!-- <div class="col-2">
        <div class="row">
          <span>Forms and Reviews</span>
        </div>
        <div class="row mt-2">
          <span>Checklists</span>
        </div>
      </div> -->
      <div class="col-10">
        <div class="d-flex flex-row flex-wrap">
          <div *ngFor="let item of formsAndReviewsFiltered">
            <span
              [matTooltip]="addMoreInfo(item.documents)"
              matTooltipClass="tooltip-blue"
              (click)="navigateToForm(item)"
              class="item"
              [ngClass]="{
                'item-dimmed': !item.documents?.length,
                'orange-background': item.order == 999
              }"
            >
              {{ item.code }}
              <span class="number"> {{ item.documents?.length }}</span>
            </span>
          </div>
        </div>
        <div class="d-flex flex-row flex-wrap mt-2">
          <div *ngFor="let item of checklistsFiltered">
            <span
              [matTooltip]="addMoreInfo(item.documents)"
              matTooltipClass="tooltip-blue"
              (click)="navigateToForm(item)"
              class="item"
              [ngClass]="{
              'item-dimmed': !item.documents?.length,
              'green-background':
                item.documents?.length &&
                (item.order ?? 0) > 4 &&
                (item.order ?? 0) < 999,
              }"
            >
              {{ item.code }}
              <span class="number"> {{ item.documents?.length }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
</div>
