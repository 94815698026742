<div class="card">
  <div class="card-header">
    <span class="card-title"> {{ inputData.dialogTitle }}</span>
    <button mat-mini-fab class="closex" color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="card-body">
    <form [formGroup]="guestGroup">
      <div class="row mb-2">
        <div class="col-8">
          <mat-checkbox
            formControlName="guestCtrl"
            (change)="addGuestChange($event)"
            >Add guest member</mat-checkbox
          >
        </div>
        <div class="col-4 clear-btn">
          <button
            mat-flat-button
            color="accent"
            (click)="clear()"
            *ngIf="!inputData.reviewMember.userID"
          >
            Clear
          </button>
        </div>
      </div>
    </form>
    <form [formGroup]="reviewMemberGroup" *ngIf="!addGuest">
      <div class="row">
        <div class="col">
          <mat-form-field>
            <mat-label>Member</mat-label>
            <input
              #input
              type="text"
              placeholder="Select User"
              matInput
              formControlName="reviewUserCtrl"
              [matAutocomplete]="auto"
              (input)="filterUserList()"
              (focus)="filterUserList()"
            />
            <mat-autocomplete
              requireSelection
              #auto="matAutocomplete"
              [displayWith]="displayUserName"
              (optionSelected)="onUserSelected($event.option.value)"
            >
              <mat-option
                *ngFor="let user of filteredUserOptions; trackBy: trackUserByFn"
                [value]="user.id"
                [disabled]="user.isSelected"
                >{{ user.name }}</mat-option
              >
            </mat-autocomplete>
            <mat-error *ngIf="reviewUserCtrl?.invalid">{{
              getErrorMsg(reviewUserCtrl)
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field>
            <mat-label>Role</mat-label>
            <mat-select
              (selectionChange)="getUsersByRoleId($event.value)"
              formControlName="reviewRoleCtrl"
            >
              <mat-option *ngFor="let role of filteredRoles" [value]="role.id">
                {{ role.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field>
            <mat-label>Category</mat-label>
            <input matInput type="text" formControlName="reviewCategoryCtrl" />
          </mat-form-field>
        </div>
      </div>
    </form>

    <form [formGroup]="guestMemberGroup" *ngIf="addGuest">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Member name</mat-label>
            <input matInput type="text" formControlName="guestMemberNameCtrl" />
            <mat-error>{{ getErrorMsg(guestMemberNameCtrl) }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Category</mat-label>
            <input
              matInput
              type="text"
              formControlName="guestMemberCategoryCtrl"
              [value]="guestCategory"
            />
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer">
    <div mat-dialog-actions align="center">
      <button mat-flat-button class="cancel-btn" color="warn" (click)="close()">
        Cancel
      </button>
      <button
        *ngIf="!inputData.reviewMember.userID"
        [disabled]="
          (reviewMemberGroup.invalid && guestMemberGroup.invalid) ||
          (!(reviewUserCtrl?.value > 0) && !guestMemberGroup.touched)
        "
        mat-flat-button
        class="accept-btn"
        color="primary"
        (click)="add()"
      >
        Add
      </button>
      <button
        *ngIf="inputData.reviewMember.userID"
        [disabled]="reviewMemberGroup.invalid || !this.reviewRoleCtrl?.touched"
        mat-flat-button
        class="accept-btn"
        color="primary"
        (click)="add()"
      >
        Save
      </button>
    </div>
  </div>
</div>
