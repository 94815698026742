import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Injector, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { Review, ReviewAttendanceCategory, ReviewAttendanceRoster } from 'src/app/components/reviews/reviews.models';
import { ReviewsService } from 'src/app/components/reviews/reviews.service';
import { ReviewAttendanceCategoriesRefresh } from 'src/app/components/reviews/store/review-attendance-category/review-attendance-category.action';
import { CanDeactivateResponse } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog-response';
import { PendingChangesDialogComponent } from 'src/app/controls/pending-changes-dialog/pending-changes-dialog.component';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';

@Component({
  selector: 'app-review-members',
  templateUrl: './review-members.component.html',
  styleUrls: ['./review-members.component.scss']
})
export class ReviewMembersComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('input') input: ElementRef<HTMLInputElement> | undefined;

  public roles$!: Observable<Role[]>;

  public roleSubscription!: Subscription;

  public roles!: Role[];

  public reviewMemberGroup!: FormGroup;
  public get reviewRoleCtrl(): AbstractControl | null { return this.reviewMemberGroup.get('reviewRoleCtrl'); }
  public get reviewUserCtrl(): AbstractControl | null { return this.reviewMemberGroup.get('reviewUserCtrl'); }
  public get reviewCategoryCtrl(): AbstractControl | null { return this.reviewMemberGroup.get('reviewCategoryCtrl'); }

  public guestGroup!: FormGroup;
  public get guestCtrl(): AbstractControl | null { return this.guestGroup.get('guestCtrl'); }

  public guestMemberGroup!: FormGroup;
  public get guestMemberNameCtrl(): AbstractControl | null { return this.guestMemberGroup.get('guestMemberNameCtrl'); }
  public get guestMemberCategoryCtrl(): AbstractControl | null { return this.guestMemberGroup.get('guestMemberCategoryCtrl'); }

  addGuest: boolean = false;
  guestName!: string;
  guestCategory: string = 'Guest';
  reviewCategory: string = 'Attendance'
  isLoading!: boolean;
  filteredUsers?: User[];
  filteredUserOptions?: User[];
  filteredRoles?: Role[];

  constructor(
    protected override injector: Injector,
    public dialogRef: MatDialogRef<ReviewMembersComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: {
      review: Review,
      reviewMember: ReviewAttendanceRoster,
      reviewCategories: ReviewAttendanceCategory[],
      dialogTitle: string
    },
    private reviewsService: ReviewsService,
  ) {
    super(injector);

  }

  ngOnInit() {
    this.getRoles();
    this.inputData.reviewMember.reviewID = this.inputData.review.id;
    this.initializeForm();
    this.getFilteredUsers();
    this.getValues();
  }

  override ngOnDestroy(): void {
    this.roleSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  trackUserByFn(index: number, user: any): any {
    return user.id;
  }

  getValues() {
    this.guestGroup.get('guestCtrl')?.setValue(false);
    //Load saved values if editing
    if (this.inputData.reviewMember.id) {
      this.reviewMemberGroup.get('reviewUserCtrl')?.disable();
      this.guestGroup.get('guestCtrl')?.disable();

      this.filteredUsers = this.users;
      this.filteredRoles = [];
      this.users.find(u => u.id === this.inputData.reviewMember.userID)?.userRole?.map(ur => {
        if (ur.role)
          this.filteredRoles?.push(ur.role);
      });

      this.reviewMemberGroup.get('reviewUserCtrl')?.setValue(this.inputData.reviewMember.userID);
      this.reviewMemberGroup.get('reviewRoleCtrl')?.setValue(this.inputData.reviewMember.roleID);
      this.reviewMemberGroup.get('reviewCategoryCtrl')?.setValue(this.inputData.reviewMember.category?.name);
    }
    //Set default category if creating new
    else {
      this.reviewMemberGroup.get('reviewCategoryCtrl')?.setValue('Attendance');
    }
  }

  filterUserList() {
    //Filter user options based on input as long as the user hasn't been selected yet
    const userCtrlValue = this.reviewMemberGroup.get('reviewUserCtrl')?.value;
    const roleCtrlValue = this.reviewMemberGroup.get('reviewRoleCtrl')?.value;
    const userValueIsEmpty = Object.keys(userCtrlValue).length !== 0;
    const roleValueIsEmpty = Object.keys(roleCtrlValue).length !== 0;
    if (userValueIsEmpty || (!userValueIsEmpty && this.input?.nativeElement.value != this.users.find(u => u.id === userCtrlValue)?.name)) {
      const filterValue = this.input?.nativeElement.value.toLowerCase() ?? '';
      this.filteredUserOptions = this.filteredUsers?.filter(u => u.name?.toLowerCase().includes(filterValue));
    }
    //If a user and role has been selected, show all users with that role
    else if (!userValueIsEmpty && !roleValueIsEmpty) {
      this.filteredUserOptions = this.filteredUsers;
    }
  }

  displayUserName = (userId: number) => {
    const user = this.filteredUsers?.find(u => u.id === userId);
    return user?.name ?? '';
  }

  onUserSelected(userId: number): void {
    this.reviewMemberGroup.get('reviewUserCtrl')?.setValue(userId);
    this.input!.nativeElement.value = "";
    this.getRolesByUserId(userId);
  }

  add() {
    //If form is valid and not a guest:
    if (this.reviewMemberGroup.valid && !this.addGuest) {
      //If editing an existing user:
      if (this.inputData.reviewMember.id) {
        const updatedAttendanceRoster: ReviewAttendanceRoster = {
          id: this.inputData.reviewMember.id,
          userID: this.reviewMemberGroup.get('reviewUserCtrl')?.value,
          roleID: this.reviewMemberGroup.get('reviewRoleCtrl')?.value > 0 ? this.reviewMemberGroup.get('reviewRoleCtrl')?.value : -1,
          attended: this.inputData.reviewMember.attended,
          categoryID: this.inputData.reviewCategories.find(category => category.name === this.reviewMemberGroup.get('reviewCategoryCtrl')?.value)?.id ?? -2,
          reviewID: this.inputData.reviewMember.reviewID
        }
        this.reviewsService.updateReviewAttendanceRoster(updatedAttendanceRoster).toPromise().then(() => {
          this.alert.message('ReviewMember_Updated');
          this.store.dispatch(new ReviewAttendanceCategoriesRefresh());
          this.dialogRef.close(this.inputData.reviewMember);
        }).catch((error: HttpErrorResponse) => {
          if (error.status === 409) {
            const member = this.users.find(x => x.id === this.inputData.reviewMember.userID);
            const role = this.roles.find(x => x.id === this.inputData.reviewMember.roleID);
            const category = this.inputData.reviewCategories.find(x => x.id === this.inputData.reviewMember.categoryID);
            this.alert.message(error.error, [new MessagePlaceholder('{reviewMember}', member?.name), new MessagePlaceholder('{reviewRole}', role?.name), new MessagePlaceholder('{reviewMemberCategory}', category?.name)]);
          } else {
            this.alert.message('genericError');
            this.dialogRef.close(this.inputData.reviewMember);
          }
        });
      }
      //If adding a new user:
      else {
        const newAttendanceRoster: ReviewAttendanceRoster = {
          id: 0,
          userID: this.reviewMemberGroup.get('reviewUserCtrl')?.value,
          //If no role is defined set the role to guest
          roleID: this.reviewMemberGroup.get('reviewRoleCtrl')?.value > 0 ? this.reviewMemberGroup.get('reviewRoleCtrl')?.value : -1,
          attended: true,
          categoryID: -2,
          reviewID: this.inputData.reviewMember.reviewID
        }
        this.reviewsService.createReviewAttendanceRoster(newAttendanceRoster).toPromise().then(() => {
          this.alert.message('ReviewMember_Added');
          this.store.dispatch(new ReviewAttendanceCategoriesRefresh());
          this.dialogRef.close(this.inputData.reviewMember);
        }).catch((error: HttpErrorResponse) => {
          if (error.status === 409) {
            const member = this.users.find(x => x.id === this.inputData.reviewMember.userID);
            const role = this.roles.find(x => x.id === this.inputData.reviewMember.roleID);
            const category = this.inputData.reviewCategories.find(x => x.id === this.inputData.reviewMember.categoryID);
            this.alert.message(error.error, [new MessagePlaceholder('{reviewMember}', member?.name), new MessagePlaceholder('{reviewRole}', role?.name), new MessagePlaceholder('{reviewMemberCategory}', category?.name)]);
          } else {
            this.alert.message('genericError');
            this.dialogRef.close(this.inputData.reviewMember);
          }
        });
      }
    }
    //If form is valid and guest is selected
    else if (this.guestMemberGroup.valid && this.addGuest) {
      //If editing an existing guest (Not in use at the moment)
      if (this.inputData.reviewMember.id) {
        const updatedGuestRoster: ReviewAttendanceRoster = {
          id: this.inputData.reviewMember.id,
          userID: this.inputData.reviewMember.userID,
          name: this.inputData.reviewMember.name,
          attended: true,
          roleID: this.inputData.reviewMember.roleID,
          reviewID: this.inputData.reviewMember.reviewID,
          categoryID: this.inputData.reviewMember.categoryID
        }
        this.reviewsService.updateReviewAttendanceRoster(updatedGuestRoster).toPromise().then((data) => {
          this.alert.message('ReviewMember_Updated');
          this.store.dispatch(new ReviewAttendanceCategoriesRefresh());
          this.dialogRef.close(data);
        });
      }
      //If adding a new guest
      else {
        const newGuestRoster: ReviewAttendanceRoster = {
          id: 0,
          userID: 0,
          name: this.utils.nameToCamelCase(this.guestMemberGroup.get('guestMemberNameCtrl')?.value ?? ''),
          attended: true,
          roleID: -1,
          reviewID: this.inputData.review.id,
          categoryID: -1
        }
        this.reviewsService.createGuestAttendanceRoster(newGuestRoster).toPromise().then((data) => {
          this.alert.message('ReviewMember_Added');
          this.store.dispatch(new ReviewAttendanceCategoriesRefresh());
          this.dialogRef.close(data);
        });
      }
    }
  }

  addGuestChange(e: any) {
    this.addGuest = e.checked;
    this.guestGroup.get('guestCtrl')?.setValue(this.addGuest);
    this.clear();
  }

  close() {
    if (this.reviewMemberGroup.dirty) {
      const confirmation = this.dialog.open(PendingChangesDialogComponent, {
        height: 'fit-content',
        width: '40%',
        data: {}
      });
      confirmation.afterClosed().toPromise().then((response: CanDeactivateResponse) => {
        if (response === CanDeactivateResponse.Discard) {
          this.dialogRef.close(null);
        }
      });
    } else {
      this.dialogRef.close(null);
    }
  }

  getRoles() {
    this.roles$ = this.store.select(state => state.Roles.data);
    this.roleSubscription = this.roles$.subscribe(roles => {
      this.roles = this.utils.cloneDeep(roles);
      this.filteredRoles = this.roles;
    });
  }

  getUsersByRoleId(roleId: number) {
    //Todo -- there is a problem here: if you select a role, then a user, the role list should be filtered based on the selected user but instead it is the full role list
    //Filter users if role is selected first or if editing and the role is changed
    if (!this.inputData.reviewMember.userID || this.input?.nativeElement.value) {
      this.users = [];
      // this.getRoles();
      this.roles.find(role => role.id === roleId)?.userRole?.map(userRole => {
        if (userRole.user)
          this.users.push(userRole.user);
      });
      this.getFilteredUsers();
    }
    else if (this.inputData.reviewMember.roleID && this.inputData.reviewMember.roleID != roleId) {
      this.users = [];
      this.roles.find(role => role.id === roleId)?.userRole?.map(userRole => {
        if (userRole.user)
          this.users.push(userRole.user);
      });
      this.getFilteredUsers();
    }
  }

  getRolesByUserId(userId: number) {
    //Filter roles if user is selected first
    if (!this.inputData.reviewMember.roleID) {
      this.filteredRoles = [];
      this.loadUsers();
      this.users.find(u => u.id === userId)?.userRole?.map(ur => {
        if (ur.role)
          this.filteredRoles?.push(ur.role);
      });
    }
  }

  getFilteredUsers() {
    //Remove inactive users
    //If a user is already selected and that user's role is being edited, don't remove that user from the list
    //If a user has been added already, mark that user as selected (disable it)
    this.filteredUsers = this.users.map(user => ({
      ...user,
      isSelected: this.inputData.review.reviewAttendanceRosters.some(
        r => r.userID === user.id && r.userID !== this.inputData.reviewMember.userID
      )
    })).filter(user => user.status === 1);
    this.filteredUserOptions = this.filteredUsers;
  }

  clear() {
    //Clear is only available when creating new, not when editing
    this.reviewMemberGroup.reset();
    this.guestMemberGroup.reset();

    //If creating new user
    if (!this.addGuest) {
      this.reviewMemberGroup.get('reviewCategoryCtrl')?.setValue('Attendance');
      this.loadUsers();
      this.getFilteredUsers();
      this.getRoles();
    }
    //If creating new guest
    else {
      this.guestMemberGroup.get('guestMemberCategoryCtrl')?.setValue('Guest');
    }
  }

  initializeForm() {
    //Initialize Groups
    this.reviewMemberGroup = this.formBuilder.group({
      reviewUserCtrl: [{ value: '' }, Validators.required],
      reviewRoleCtrl: [{ value: '' }],
      reviewCategoryCtrl: [{ value: 'Attendance', disabled: true }, Validators.required],
    });

    this.guestGroup = this.formBuilder.group({
      guestCtrl: [{ value: this.addGuest }]
    });

    this.guestMemberGroup = this.formBuilder.group({
      guestMemberNameCtrl: [{ value: '' }, Validators.required],
      guestMemberCategoryCtrl: [{ value: 'Guest', disabled: true }, Validators.required]
    });
  }

}
