import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit, Injector } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Review, ReviewActionItem, ReviewActionItemType, ReviewAttendanceCategory, ReviewAttendanceRoster, ReviewBoard, ReviewBoardType, ReviewCycle, ReviewDocumentCategory, ReviewDocuments, ReviewPermission, ReviewSatisfactionCheck, ReviewStatus, ReviewStatusAction, ReviewStatusPermission, ReviewType, SaveReview, ReviewAttendanceSave, ReviewExtension, ReviewAttendanceSaveSave } from './reviews.models';
import { Store } from '@ngxs/store';
import { Roles } from 'src/app/common/enumerations/enumerations';
import { utils } from 'src/app/modules/libs/utils';
import { PrivilegeEnum } from 'src/app/services/role-privilege/privilege-enum';
import { RolePrivilege } from 'src/app/components/catalogs/roles/services/role-privilege';
import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { AppStateService } from 'src/app/store/app-state-service';
import { BaseService } from 'src/app/common/base/base.service';
import { Document } from 'src/app/services/documents/documents';
import { initial } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService extends BaseService<Review, number> {

  ApiBoardTypes = '/ReviewBoardTypes';
  ApiBoards = '/ReviewBoards';
  ApiReviews = '/Reviews';
  ApiReviewTypes = '/ReviewTypes';
  ApiReviewStatuses = '/ReviewStatuses';
  ApiReviewStatusActions = '/ReviewStatusActions';
  ApiReviewCycles = environment.urls.baseApiUrl + '/ReviewCycles/';
  ApiReviewAttendanceCategories = environment.urls.baseApiUrl + '/ReviewAttendanceCategories/';
  ApiReviewAttendanceRosters = environment.urls.baseApiUrl + '/ReviewAttendanceRosters/';
  ApiReviewActionItemTypes = '/ReviewActionItemTypes';
  ApiReviewActionItems = '/ReviewActionItems';
  ApiReviewSatisfactionChecks = '/ReviewSatisfactionChecks';
  ApiReviewPermissions = '/ReviewPermissions';
  ApiReviewStatusPermissions = '/ReviewStatusPermissions';
  ApiReviewExtensions = '/ReviewExtensions';
  ApiReviewDocumentCategory = '/ReviewDocumentCategory';
  ApiReviewDocuments = '/ReviewDocuments';
  ApiReviewAttendanceSave = environment.urls.baseApiUrl + '/ReviewAttendanceSave/';


  reviewPermissions!: ReviewPermission[];
  reviewPermissions$!: Observable<ReviewPermission[]>;
  reviewPermissionsSubs!: Subscription;

  rolePrivileges!: RolePrivilege[];
  rolePrivileges$!: Observable<RolePrivilege[]>;
  rolePrivilegesSubs!: Subscription;

  appStateSubs!: Subscription;
  loading!: boolean;
  index!: boolean;

  constructor(protected override injector: Injector) {
    super(injector, 'reviews')
  }

  Init() {
    if (!this.reviewPermissions && !this.rolePrivileges) {
      this.reviewPermissions$ = this.store.select(state => state.ReviewPermissions.data);
      this.reviewPermissionsSubs = this.reviewPermissions$.subscribe(data => {
        this.reviewPermissions = data ?? [];
      });
      this.rolePrivileges$ = this.store.select(state => state.RolePrivileges.data);
      this.rolePrivilegesSubs = this.rolePrivileges$.subscribe(data => {
        this.rolePrivileges = data ?? [];
      });
    }
  }


  //#region ReviewBoardTypes
  getReviewBoardTypes(): Observable<ReviewBoardType[]> {
    return this.http.get<ReviewBoardType[]>(this.BASE_URL + this.ApiBoardTypes);
  }

  getReviewBoardTypeById(id: number): Observable<ReviewBoardType> {
    return this.http.get<ReviewBoardType>(this.BASE_URL + this.ApiBoardTypes + '/' + id);
  }

  createReviewBoardType(reviewBoardType: ReviewBoardType): Observable<ReviewBoardType> {
    return this.http.post<ReviewBoardType>(this.BASE_URL + this.ApiBoardTypes, reviewBoardType);
  }

  updateReviewBoardType(reviewBoardType: ReviewBoardType) {
    return this.http.put<ReviewBoardType>(this.BASE_URL + this.ApiBoardTypes + '/' + reviewBoardType.id, reviewBoardType);
  }

  deleteReviewBoardType(id: number) {
    return this.http.delete(this.BASE_URL + this.ApiBoardTypes + '/' + id);
  }
  //#endregion

  //#region ReviewTypes
  getReviewTypes(): Observable<ReviewType[]> {
    return this.http.get<ReviewType[]>(this.BASE_URL + this.ApiReviewTypes);
  }

  getReviewTypeById(id: number): Observable<ReviewType> {
    return this.http.get<ReviewType>(this.BASE_URL + this.ApiReviewTypes + '/' + id);
  }

  createReviewType(reviewType: ReviewType): Observable<ReviewType> {
    return this.http.post<ReviewType>(this.BASE_URL + this.ApiReviewTypes, reviewType);
  }

  updateReviewType(reviewType: ReviewType) {
    return this.http.put<ReviewType>(this.BASE_URL + this.ApiReviewTypes + '/' + reviewType.id, reviewType);
  }

  updateReviewTypeOrder(reviewType: ReviewType) {
    return this.http.put<ReviewType>(this.BASE_URL + this.ApiReviewTypes + '/Order/' + reviewType.id, reviewType);
  }

  deleteReviewType(id: number) {
    return this.http.delete(this.BASE_URL + this.ApiReviewTypes + '/' + id);
  }
  //#endregion

  //#region ReviewBoards
  getReviewBoards(): Observable<ReviewBoard[]> {
    return this.http.get<ReviewBoard[]>(this.BASE_URL + this.ApiBoards);
  }

  getReviewBoardById(id: number): Observable<ReviewBoard> {
    return this.http.get<ReviewBoard>(this.BASE_URL + this.ApiBoards + '/' + id);
  }

  createReviewBoard(reviewBoard: ReviewBoard): Observable<ReviewBoard> {
    return this.http.post<ReviewBoard>(this.BASE_URL + this.ApiBoards, reviewBoard);
  }

  updateReviewBoard(reviewBoard: ReviewBoard) {
    return this.http.put<ReviewBoard>(this.BASE_URL + this.ApiBoards + '/' + reviewBoard.id, reviewBoard);
  }

  deleteReviewBoard(id: number) {
    return this.http.delete(this.BASE_URL + this.ApiBoards + '/' + id);
  }
  //#endregion

  //#region Reviews
  getReviews(): Observable<Review[]> {
    return this.http.get<Review[]>(this.BASE_URL + this.ApiReviews);
  }

  getReviewById(id: number): Observable<Review> {
    return this.http.get<Review>(this.BASE_URL + this.ApiReviews + '/' + id);
  }

  createReview(review: SaveReview): Observable<Review> {
    return this.http.post<Review>(this.BASE_URL + this.ApiReviews, review);
  }

  updateReview(review: SaveReview) {
    return this.http.put<Review>(this.BASE_URL + this.ApiReviews + '/' + review.id, review);
  }

  deleteReview(id: number) {
    return this.http.delete(this.BASE_URL + this.ApiReviews + '/' + id);
  }
  //#endregion

  //#region ReviewStatuses
  getReviewStatuses(): Observable<ReviewStatus[]> {
    return this.http.get<ReviewStatus[]>(this.BASE_URL + this.ApiReviewStatuses);
  }

  getReviewStatusById(id: number): Observable<ReviewStatus> {
    return this.http.get<ReviewStatus>(this.BASE_URL + this.ApiReviewStatuses + '/' + id);
  }

  createReviewStatus(reviewstatus: ReviewStatus): Observable<ReviewStatus> {
    return this.http.post<ReviewStatus>(this.BASE_URL + this.ApiReviewStatuses, reviewstatus);
  }

  updateReviewStatus(reviewstatus: ReviewStatus) {
    return this.http.put<ReviewStatus>(this.BASE_URL + this.ApiReviewStatuses + '/' + reviewstatus.id, reviewstatus);
  }

  deleteReviewStatus(id: number) {
    return this.http.delete(this.BASE_URL + this.ApiReviewStatuses + '/' + id);
  }

  updateReviewStatusOrder(reviewStatus: ReviewStatus) {
    return this.http.put<ReviewStatus>(this.BASE_URL + this.ApiReviewStatuses + '/Order/' + reviewStatus.id, reviewStatus);
  }
  //#endregion

  //#region ReviewStatusActions
  getReviewStatusActions(): Observable<ReviewStatusAction[]> {
    return this.http.get<ReviewStatusAction[]>(this.BASE_URL + this.ApiReviewStatusActions);
  }

  getReviewStatusActionById(id: number): Observable<ReviewStatusAction> {
    return this.http.get<ReviewStatusAction>(this.BASE_URL + this.ApiReviewStatusActions + '/' + id);
  }

  createReviewStatusAction(reviewStatusAction: ReviewStatusAction): Observable<ReviewStatusAction> {
    return this.http.post<ReviewStatusAction>(this.BASE_URL + this.ApiReviewStatusActions, reviewStatusAction);
  }

  updateReviewStatusAction(reviewStatusAction: ReviewStatusAction) {
    return this.http.put<ReviewStatusAction>(this.BASE_URL + this.ApiReviewStatusActions + '/' + reviewStatusAction.id, reviewStatusAction);
  }

  deleteReviewStatusAction(id: number) {
    return this.http.delete(this.BASE_URL + this.ApiReviewStatusActions + '/' + id);
  }
  //#endregion

  //#region ReviewCycle
  getReviewCycles(): Observable<ReviewCycle[]> {
    return this.http.get<ReviewCycle[]>(this.ApiReviewCycles);
  }

  getReviewCycleById(id: number): Observable<ReviewCycle> {
    return this.http.get<ReviewCycle>(this.ApiReviewCycles + id);
  }

  createReviewCycle(reviewCycle: ReviewCycle): Observable<ReviewCycle> {
    return this.http.post<ReviewCycle>(this.ApiReviewCycles, reviewCycle);
  }

  sendReviewCycleEmail(reviewCycle: ReviewCycle) {
    const isTest = document.location.host !== 'ascc.als.lbl.gov';
    return this.http.post(this.ApiReviewCycles + 'Email/' + isTest, reviewCycle);
  }

  updateReviewCycle(reviewCycle: ReviewCycle): Observable<ReviewCycle> {
    return this.http.put<ReviewCycle>(this.ApiReviewCycles + reviewCycle.id, reviewCycle);
  }

  deleteReviewCycle(id: number) {
    return this.http.delete(this.ApiReviewCycles + id);
  }
  //#endregion

  //#region ReviewAttendanceRoster
  getReviewAttendanceRosters(): Observable<ReviewAttendanceRoster[]> {
    return this.http.get<ReviewAttendanceRoster[]>(this.ApiReviewAttendanceRosters);
  }

  getReviewAttendanceRosterById(id: number): Observable<ReviewAttendanceRoster> {
    return this.http.get<ReviewAttendanceRoster>(this.ApiReviewAttendanceRosters + id);
  }

  createReviewAttendanceRoster(reviewAttendanceRoster: ReviewAttendanceRoster): Observable<ReviewAttendanceRoster> {
    return this.http.post<ReviewAttendanceRoster>(this.ApiReviewAttendanceRosters, reviewAttendanceRoster);
  }

  createGuestAttendanceRoster(reviewAttendanceRoster: ReviewAttendanceRoster): Observable<ReviewAttendanceRoster> {
    return this.http.post<ReviewAttendanceRoster>(this.ApiReviewAttendanceRosters + 'Guest', reviewAttendanceRoster);
  }

  // updateGuestAttendanceRoster(reviewAttendanceRoster: ReviewAttendanceRoster): Observable<ReviewAttendanceRoster> {
  //   return this.http.post<ReviewAttendanceRoster>(this.ApiReviewAttendanceRosters + 'UpdateGuest', reviewAttendanceRoster);
  // }

  updateReviewAttendanceRoster(reviewAttendanceRoster: ReviewAttendanceRoster): Observable<ReviewAttendanceRoster> {
    return this.http.put<ReviewAttendanceRoster>(this.ApiReviewAttendanceRosters + reviewAttendanceRoster.id, reviewAttendanceRoster);
  }

  deleteReviewAttendanceRoster(id: number) {
    return this.http.delete(this.ApiReviewAttendanceRosters + id);
  }

  saveReviewAttendanceRoster(reviewAttendanceSave: ReviewAttendanceSave) {
    return this.http.post<ReviewAttendanceSave>(this.ApiReviewAttendanceRosters + 'SaveList', reviewAttendanceSave);
  }
  //#endregion

  //#region ReviewAttendanceSave
  getReviewAttendanceSaved(): Observable<ReviewAttendanceSave[]> {
    return this.http.get<ReviewAttendanceSave[]>(this.ApiReviewAttendanceSave);
  }

  getReviewAttendanceSavedById(id: number): Observable<ReviewAttendanceSave> {
    return this.http.get<ReviewAttendanceSave>(this.ApiReviewAttendanceSave + id);
  }

  createReviewAttendanceSaved(reviewAttendanceSave: ReviewAttendanceSave): Observable<ReviewAttendanceSave> {
    return this.http.post<ReviewAttendanceSave>(this.ApiReviewAttendanceSave, reviewAttendanceSave);
  }

  updateReviewAttendanceSaved(reviewAttendanceSave: ReviewAttendanceSaveSave): Observable<ReviewAttendanceSave> {
    return this.http.put<ReviewAttendanceSave>(this.ApiReviewAttendanceSave + reviewAttendanceSave.id, reviewAttendanceSave);
  }

  deleteReviewAttendanceSaved(id: number) {
    return this.http.delete(this.ApiReviewAttendanceSave + id);
  }
  //#endregion

  //#region ReviewAttendanceCategory
  getReviewAttendanceCategories(): Observable<ReviewAttendanceCategory[]> {
    return this.http.get<ReviewAttendanceCategory[]>(this.ApiReviewAttendanceCategories);
  }

  getReviewAttendanceCategoryById(id: number): Observable<ReviewAttendanceCategory> {
    return this.http.get<ReviewAttendanceCategory>(this.ApiReviewAttendanceCategories + id);
  }

  createReviewAttendanceCategory(reviewAttendanceSave: ReviewAttendanceCategory): Observable<ReviewAttendanceCategory> {
    return this.http.post<ReviewAttendanceCategory>(this.ApiReviewAttendanceCategories, reviewAttendanceSave);
  }

  updateReviewAttendanceCategory(reviewAttendanceSave: ReviewAttendanceCategory): Observable<ReviewAttendanceCategory> {
    return this.http.put<ReviewAttendanceCategory>(this.ApiReviewAttendanceCategories + reviewAttendanceSave.id, reviewAttendanceSave);
  }

  deleteReviewAttendanceCategory(id: number) {
    return this.http.delete(this.ApiReviewAttendanceCategories + id);
  }
  //#endregion

  //#region ReviewActionItemTypes
  getReviewActionItemTypes(): Observable<ReviewActionItemType[]> {
    return this.http.get<ReviewActionItemType[]>(this.BASE_URL + this.ApiReviewActionItemTypes);
  }

  getReviewActionItemTypeById(id: number): Observable<ReviewActionItemType> {
    return this.http.get<ReviewActionItemType>(this.BASE_URL + this.ApiReviewActionItemTypes + '/' + id);
  }

  createReviewActionItemType(reviewActionItemType: ReviewActionItemType): Observable<ReviewActionItemType> {
    return this.http.post<ReviewActionItemType>(this.BASE_URL + this.ApiReviewActionItemTypes, reviewActionItemType);
  }

  updateReviewActionItemType(reviewActionItemType: ReviewActionItemType) {
    return this.http.put<ReviewActionItemType>(this.BASE_URL + this.ApiReviewActionItemTypes + '/' + reviewActionItemType.id, reviewActionItemType);
  }

  deleteReviewActionItemType(id: number) {
    return this.http.delete(this.BASE_URL + this.ApiReviewActionItemTypes + '/' + id);
  }
  //#endregion

  //#region ReviewActionItems
  getReviewActionItems(): Observable<ReviewActionItem[]> {
    return this.http.get<ReviewActionItem[]>(this.BASE_URL + this.ApiReviewActionItems);
  }

  getReviewActionItemById(id: number): Observable<ReviewActionItem> {
    return this.http.get<ReviewActionItem>(this.BASE_URL + this.ApiReviewActionItems + '/' + id);
  }

  createReviewActionItem(reviewActionItem: ReviewActionItem): Observable<ReviewActionItem> {
    return this.http.post<ReviewActionItem>(this.BASE_URL + this.ApiReviewActionItems, reviewActionItem);
  }

  updateReviewActionItem(reviewActionItem: ReviewActionItem) {
    return this.http.put<ReviewActionItem>(this.BASE_URL + this.ApiReviewActionItems + '/' + reviewActionItem.id, reviewActionItem);
  }

  deleteReviewActionItem(id: number) {
    return this.http.delete(this.BASE_URL + this.ApiReviewActionItems + '/' + id);
  }

  sendemailNotification(reviewActionItem: ReviewActionItem) {
    return this.http.patch(this.BASE_URL + this.ApiReviewActionItems + '/sendEmail/', reviewActionItem);
  }
  //#endregion

  //#region ReviewSatisfactionChecks
  getReviewSatisfactionChecks(): Observable<ReviewSatisfactionCheck[]> {
    return this.http.get<ReviewSatisfactionCheck[]>(this.BASE_URL + this.ApiReviewSatisfactionChecks);
  }

  getReviewSatisfactionCheckById(id: number): Observable<ReviewSatisfactionCheck> {
    return this.http.get<ReviewSatisfactionCheck>(this.BASE_URL + this.ApiReviewSatisfactionChecks + '/' + id);
  }

  createReviewSatisfactionCheck(reviewActionItemType: ReviewSatisfactionCheck): Observable<ReviewSatisfactionCheck> {
    return this.http.post<ReviewSatisfactionCheck>(this.BASE_URL + this.ApiReviewSatisfactionChecks, reviewActionItemType);
  }

  updateReviewSatisfactionCheck(reviewActionItemType: ReviewSatisfactionCheck) {
    return this.http.put<ReviewSatisfactionCheck>(this.BASE_URL + this.ApiReviewSatisfactionChecks + '/' + reviewActionItemType.id, reviewActionItemType);
  }

  deleteReviewSatisfactionCheck(id: number) {
    return this.http.delete(this.BASE_URL + this.ApiReviewSatisfactionChecks + '/' + id);
  }
  //#endregion

  //#region ReviewPermissions
  getReviewPermissions(): Observable<ReviewPermission[]> {
    return this.http.get<ReviewPermission[]>(this.BASE_URL + this.ApiReviewPermissions);
  }

  getReviewPermissionById(id: number): Observable<ReviewPermission> {
    return this.http.get<ReviewPermission>(this.BASE_URL + this.ApiReviewPermissions + '/' + id);
  }

  createReviewPermission(reviewPermission: ReviewPermission): Observable<ReviewPermission> {
    return this.http.post<ReviewPermission>(this.BASE_URL + this.ApiReviewPermissions, reviewPermission);
  }

  updateReviewPermission(reviewPermission: ReviewPermission) {
    return this.http.put<ReviewPermission>(this.BASE_URL + this.ApiReviewPermissions + '/' + reviewPermission.id, reviewPermission);
  }

  deleteReviewPermission(id: number) {
    return this.http.delete(this.BASE_URL + this.ApiReviewPermissions + '/' + id);
  }
  //#endregion

  //#region ReviewStatusPermissions
  getReviewStatusPermissions(): Observable<ReviewStatusPermission[]> {
    return this.http.get<ReviewStatusPermission[]>(this.BASE_URL + this.ApiReviewStatusPermissions);
  }

  getReviewStatusPermissionById(id: number): Observable<ReviewStatusPermission> {
    return this.http.get<ReviewStatusPermission>(this.BASE_URL + this.ApiReviewStatusPermissions + '/' + id);
  }

  createReviewStatusPermission(reviewActionItemType: ReviewStatusPermission): Observable<ReviewStatusPermission> {
    return this.http.post<ReviewStatusPermission>(this.BASE_URL + this.ApiReviewStatusPermissions, reviewActionItemType);
  }

  updateReviewStatusPermission(reviewActionItemType: ReviewStatusPermission) {
    return this.http.put<ReviewStatusPermission>(this.BASE_URL + this.ApiReviewStatusPermissions + '/' + reviewActionItemType.id, reviewActionItemType);
  }

  deleteReviewStatusPermission(id: number) {
    return this.http.delete(this.BASE_URL + this.ApiReviewStatusPermissions + '/' + id);
  }
  //#endregion

  //#region ReviewExtensions
  getReviewExtensions(): Observable<ReviewExtension[]> {
    return this.http.get<ReviewExtension[]>(this.BASE_URL + this.ApiReviewExtensions);
  }

  getReviewExtensionById(id: number): Observable<ReviewExtension> {
    return this.http.get<ReviewExtension>(this.BASE_URL + this.ApiReviewExtensions + '/' + id);
  }

  createReviewExtension(reviewExtension: ReviewExtension): Observable<ReviewExtension> {
    return this.http.post<ReviewExtension>(this.BASE_URL + this.ApiReviewExtensions, reviewExtension);
  }

  updateReviewExtension(reviewExtension: ReviewExtension) {
    return this.http.put<ReviewExtension>(this.BASE_URL + this.ApiReviewExtensions + '/' + reviewExtension.id, reviewExtension);
  }

  deleteReviewExtension(id: number) {
    return this.http.delete(this.BASE_URL + this.ApiReviewExtensions + '/' + id);
  }
  //#endregion

  //#region.ReviewDocumentCategory
  getReviewDocumentCategories(): Observable<ReviewDocumentCategory[]> {
    return this.http.get<ReviewDocumentCategory[]>(this.BASE_URL + this.ApiReviewDocumentCategory);
  }

  createReviewDocumentCategory(reviewDocumentCategory: ReviewDocumentCategory): Observable<ReviewDocumentCategory> {
    return this.http.post<ReviewDocumentCategory>(this.BASE_URL + this.ApiReviewDocumentCategory, reviewDocumentCategory);
  }

  updateReviewDocumentCategory(reviewDocumentCategory: ReviewDocumentCategory) {
    return this.http.put<ReviewDocumentCategory>(this.BASE_URL + this.ApiReviewDocumentCategory + '/' + reviewDocumentCategory.id, reviewDocumentCategory);
  }

  deleteReviewDocumentCategory(reviewCategoryId: number) {
    return this.http.delete(this.BASE_URL + this.ApiReviewDocumentCategory + '/' + reviewCategoryId);
  }
  //#endregion

  //#region.ReviewDocuments
  getReviewDocuments(): Observable<ReviewDocuments[]> {
    return this.http.get<ReviewDocuments[]>(this.BASE_URL + this.ApiReviewDocuments);
  }

  getReviewDocumentsByCategory(categoryId: number): Observable<ReviewDocuments[]> {
    return this.http.get<ReviewDocuments[]>(this.BASE_URL + this.ApiReviewDocuments + '/' + categoryId);
  }

  getReviewDocumentsByReview(idReview: number): Observable<ReviewDocuments[]> {
    return this.http.get<ReviewDocuments[]>(this.BASE_URL + this.ApiReviewDocuments + '/Review/' + idReview);
  }

  createReviewDocuments(reviewDocuments: ReviewDocuments[]): Observable<number> {
    return this.http.post<number>(this.BASE_URL + this.ApiReviewDocuments + '/Documents', reviewDocuments);
  }

  deleteReviewDocument(id: number) {
    return this.http.delete(this.BASE_URL + this.ApiReviewDocuments + '/' + id);
  }
  //#endregion

  //#region General Methods

  checkPrivilege(privilege: PrivilegeEnum, reviewBoardType: ReviewBoardType | null = null, reviewStatus: ReviewStatus | null = null) {
    this.Init();
    if (!this.currentUser) { return false; }
    if (this.currentUser?.userRole?.map(r => r.roleID).includes(Roles.SA) && privilege == PrivilegeEnum.ChangeSettings) {
      return true;
    }
    if (!reviewBoardType) {
      const rolePrivileges = this.rolePrivileges?.filter(x => x.privilege == privilege);
      return utils.intersect(rolePrivileges.map(r => r.roleID), this.currentUser.userRole?.map(u => u.roleID));
    }

    if (reviewBoardType && !reviewStatus) {
      const reviewPermissions = this.reviewPermissions.filter(x => x.privilege == privilege && x.reviewBoardTypeID == reviewBoardType.id);
      const rolePrivileges = reviewPermissions?.length ? reviewPermissions.map(p => p.rolePrivileges)?.reduce((acc, arr) => [...acc, ...arr]) : [];
      return utils.intersect(rolePrivileges.map(p => p.roleID), this.currentUser.userRole?.map(u => u.roleID));
    }

    if (reviewBoardType && reviewStatus) {
      const reviewPermissions = this.reviewPermissions.filter(x => x.privilege == privilege && x.reviewBoardTypeID == reviewBoardType.id && (x.reviewStatusPermissions.some(s => s.reviewStatusID == reviewStatus.id) && x.hasStatusPermissions || !x.hasStatusPermissions));
      const rolePrivileges = reviewPermissions?.length ? reviewPermissions.map(p => p.rolePrivileges)?.reduce((acc, arr) => [...acc, ...arr]) : [];
      return utils.intersect(rolePrivileges.map(p => p.roleID), this.currentUser.userRole?.map(u => u.roleID));
    }
    return false;
  }
  //#endregion
}
