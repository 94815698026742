import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WFComponent } from '../../../../components/workflow/workflow';
import { ReturnType } from 'src/app/common/enumerations/enumerations';

@Component({
  selector: 'app-cl-editor-tag-info',
  templateUrl: './cl-editor-tag-info.component.html',
  styleUrls: ['./cl-editor-tag-info.component.scss']
})
export class ClEditorTagInfoComponent implements OnInit {

  returnType: string;
  returnTypes = ReturnType;
  tag?: string;

  constructor(
    public dialog: MatDialogRef<ClEditorTagInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TagInfoData) {
    this.returnType = ReturnType[data.component.returnType ?? -1];
    this.tag = data.tag ? data.tag : '<b class="red">{Tag}</b>';
  }

  ngOnInit(): void {
  }

  close() {
    this.dialog.close();
  }

}

export interface TagInfoData {
  component: WFComponent;
  tag: string;
  multi: boolean;
}
