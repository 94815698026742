import { SafeHtml } from "@angular/platform-browser";
import { FormStatusEnum, ActionPendingCatalog, CompareOperator } from "src/app/common/enumerations/enumerations";
import { FormStatus } from "src/app/services/forms/form-status";
import { ProcedureTypeEnum } from "src/app/components/procedure/enums/procedure-type.enum";
import { Procedure } from "src/app/components/procedure/models/procedure.model";
import { Role } from "src/app/components/catalogs/roles/services/role";
import { User } from "src/app/components/catalogs/user-catalog/services/user";
import { WfTaskLocalResource, WFStepStatusLocal } from "src/app/services/work-flow/work-flow";
import { Schedule } from "../schedules/models/schedule";
import { ScheduleType } from "../schedules/models/schedule-type";
import { ScheduleSubtype } from "../schedules/models/schedule-subtype";
import { Resource, ResourceType } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import { DocumentType, RelatedDocument } from "src/app/services/documents/documents";
import { ProcedureMaster } from "../procedure/models/procedure-master.model";
import { WFTableResource } from "../workflow/workflow";
import { Review } from "../reviews/reviews.models";

export class Checklist {
  id!: number;
  name?: string;
  serialNo?: string;
  description?: string;
  status?: FormStatusEnum;
  statusName?: string;
  type?: number | null;
  code?: string;
  createdOn?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedOn?: Date;
  updatedBy?: number | null;
  updatedByName?: string;
  startedBy?: number | null;
  startedByName?: string;
  startedOn?: Date;
  closedBy?: number | null;
  closedByName?: string;
  closedOn?: Date;
  canceledBy?: number | null;
  canceledByName?: string;
  canceledOn?: Date;
  canceledComments?: string;
  scheduleId?: number;
  schedule?: Schedule | null;
  resourceID?: number | null;
  resource?: Resource;
  wfTableID?: number;
  wfTable?: WFTableResource | null;
  versionName?: string;
  typeName?: string;
  checklistTemplateID?: number;
  checklistTemplate?: ChecklistTemplate;
  documentTypeID?: number;
  documentType?: DocumentType;
  resourceName?: string;
  resourceNameSanitized?: SafeHtml;
  verifiedOK?: boolean;
  verificationList?: VerificationList;
  isOlogSent?: boolean;
  checklistStatusID?: number;
  checklistStatus?: ChecklistExecStatus;
  annotations?: Annotation[];
  locations?: string;

  review?: Review;
  tooltipCssClasses?: string[];
  relatedDocuments?: RelatedDocument[];
  loading?: boolean;
}

export class VerificationList {
  checklistHashCode!: string;
  templateHashCode!: string;
  checklistTasks: WfTaskLocalResource[] = [];
  templateTasks: WfTaskLocalResource[] = [];
  verifiedOK: boolean = false;
}

export class ChecklistTemplateStatusResource {
  status!: number;
  completed: boolean = false;
  userID!: number;
  taskCount!: number;
}

export class SaveChecklistStatusResource {
  id!: number;
  status?: number;
  reason?: string;
  checklistStatusID?: number;
  rolesToNotify?: number[];
  wfTaskLocalID?: number;
}

export class SaveChecklistLocationsResource {
  id!: number;
  locations!: string;
}

export interface TemplateType {
  id: number;
  name?: string;
  description?: string;
  status?: number;
  type?: number | null;
  order?: number;
  code?: string;
  createdOn?: Date;
  createdBy?: number;
  updatedOn?: Date | null;
  updatedBy?: number | null;
  documentTypeID?: number;
  resourceTypeID?: number | null;
  scheduleTypeID?: number | null;
  scheduleSubtypeID?: number | null;
  hideCustomSchedules?: boolean;
  samePersonCanApprove?: boolean;
  reviewInExec?: boolean;
  allowActionItems?: boolean;
  documentType?: DocumentType;
  execDocumentType?: DocumentType;
  resourceType?: ResourceType | null;
  scheduleType?: ScheduleType | null;
  scheduleSubtype?: ScheduleSubtype | null;
  templateRolePermissions?: TemplateRolePermission[];
  checklistTemplates?: ChecklistTemplate[];
  deleteReason?: string;
  actionPending?: ActionPendingCatalog;
  allSignaturesRequired?: boolean;
  allowMultipleVersions?: boolean;
}

export interface TemplateTypeDialogData {
  documentType?: DocumentType;
  templateType: TemplateType;
  isAbsiTemplateType: boolean;
}

export interface ChecklistTemplate {
  id: number;
  name?: string | null;
  description?: string;
  status?: number;
  statusName?: string;
  statusOrder?: number;
  type?: number;
  code?: string;
  createdOn?: Date;
  createdBy?: number;
  updatedOn?: Date;
  updatedBy?: number;
  updatedByName?: string;
  approvedBy?: User;
  approvedOn?: Date;
  createdByName?: string;
  serialNo?: string;
  templateTypeID?: number;
  templateType?: TemplateType;
  documentTypeID?: number;
  documentType?: DocumentType;
  resourceID?: number | null;
  resourceString?: string | null;
  wfTableID: number;
  wfTable?: WFTableResource;
  resource?: Resource;
  cssClass?: string;
  procedureMasterID?: number;
  procedureMaster?: ProcedureMaster;
  procedureID?: number;
  procedure?: Procedure;
  comments?: string | null;
  annotations?: Annotation[];
  schedule?: Schedule | null;
  scheduleID?: number;
}

export interface TemplateRolePermission {
  id: number;
  name?: string;
  description?: string;
  status?: number;
  type?: number | null;
  code?: string;
  createdOn?: Date;
  createdBy?: number;
  createdByName?: string;
  templateTypeID: number;
  templateType?: TemplateType;
  documentTypeID?: number;
  documentType?: DocumentType;
  roleID: number;
  role: Role;
  checklistPrivilege: number;
}

export enum ChecklistPriv {
  Prepare = 1,
  Review = 2,
  Approve = 9,
  CreateChecklist = 11,
  ReviewChecklist = 12
}

export enum ChecklistTemplateStatusEnum {
  Temp = -1,        // TEMP (RSS Functional Test)
  Draft = 0,        // NEW
  Active = 1,       // ACTIVE
  PendingRev = 2,   // PNR
  Rejected = 3,     // RJT
  CorrectionsPending = 4,  // CRR
  CorrectionsInProgress = 5,   // COR
  PendingApr = 6,   // PND
  InReview = 7,     // IRV
  Archived = 9,     // OLD
  Approving = 10,   // IAP
  Suggesting = 11   // IAS
}
export interface ChecklistExecStatus {
  id: number;
  name?: string;
  description?: string;
  status: number;
  type?: number;
  code?: string;
  createdOn?: Date;
  createdBy?: number;
  documentTypeID: number;
  formStatusID: number;
  formStatus: FormStatus;
  icon: string;
  cssClass: string;
  order: number;
  wfStepStatus?: WFStepStatusLocal;
  enabled?: boolean;
  hidden?: boolean;
}

export class Placeholder {
  id!: number;
  name!: string;
  description?: string;
  type?: number;
  code?: string;
  status?: number;
  placeholderString!: string;
  object?: string;
  data?: any;
}
export class ChecklistTemplateStatus {
  id!: number;
  statusEnum?: ChecklistTemplateStatusEnum;
  nextStatusEnum?: ChecklistTemplateStatusEnum;
  altNextStatusEnum?: ChecklistTemplateStatusEnum;
  type?: number;
  icon!: string;
  cssClass!: string;
  order!: number;
  name!: string;
  description!: string;
  code!: string;
  selected?: boolean;
}

export interface Annotation {
  id: number;
  name?: string;
  description?: string;
  annotationText?: string | null;
  createdBy?: number;
  createdByName?: string;
  createdOn?: Date;
  status?: number;
  type?: number;
  code?: string;
  wfTaskLocalID?: number;
  checklistTemplateID?: number;
  checklistID?: number;
  responseBy?: number | null;
  responseByName?: string | null;
  responseText?: string | null;
  responseDate?: Date | null;
}

export enum AnnotationStatus {
  Active = 0,
  Done = 1
}

export class RSSTest {
  procedureMasterId?: number;
  procedureTypeEnum?: ProcedureTypeEnum;
  checklistTemplate?: ChecklistTemplate;
}

export class RadMonitorChecklistTasks {
  taskLoc: WfTaskLocalResource = new WfTaskLocalResource;
  taskOut: WfTaskLocalResource = new WfTaskLocalResource;
  taskIn: WfTaskLocalResource = new WfTaskLocalResource;
  sign1?: WfTaskLocalResource = new WfTaskLocalResource;
  sign2?: WfTaskLocalResource = new WfTaskLocalResource;
  signSwap: WfTaskLocalResource = new WfTaskLocalResource;
}

export class Option {
  id?: number;
  label!: string;
  value!: number;
  checked?: boolean;
  text?: string;
  user?: User | null;
  date?: Date | null;
  operator?: CompareOperator;
}

export interface Shielding {
  id: number;
  resource?: Resource;
  resourceID?: number;
  roleOptions?: RoleOption[];
  isShared?: boolean;
  waiting?: boolean;
}

export interface RoleOption {
  id: number;
  roleIDs: number[];
  roleCodes: string;
  userID?: number | null;
  checked?: boolean;
  user?: User | null;
  disabled?: boolean;
  date?: Date | null;
}

// export class RoleOption {
//   id?: number;
//   roleIDs?: number[];
//   roleCodes?: string;
//   userID?: number | null;
//   checked?: boolean;
//   user?: User | null;
//   disabled?: boolean;
//   date?: Date | null;
// }