import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Checklist, SaveChecklistStatusResource } from 'src/app/components/checklists/checklists';
import { ChecklistsService } from 'src/app/components/checklists/checklists.service';
import { WFSectionLocalResource, WFStepLocalResource, WFTaskSignatureResource, WfActionLocalResource, WfTaskLocalResource } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';
import { Observable, Subscription } from 'rxjs';
import { ChecklistsUpdate } from 'src/app/components/checklists/store/checklists/checklists.action';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { FormType, FormStatusEnum, Action } from 'src/app/common/enumerations/enumerations';
import { ScheduleStatusEnum } from 'src/app/components/schedules/models/enums';
import { Schedule, ScheduleSubdetail } from 'src/app/components/schedules/models/schedule';
import { ScheduleService } from 'src/app/components/schedules/services/schedule.service';
import { SchedulesUpdate } from 'src/app/components/schedules/store/schedules/schedule.action';
import { Role } from 'src/app/components/catalogs/roles/services/role';
import { YesNoDialogComponent } from '../../yes-no-dialog/yes-no-dialog.component';
import { KEService } from 'src/app/components/kenable/ke.service';
import { KEResource, SaveKEStatusResource } from 'src/app/components/kenable/ke';
import { AbsiResource } from 'src/app/components/absi/absi';
import { MessagePlaceholder } from 'src/app/common/models/placeholder';
import { MessageTypeEnum } from 'src/app/components/messages/services/message-banner.enums';
@Component({
  selector: 'cl-close-action',
  templateUrl: './cl-close-action.component.html',
  styleUrls: ['./cl-close-action.component.scss']
})
export class ClCloseActionComponent extends ClBaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() currentChecklist?: Checklist | KEResource | AbsiResource | null;
  @Output() closed = new EventEmitter<boolean>();
  @Output() loading = new EventEmitter<boolean>();

  public name?: string | null;

  allRoles: Role[] = [];
  roles = [];
  public showRoles!: Role[];

  schedule?: Schedule | null;
  schedules!: Schedule[];
  schedules$!: Observable<Schedule[]>;
  schedulesSubs!: Subscription;

  public hidden!: boolean;

  wfTaskSignature: WFTaskSignatureResource = new WFTaskSignatureResource();

  fullRoleName!: string;

  isChecked?: boolean;
  toggleLabel?: string;

  constructor(
    public _wf: WorkFlowService,
    public _ke: KEService,
    public _checklist: ChecklistsService,
    private serviceSchedule: ScheduleService,
    protected override injector: Injector
  ) {
    super(injector);
  }

  override ngOnDestroy(): void {
    this.schedulesSubs?.unsubscribe();
  }

  ngOnInit() {
    this.toggleLabel = this.getMessage('ScheduleCloseLabel').description;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadSchedules();
    this.refresh();
  }

  loadSchedules() {
    const checklist = this.currentChecklist as Checklist;
    this.schedule = null;
    if (checklist?.scheduleId || checklist?.checklistTemplate?.procedureID) {
      this.schedules$ = this.store.select(state => state.Schedules.data);
      this.schedulesSubs = this.schedules$.subscribe(async data => {
        this.schedules = data.filter(x => x.statusId != ScheduleStatusEnum.remove && x.statusId != ScheduleStatusEnum.completed);

        if (checklist && checklist.checklistStatus?.formStatusID != FormStatusEnum.Closed && checklist.checklistStatus?.formStatusID != FormStatusEnum.Canceled) {
          let schedule = this.schedules.find(x => x.id == checklist.scheduleId);
          if (!schedule) {
            schedule = this.schedules.find(s => s.procedureMasterID == checklist.checklistTemplate?.procedureMasterID);
          }
          if (!schedule) {
            schedule = await this.getScheduleByID(checklist.scheduleId ?? 0)
          }
          this.schedule = schedule;
          if (!this.schedule) this.alert.warning('No Schedule found for this checklist!');
        }
        this.checkSchedule();
      });
    }
  }

  async getScheduleByID(id: number) {
    return await this.serviceSchedule.getById(id).toPromise();
  }

  checkSchedule() {
    if (!this.valueString) {
      if (this.schedule) this.isChecked = true;
      else this.isChecked = false;
    }
    else this.isChecked = this.utils.JSONparse(this.valueString).checked;
  }

  buttonDisabled() {
    return this.disabled || !this.hasRoles(this.roleIds) || this.disabledClose();
  }

  disabledClose() {
    if (!this._checklist.readyToClose() && (this.currentChecklist instanceof Checklist && this.currentChecklist?.checklistTemplate?.templateType?.allSignaturesRequired || this.currentChecklist instanceof KEResource && this.currentChecklist?.serialNo?.includes('KE-'))) {
      return true;
    }
    return false;
  }

  refresh() {
    if (!this.value) {
      this.label = 'Close';
      this.name = null;
      this.date = null;
    }
    else {
      this.name = this.user?.name;
      this.date = this.date;
    }
    this.checkSchedule();
  }

  click() {
    if (!this.value && !this.editor && !this.builder) {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: '400px',
        data: {
          message: this.getMessage('finish_message')?.description
        }
      });
      confirm.afterClosed().toPromise().then(data => {
        if (data) {
          this.name = this.getCurrentUser()?.name;
          this.date = new Date();
          this.loading.emit(true);

          if (this.currentChecklist) {
            const wfSectionLocal = this.currentChecklist.wfTable?.wfTableLocal?.wfSectionLocals.find((s: WFSectionLocalResource) => s?.wfStepLocals?.some(t => t.wfTaskLocals?.some(x => x.id === this.taskID)));

            const wfStepLocal = wfSectionLocal?.wfStepLocals?.find((s: WFStepLocalResource) => s.wfTaskLocals.some(x => x.id == this.taskID));

            const wfTaskLocal = wfStepLocal?.wfTaskLocals.find((x: WfTaskLocalResource) => x.id == this.taskID);

            const wfActionApprove = wfTaskLocal?.wfActionLocals?.find((a: WfActionLocalResource) => a.action == Action.Approve);
            if (this.currentChecklist.wfTableID)
              this._wf.getDocumentTypeByWFTableID(this.currentChecklist.wfTableID).toPromise().then((formType: any) => {

                switch (formType?.id) {

                  case FormType.KE:
                    if (wfTaskLocal?.wfTasks?.[0].id && wfActionApprove?.id)
                      this._ke.sign(wfTaskLocal.wfTasks[0].id, wfActionApprove.id).toPromise().then((res: any) => {
                        if (res) {
                          this.value = true;
                          this.closed.emit(this.isChecked);
                        }
                        else {
                          this.forceClose(FormType.KE, wfTaskLocal);
                        }
                      });
                    break;

                  case FormType.ABSI:
                    this.value = true;
                    this.closed.emit(this.isChecked);
                    break;

                  default:
                    if (wfTaskLocal?.wfTasks?.[0].id && wfActionApprove?.id)
                      this._checklist.sign(wfTaskLocal.wfTasks[0].id, wfActionApprove.id, undefined, FormStatusEnum.Closed).toPromise().then(async wfTaskLocal => {
                        const saveChecklistStatus = {
                          id: this.currentChecklist?.id,
                          status: FormStatusEnum.Closed,
                        } as SaveChecklistStatusResource;
                        this.user = this.getCurrentUser();
                        this.closed.emit(this.isChecked);
                        this._checklist.putStatus(saveChecklistStatus).toPromise().then(res => {
                          this.currentChecklist = res;
                          this.value = true;
                          if (this.schedule && this.isChecked) {
                            if (this.currentChecklist?.checklistTemplate?.templateType?.code == 'HFUNC' && this.schedule.subdetail) {
                              this.updateScheduleStatusSubdetail(this.schedule.subdetail, ScheduleStatusEnum.completedSubdetail);
                            }
                            else {
                              this.updateScheduleStatus(this.schedule, ScheduleStatusEnum.completed);
                            }
                          }
                          else {
                            this.loading.emit(false);
                          }
                          this.alert.message('FormClosed', [{ placeholder: '{serialNo}', value: this.currentChecklist?.serialNo }]);
                          if (res)
                            this.store.dispatch(new ChecklistsUpdate(res.id, res));
                        });
                      });
                    break;
                }
              })
                .catch(error => {
                  console.log(error);
                  this.loading.emit(false);
                });
          }
          else {
            this.closed.emit(this.isChecked);
            this.loading.emit(false);
          }
        }
      });
    }
  }

  forceClose(formType: FormType, wfTaskLocal: WfTaskLocalResource) {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: "500px", data: {
        message: this.getMessage('ForceCloseChecklist').description,
        icon: "stop"
      },
    });
    confirm.afterClosed().subscribe(async (data) => {
      if (data) {
        switch (formType) {
          case FormType.KE:
            const saveKEStatus = { id: this.currentChecklist?.id, status: FormStatusEnum.Closed } as SaveKEStatusResource;
            this.loading.emit(true);
            await this._ke.putStatus(saveKEStatus).toPromise();
            this.loading.emit(false);
            break;
        }
      }
      else {
        switch (formType) {
          case FormType.KE:
            const wfActionUnapprove = wfTaskLocal?.wfActionLocals?.find(a => a.action == Action.Unapprove);
            if (wfTaskLocal.wfTasks?.[0].id && wfActionUnapprove?.id) {
              this.loading.emit(true);
              this._ke.sign(wfTaskLocal.wfTasks[0].id, wfActionUnapprove.id).toPromise().then(() => {
                this.value = false;
                this.loading.emit(false);
              });
            }
            break;
        }
      }
    });
  }

  updateScheduleStatus(schedule: Schedule, status: ScheduleStatusEnum) {
    if (schedule) {
      schedule.statusId = status;
      if (schedule.statusId === ScheduleStatusEnum.completed) {
        this.loading.emit(true);
        this.serviceSchedule.updateStatus(schedule).toPromise().then(data => {
          if (this.currentChecklist && this.currentChecklist instanceof Checklist)
            this.currentChecklist.schedule = schedule;
          this.alert.message('update', [new MessagePlaceholder('{what}', 'Schedule for ' + schedule.scheduleResources.map(r => r.resource ? r.resource?.name : r.resourceString).join(', '))], MessageTypeEnum.Warning, 'Schedules');
          this.loading.emit(false);
        });
      }
    }
  }

  updateScheduleStatusSubdetail(subdetail: ScheduleSubdetail, status: ScheduleStatusEnum) {
    if (subdetail) {
      subdetail.statusId = status;
      this.loading.emit(true);
      this.serviceSchedule.updateSubdetailStatus(subdetail).subscribe(response => {
        const newSchedule = response as Schedule;
        this.store.dispatch(new SchedulesUpdate(newSchedule.id, newSchedule));
        this.alert.message('update', [new MessagePlaceholder('{what}', 'Schedule for ' + newSchedule.scheduleResources.map(r => r.resource ? r.resource?.name : r.resourceString).join(', '))], MessageTypeEnum.Warning, 'Schedules');
        this.loading.emit(false);
      });
    }
  }

  toggleChanged() {
    if (this.isChecked) {
      this.alert.message('ScheduleCloseOn');
    }
    else {
      this.alert.message('ScheduleCloseOff');
    }
    this.valueString = this.utils.JSONstringify({ checked: this.isChecked });
    this.changed.emit(this.valueString);
  }

}
