import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Schedule, ScheduleSubdetail } from '../models/schedule';
import { BaseService } from 'src/app/common/base/base.service';
import { ScheduleStatusEnum } from '../models/enums';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService extends BaseService<Schedule, number> {

  protected rootURL: string;
  locationIDs: number[] = [];
  constructor(protected override injector: Injector) {
    super(injector, '/schedule');
    this.rootURL = this.BASE_URL + '/schedule';
  }

  // readAll(): Observable<Schedule[]> {
  //   return this.http.get<Schedule[]>(this.rootURL);
  // }

  // readByID(id: number): Observable<Schedule> {
  //   return this.http.get<Schedule>(this.rootURL + '/byID/' + id);
  // }

  // read(id: number): Observable<Schedule> {
  //   const url = this.rootURL + '/' + id;
  //   return this.http.get<Schedule>(url);
  // }

  // readByType(type: number, scheduleCustomTabId: number | null = null): Observable<Schedule[]> {
  //   const scheduleQuery = scheduleCustomTabId ? '?customTabId=' + scheduleCustomTabId : "";
  //   return this.http.get<Schedule[]>(this.rootURL + '/type/' + type + scheduleQuery);
  // }

  updateStatus(schedule: Schedule): Observable<Schedule> {
    return this.http.put<Schedule>(this.rootURL + '/status', schedule);
  }

  // updateCompleteStatus(schedule: Schedule): Observable<any> {
  //   return this.http.put<Schedule>(this.rootURL + '/status', schedule);
  // }

  updateSubdetailStatus(subdetail: ScheduleSubdetail): Observable<any> {
    return this.http.put<any>(this.rootURL + '/SubdetailStatus', subdetail);
  }

  // getScheduleByScheduleResourceId(scheduleResourceId: number): Observable<any> {
  //   return this.http.get<any>(this.rootURL + '/ScheduleResource/' + scheduleResourceId);
  // }

  getLocationsIdBySchedules(schedules: Schedule[]) {
    schedules?.map(schedule => {
      schedule.scheduleResources?.map(scheduleResource => {
        if (scheduleResource.resourceId && schedule.statusId !== ScheduleStatusEnum.completed) {
          this.locationIDs.push(scheduleResource.resourceId);
        }
      });
    });
  }
}
