import { GeneralCondition, ReturnType } from "src/app/common/enumerations/enumerations";
import { WfTaskLocalResource } from "src/app/services/work-flow/work-flow";
import { utils } from "./utils";

export class ConditionParser {

	private static operatorsArray() {
		return Object.values(Operators);
	}

	private static getValues(
		tag: string,
		tasks: WfTaskLocalResource[],
		operator: Operators = Operators.NONE
	) {
		let values: any[] = [];
		tasks
			.filter((t) => t.code?.trim() === tag.trim())
			.forEach((t) => {
				if (t.component?.returnType) {
					switch (t.component.returnType) {
						case ReturnType.Boolean:
							values = t.component.hasMultipleRoles
								? this.getRolesChecked(t, operator)
								: this.getBoolean(t, operator);
							break;
						case ReturnType.Numeric:
							values = this.getChecked(t, operator);
							break;
						case ReturnType.Sign:
							values.push(
								operator === Operators.NONE
									? t.wfTasks?.[0].signedBy != null
									: t.wfTasks?.[0].signedBy == null
							);
							break;
						case ReturnType.Text:
							values = this.getText(t);
							break;
						default:
							values.push(t.value);
					}
				}
			});
		return values;
	}

	private static getText(task: WfTaskLocalResource): any[] {
		let values: any[] = [];
		const storedText = utils.JSONparse(task.text);
		if (storedText?.length) {
			if (storedText.some((x: { key: any }) => x.key)) {
				const storedValues = storedText.find((x: { key: string }) => x.key === "values").val;
				values = storedValues.filter((x: { text: any }) => x.text).map((x: { text: any }) => x.text);
			} else {
				const storedString = utils.JSONparse(storedText[0].stringValue);
				const storedValues = storedString.find((x: { key: string }) => x.key === "values").val;
				values = storedValues.filter((x: { text: any }) => x.text).map((x: { text: any }) => x.text);
			}
		}
		return values;
	}

	private static getRolesChecked(
		task: WfTaskLocalResource,
		operator: Operators
	): any[] {
		let values: any[] = [];
		let roleOptions = [];
		const storedText = utils.JSONparse(task.text);
		if (task.text?.includes("stringValue")) {
			roleOptions = utils.JSONparse(storedText[0].stringValue);
		} else {
			roleOptions = storedText;
		}
		values.push(
			operator === Operators.NONE
				? roleOptions?.filter((o: { checked: any }) => o.checked).length ===
				roleOptions?.length
				: roleOptions?.filter((o: { checked: any }) => o.checked).length !==
				roleOptions?.length
		);
		return values;
	}

	private static getBoolean(
		task: WfTaskLocalResource,
		operator: Operators
	): any[] {
		const values: any[] = [];
		const storedText = utils.JSONparse(task.text);
		if (storedText?.length) {
			if (storedText.some((x: { key: any }) => x.key)) {
				const storedValues = storedText.find(
					(x: { key: string }) =>
						x.key === (operator === Operators.NONE ? "checkedYes" : "checkedNo")
				).val;
				values.push(storedValues);
			} else {
				const storedString = utils.JSONparse(storedText[0].stringValue);
				const storedValues = storedString.find(
					(x: { key: string }) =>
						x.key === (operator === Operators.NONE ? "checkedYes" : "checkedNo")
				).val;
				values.push(storedValues);
			}
		}
		return values;
	}

	private static getChecked(task: WfTaskLocalResource, operator: Operators): any[] {
		const component = task.component;
		let values = [];
		if (component?.hasMultipleCheckboxes) {
			const storedText = utils.JSONparse(task.text);
			if (storedText?.length) {
				if (storedText.some((x: { key: any }) => x.key)) {
					const storedValues = storedText.find((x: { key: string }) => x.key === "checked").val;
					values = storedValues
						.filter((x: { checked: any }) => x.checked)
						.map((x: { value: any }) => x.value);
				} else {
					const storedString = utils.JSONparse(storedText[0].stringValue);
					const storedValues = storedString.find((x: { key: string }) => x.key === "checked").val;
					values = storedValues
						.filter((x: { checked: any }) => x.checked)
						.map((x: { value: any }) => x.value);
				}
			}
		} else if (component?.hasRadioButtons) {
			values.push(
				task.numericValue?.toString()
			);
		} else {
			if (task.booleanValue !== undefined) {
				values.push(task.booleanValue);
			}
			if (task.isCompleted !== undefined) {
				values.push(task.isCompleted);
			}
		}
		return values;
	}

	private static evaluateSingleCondition(condition: string, tasks: WfTaskLocalResource[]): boolean {
		// console.log(`Evaluating Condition: ${condition}`);
		const operators = [Operators.EQUAL, Operators.EQUAL2, Operators.NOTEQUAL, Operators.NOTEQUAL2];
		let operator = operators.find((op) => condition.includes(op));
		let result = false;
		if (!operator) {
			if (condition.startsWith("!")) {
				// Handle single variable evaluation (e.g., `!sign1`)
				result = !this.getValues(condition.replace('!', '').trim(), tasks, Operators.NONE).includes(true);
				// console.log(`Single Condition (${condition}): ${result}`);
				return result;
			} else {
				condition = condition.replace("!", "");
				const generalConditions = utils.getENUMString(GeneralCondition);
				if (generalConditions.map((x) => x.value).includes(condition)) {
					result = this.evalGeneralConditions(condition, tasks);
				} else {
					// Handle single variable evaluation (e.g., `sign1`)
					result = this.getValues(condition.trim(), tasks, Operators.NONE).includes(true);
				}
				// console.log(`Single Condition (${condition}): ${result}`);
				return result;
			}
		}

		// Split by operator and evaluate

		operator = this.operatorsArray().find((x) =>
			condition.includes(x.toString())
		);
		let operands: any[] = [];
		let var1 = null;
		let var2 = null;
		let value = null;

		if (!operator) {
			operator = condition?.includes(Operators.NOT)
				? Operators.NOT
				: Operators.NONE;
		}
		switch (operator) {
			case Operators.EQUAL:
			case Operators.EQUAL2:
				operands = condition.split(operator);
				if (operands.length == 2) {
					var1 = operands[0].trim();
					value = operands[1].trim().replace(/'/g, "");
					result = this.getValues(var1, tasks, operator).includes(value);
				}
				break;
			case Operators.NOTEQUAL:
			case Operators.NOTEQUAL2:
				operands = condition.split(operator);
				if (operands.length == 2) {
					var1 = operands[0].trim();
					value = operands[1].trim();
					result = !this.getValues(var1, tasks, operator).includes(value);
				}
				break;
			case Operators.AND:
				operands = condition.split(operator);
				if (operands.length == 2) {
					var1 = operands[0].trim();
					var2 = operands[1].trim();
					result =
						this.getValues(var1, tasks).includes(true) &&
						this.getValues(var2, tasks).includes(true);
				}
				break;
			case Operators.OR:
				operands = condition.split(operator);
				if (operands.length == 2) {
					var1 = operands[0].trim();
					var2 = operands[1].trim();
					result =
						this.getValues(var1, tasks).includes(true) ||
						this.getValues(var2, tasks).includes(true);
				}
				break;
			case Operators.NOT:
			case Operators.NONE:
				condition = condition.replace("!", "");
				const generalConditions = utils.getENUMString(GeneralCondition);
				if (generalConditions.map((x) => x.value).includes(condition)) {
					result = this.evalGeneralConditions(condition, tasks);
				} else {
					result = this.getValues(condition, tasks, operator).includes(true);
				}

				break;
		}
		return result;
	}

	private static evalGeneralConditions(
		condition: string,
		tasks: WfTaskLocalResource[]
	) {
		let result = true;
		switch (condition) {
			case GeneralCondition.AllTasksCompleted:
				tasks
					.filter((t) => t.condition != condition)
					.map((t) => {
						const req = this.parseCondition(t.condition ?? '', tasks);
						if (req) {
							const res = utils.taskComplete(t);
							if (!res) {
								result = res ?? false;
							}
						}
					});

				break;
			default:
				return true;
		}
		return result;
	}

	private static tokenize(condition: string): string[] {
		const tokens: string[] = [];
		let buffer = "";
		let depth = 0;

		for (let i = 0; i < condition.length; i++) {
			const char = condition[i];

			if (char === "(") {
				if (depth > 0) buffer += char;
				depth++;
			} else if (char === ")") {
				depth--;
				if (depth > 0) buffer += char;
				if (depth === 0) {
					tokens.push(`(${buffer.trim()})`);
					buffer = "";
				}
			} else if (depth === 0 && (condition.startsWith("&&", i) || condition.startsWith("||", i))) {
				if (buffer.trim()) tokens.push(buffer.trim());
				tokens.push(condition.substr(i, 2)); // Push the operator
				i++; // Skip next character as it's part of the operator
				buffer = "";
			} else {
				buffer += char;
			}
		}

		if (buffer.trim()) tokens.push(buffer.trim());

		// console.log('Tokenized:', condition, '->', tokens); // Debugging output
		return tokens;
	}


	private static parseConditionRecursive(tokens: string[], tasks: WfTaskLocalResource[]): boolean {
		let result: boolean | undefined;
		let operator: string | null = null;

		for (const token of tokens) {
			if (token === "&&" || token === "||") {
				operator = token;
			} else {
				// Evaluate the current token
				const conditionResult = token.startsWith("(")
					? this.parseConditionRecursive(this.tokenize(token.slice(1, -1)), tasks) // Recursively parse nested condition
					: this.evaluateSingleCondition(token, tasks); // Evaluate single condition

				// Combine with previous result using the current operator
				if (result === undefined) {
					result = conditionResult;
				} else if (operator === "&&") {
					result = result && conditionResult;
				} else if (operator === "||") {
					result = result || conditionResult;
				}

				// Debugging: Print intermediate results
				// console.log(`Token: ${token}, Result: ${conditionResult}, Combined Result: ${result}`);
			}
		}

		return result ?? false;
	}

	static parseCondition(condition: string, tasks: WfTaskLocalResource[]): boolean {
		if (!condition) return true;

		const tokens = this.tokenize(condition);
		const result = this.parseConditionRecursive(tokens, tasks);
		// console.log('Tokenizing:', condition, '->', tokens, '->', result); // Debugging output

		return result;
	}

	static parseConditions(tasks: WfTaskLocalResource[]): WfTaskLocalResource[] {
		return tasks.map((t) => {

			// console.warn(`Task ${t.id} - Condition: ${t.condition}`);

			const value = t.condition ? this.parseCondition(t.condition, tasks) : true;
			const hideDisable = t.configuration
				? utils.JSONparse(t.configuration).hideDisable
				: false;

			// console.log(`Task ${t.id} - Condition: ${t.condition}, Value: ${value}`);
			// console.log(`Task ${t.id} - Required: ${!hideDisable ? value : true}`);
			// console.log(`Task ${t.id} - Disabled: ${hideDisable ? !value : false}`);
			// console.log(`------------------------------------------------------------------------------------`);

			t.required = !hideDisable ? value : true;
			t.disabled = hideDisable ? !value : false;

			if (t.wfTasks?.[0]) {
				t.wfTasks[0].required = t.required;
				t.wfTasks[0].enabled = !t.disabled;
			}
			t.isCompleted = t.required ? utils.taskComplete(t) : true;
			return t;
		});
	}
}

export enum Operators {
	EQUAL = "==",
	EQUAL2 = "===",
	NOTEQUAL = "!=",
	NOTEQUAL2 = "!==",
	AND = "&&",
	OR = "||",
	NOT = "!",
	NONE = "",
}
