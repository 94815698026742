<div
  class="row mt-4 pb-4 full-width border-top-left relative"
  id="{{ reviewCycle.serialNo }}"
  [ngClass]="reviewCycle.reviewRequestStatus?.color"
  *ngFor="let reviewCycle of reviewCycles"
>
  <!-- <div class="left-label" *ngIf="reviewCycle.type == 2">
    {{ label(reviewCycle) }}
  </div> -->
  <div class="col-12 text-center">
    <span
      (click)="openReviewRequest(reviewCycle)"
      class="cycle-name"
      [ngClass]="reviewCycle.reviewRequestStatus?.color"
      >{{ reviewCycle.name }}
      <mat-icon [matTooltip]="reviewCycle.reviewRequestStatus?.name ?? ''">{{
        reviewCycle.reviewRequestStatus?.icon
      }}</mat-icon></span
    >
    <div class="row text-left">
      <div class="col-12">
        <reviews-boards-reviews
          [reviewCycle]="reviewCycle"
          (selectedReview)="reviewSelected($event)"
        ></reviews-boards-reviews>
      </div>
    </div>
  </div>
</div>
