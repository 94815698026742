import { User } from 'src/app/components/catalogs/user-catalog/services/user';
import { WfSignatureResource } from 'src/app/services/work-flow/work-flow';
import { WFTableConfig, WFTableResource } from '../workflow/workflow';
import { FormStatus } from 'src/app/services/forms/form-status';
import { ChecklistExecStatus } from '../checklists/checklists';

export class AbsiResource {
  id!: number;
  name?: string;
  serialNo!: string;
  scheduleId?: number;
  resourceName?: string;
  status!: number;
  type?: number;
  formStatus?: FormStatus;
  requesterId?: number;
  requesterName?: string;
  createdOn?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedOn?: Date;
  updatedBy?: number;
  updatedByName?: string;
  locations?: Location[];
  wfTableID?: number;
  wfTableLocalID?: number;
  wfTable?: WFTableResource;
  wfTableConfigID?: number;
  typeName?: string;
  versionName?: string;
  cancelReason?: string;

  checklistStatusID?: number;
  checklistStatus?: ChecklistExecStatus;
}

export class SaveAbsiResource {
  id!: number;
  name?: string;
  serialNo?: string;
  type?: number;
  scheduleId!: number;
  status?: number;
  requesterId?: number;
  wfTableID?: number;
}

export class AbsiStatusResource {
  status!: number;
  completed!: boolean;
  userID!: number;
  taskCount!: number;
}

export interface SaveAbsiStatusResource {
  id: number;
  status: number;
  cancelReason: string;
}

export class AbsiTemplateResource {
  id?: number;
  name!: string;
  type!: number;
  status?: number;
  requesterId!: number;
  wfTableID?: number;
  createdOn?: Date;
  wfTableConfig?: WFTableConfig;
  absiTemplateVersions?: AbsiTemplateVersionResource[];
}

export class AbsiTemplateVersionResource {
  id?: number;
  name!: string;
  status?: number;
  wfTableID?: number;
  wfTableConfig?: WFTableConfig;
  createdBy?: number;
  createdByName?: string;
  createdOn?: Date;
  updatedBy?: number;
  updatedByName?: string;
  updatedOn?: Date;
  approvedByID?: number;
  approvedBy?: User;
  approvedOn?: Date;

  statusName?: string;
  statusClass?: string;
}

export class AbsiTemplateTypeResource {
  id?: number;
  name?: string;
  description?: string;
  status?: number;
  code?: string;
  wfTableID?: number;
  createdOn?: Date;
  absiTemplates?: AbsiTemplateResource[];
}

export class ABSITStatusResource {
  activeTemplate!: AbsiTemplateResource;
  newTemplate!: number;
  templateStatus!: number;
}

export class AbsiSection {
  public wfTableID!: number;
  public sectionNumber!: number;
  public wfSignatures!: WfSignatureResource[];
}
