import { Component, HostListener, OnInit, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/common/base/base.component';
import { Observable, Subscription } from 'rxjs';
import { Document, DocumentCategory, DocumentType } from 'src/app/services/documents/documents';
import { FormStatusEnum } from 'src/app/common/enumerations/enumerations';
import { ReviewCycle } from '../../reviews/reviews.models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-current-activity',
  templateUrl: './current-activity.component.html',
  styleUrls: ['./current-activity.component.scss']
})
export class CurrentActivityComponent extends BaseComponent implements OnInit {

  public maxHeight = '0px';
  loading = false;

  inactive = [FormStatusEnum.Closed, FormStatusEnum.Canceled, FormStatusEnum.New];

  default = [{ id: 1, order: 1 }, { id: 2, order: 5 }, { id: 3, order: 2 }, { id: 4, order: 3 }, { id: 9, order: 4 }, { id: 11, order: 6 }];

  documents?: Document[];
  documentsFiltered?: Document[];
  documents$!: Observable<Document[]>;
  documentsSubs!: Subscription;

  documentTypes?: DocumentType[];
  documentTypesFiltered?: DocumentType[];
  documentTypes$!: Observable<DocumentType[]>;
  documentTypesSubs!: Subscription;

  formsAndReviewsFiltered?: DocumentType[];
  checklistsFiltered?: DocumentType[];

  reviewCycles?: ReviewCycle[];
  reviewCyclesFiltered?: ReviewCycle[];
  reviewCycles$!: Observable<ReviewCycle[]>;
  reviewCyclesSubs!: Subscription;

  public router!: Router;   // Use this to Prevent Opent link in New Tab or Same be displayed

  redirectTo = true;

  constructor(
    protected override injector: Injector,
  ) {
    super(injector);
    this.router = injector.get(Router);
    this.redirect
  }

  ngOnInit() {
    this.loadDocuments();
  }

  loadReviewCycles() {
    this.reviewCycles$ = this.store.select(state => state.ReviewCycles.data);
    this.reviewCyclesSubs = this.reviewCycles$.subscribe(data => {
      if (data?.length) {
        this.reviewCycles = data;
        this.documents?.filter(d => d.category == DocumentCategory.Review).map(d => {
          const reviewCycle = this.reviewCycles?.find(c => c.serialNo == d.serialNo);
          d.disableLink = !reviewCycle?.reviewBoard;
        });
      }
    });
  }

  loadDocumentTypes() {
    this.documentTypes$ = this.store.select(state => state.DocumentType.data);
    this.documentTypesSubs = this.documentTypes$.subscribe(data => {
      if (data?.length) {
        data.filter(d => d.type).map(d => {
          const doc = data.find(x => x.id == d.type);
          if (doc) doc.checklistExecStatuses = d.checklistExecStatuses;
        });
        this.documentTypes = data;
        this.documentTypesFiltered = this.documentTypes.filter(d => !d.type && d.id != 8 && (this.default.map(d => d.id).includes(d.id) || d.showInCatalog || d.id == 201));
        this.documentTypesFiltered.map(t => {
          t.documents = this.documents?.filter(d => d.type == t.id && (d.category != 2 || d.category == 2 && !this.inactive.includes(t.checklistExecStatuses?.find(s => s.id == d.status)?.formStatusID ?? 0)));
          t.order = this.default.find(d => d.id == t.id)?.order ?? 99;
          t.visible = this.default.map(d => d.id).includes(t.id) || (t.documents?.length ?? 0) > 0;
          t.order = t.id == 201 ? 999 : t.order;
        });
        this.documentTypesFiltered.sort((a, b) => this.utils.sort(a.order, b.order));
        this.formsAndReviewsFiltered = this.documentTypesFiltered.filter(f => f.visible &&
          ((f.order ?? 0) <= 4 || (f.order ?? 0) == 999))
        this.checklistsFiltered = this.documentTypesFiltered.filter(f => f.visible && (f.order ?? 0) > 4 && (f.order ?? 0) < 999)
      }
    });
  }

  loadDocuments() {
    this.loading = true;
    this.documents$ = this.store.select(state => state.Documents.data);
    this.documentsSubs = this.documents$.subscribe(data => {
      if (data?.length) {
        this.documents = data.filter(d => !this.inactive.includes(d.status ?? 0)).sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(b.serialNo, a.serialNo));
        this.loadReviewCycles();
        this.loadDocumentTypes();
        this.loading = false;
      }
    });
  }

  addMoreInfo(documents?: Document[]): string {
    if (documents?.length) {
      var moreInfo = '<div class="d-flex flex-column">' + documents.map(d => {
        return '<div class="my-1 bold ' + d.cssClass + '">' + (
          !d.disableLink ? '<a tabindex="-1" href="#/redirect/' + d.serialNo + '">' + d.serialNo + '</a>' :
            '<span class="yellow">' + d.serialNo + '</span>') +
          '</div>';
      }).join('') + '</div>';
      return moreInfo ?? '';
    }
    else return '';
  }

  navigateToForm(doc: DocumentType) {
    if (doc.documents?.some(d => d.category != 2) || !doc.documents?.length) {
      this.router.navigate([doc.code?.trim().toLowerCase()]);
    }
    else {
      const documentType = this.documentTypes?.find(d => d.type == doc.id);
      this.router.navigate(['checklist-exec/' + documentType?.id]);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any; innerHeight: any; }; }) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;
    this.maxHeight = (parseInt((this.innerHeight * 0.50).toFixed(0)) - 100) + 'px';
  }

  responsiveFunction() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.maxHeight = (parseInt((this.innerHeight * 0.50).toFixed(0)) - 100) + 'px';
  }

}
