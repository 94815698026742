<div class="d-flex flex-row justify-content-between pl-3">
  <div class="pl-2 pt-1">
    <mat-label
      [innerHTML]="question ?? '' | placeholderHighlight : editor"
    ></mat-label>
  </div>
  <div
    class="text-right d-flex flex-row justify-content-end align-items-center"
  >
    <button
      mat-icon-button
      class="mr-3 pt-2"
      *ngIf="roleIds?.length"
      color="{{ color }}"
      [matTooltip]="
        'Email Notification will be sent to <b>' +
        roleCodesToNotify +
        '</b> on Button click'
      "
      (click)="viewNotification()"
    >
      <mat-icon>forward_to_inbox</mat-icon>
    </button>

    <role-chips [roleCodes]="roleCodes" class="mr-2"></role-chips>

    <button
      mat-flat-button
      (click)="setStatus()"
      color="{{ color }}"
      [disabled]="checkDisabled() || builder"
    >
      <div class="d-flex flex-row align-items-center">
        {{ title }}
        <initials-chip
          class="ml-2"
          [user]="user"
          [date]="date"
          [disabled]="disabled"
          *ngIf="user"
        ></initials-chip>
      </div>

      <mat-icon
        color="warn"
        *ngIf="!clStatus && !editor"
        matTooltip="Wrong Status Setting!"
        matTooltipClass="warn-tooltip"
        >warning</mat-icon
      >
    </button>
  </div>
</div>
