<div class="row">
  <div class="col col-lg-3 px-1" *ngFor="let reviewType of reviewTypes">
    <div class="card full-height">
      <!-- <div
        class="card-header"
        [ngClass]="{ 'bg-blue': reviewCycle.type == reviewTypesEnum.MODF }"
      > -->
      <div
        class="card-header"
        [ngClass]="{ 'bg-blue': reviewType.type == reviewTypesEnum.MODF }"
      >
        {{ reviewType.name }}&nbsp;({{ reviewType.code }})
      </div>
      <div class="card-body">
        <div
          *ngFor="let review of getReviews(reviewType)"
          class="d-flex flex-row justify-content-between mb-1 review bottom-border pointer"
          (click)="getReviewDetails(review)"
        >
          <div class="d-flex flex-row">
            <div class="date" [ngClass]="review.reviewStatus.color">
              {{ review.reviewDate | date }}
            </div>
            <div
              class="action-items-wrapper non-stop"
              *ngIf="getActionItems(review, false) != 0"
              matTooltip="Non-stopper Action Item Count"
            >
              <span class="action-items">{{
                getActionItems(review, false)
              }}</span>
            </div>
            <div
              class="action-items-wrapper stopper"
              *ngIf="getActionItems(review, true) != 0"
              matTooltip="Stopper Action Item Count"
            >
              <span
                class="action-items"
                *ngIf="getActionItems(review, true) != 0"
                >{{ getActionItems(review, true) }}</span
              >
            </div>
          </div>
          <div class="status" [ngClass]="getClass(review.reviewStatus.color)">
            {{ review.reviewStatus.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
