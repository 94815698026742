<div class="body">
  <mat-toolbar class="toolbar">
    <div class="menu">
      <button mat-icon-button (click)="isExpanded = !isExpanded">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <div class="full-width">
      <div class="row full-width d-flex align-items-center">
        <div class="col-3 col-md-3">
          <h1 class="title">{{ reviewBoardType?.name }}</h1>
        </div>
        <div class="col-6 col-md-6" *ngIf="review">
          <h1 class="title" [ngClass]="review.reviewStatus.color">
            {{ label() }} {{ review.reviewType.name }} for
            {{ review.reviewCycle.reviewBoard.resource?.name }}
          </h1>
        </div>
        <div
          class="col-6 col-md-2 pt-2 d-flex align-items-center"
          *ngIf="!review"
        >
          <mat-form-field appearance="outline" class="beamline no-subscript">
            <mat-label> {{ reviewBoardType?.resourceType?.name }}</mat-label>
            <input
              type="text"
              placeholder="Select {{ reviewBoardType?.resourceType?.name }}"
              matInput
              name="resourceCtrl"
              [formControl]="resourceCtrl"
              [matAutocomplete]="autoResource"
              (keyup)="changedResource($event)"
            />
            <mat-autocomplete
              #autoResource="matAutocomplete"
              #resourceAutocomplete
              [displayWith]="displayObject"
              (optionSelected)="selectedResource($event)"
            >
              <mat-option
                *ngFor="let resource of resourcesFiltered"
                [value]="resource"
              >
                {{ resource.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-icon
              class="pointer input-icon"
              *ngIf="!resource"
              color="primary"
              >search</mat-icon
            >
            <mat-icon
              class="pointer input-icon"
              *ngIf="resource"
              color="warn"
              (click)="clearFilter()"
              >close</mat-icon
            >
            <mat-hint *ngIf="resource">
              {{ resource.description }}
            </mat-hint>
          </mat-form-field>
        </div>
        <div
          *ngIf="!review"
          class="col-6 col-md-4 d-flex justify-content-center"
        >
          <span *ngIf="draftRequestsCount > 1" title="{{ moreInfo }}"
            >Note: There are {{ draftRequestsCount }} pending requests</span
          >
          <span *ngIf="draftRequestsCount == 1" title="{{ moreInfo }}"
            >Note: There is {{ draftRequestsCount }} pending request</span
          >
        </div>
        <div
          class="col-6 col-md-2 d-flex justify-content-end align-items-center"
          *ngIf="!review && !service.index"
        >
          <mat-slide-toggle
            class="small"
            color="primary"
            [(ngModel)]="showOnlyActive"
            (change)="showOnlyActiveChanged($event)"
            >Show Active Boards only
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </mat-toolbar>
  <mat-sidenav-container class="sidenav-container" autosize>
    <mat-sidenav #sidenav class="reviews-sidenav" mode="side" opened="true">
      <mat-nav-list>
        <mat-list-item
          class="parent"
          (click)="gotoIndex()"
          *ngIf="!service.index"
        >
          <span class="full-width" *ngIf="isExpanded">Index View</span>
          <mat-icon matListItemIcon class="blue">list_alt</mat-icon>
        </mat-list-item>
        <mat-list-item
          class="parent"
          (click)="gotoBoard()"
          *ngIf="review || service.index"
        >
          <span class="full-width" *ngIf="isExpanded">Board View</span>
          <mat-icon matListItemIcon class="blue">grid_view</mat-icon>
        </mat-list-item>

        <mat-list-item
          class="parent"
          (click)="openAddDialog()"
          *ngIf="
            !review &&
            !service.index &&
            reviewBoardType?.allowCustomResources &&
            addCustomResourcePrivilege
          "
        >
          <span class="full-width" *ngIf="isExpanded">New Location</span>
          <mat-icon matListItemIcon class="yellow">add_box</mat-icon>
        </mat-list-item>
        <mat-list-item class="parent side-table-container">
          <span class="full-width" *ngIf="isExpanded">
            <review-index-table
              [reviewBoardTypeID]="reviewBoardTypeId"
              [visible]="service.index"
              (reviewCycleSelected)="reviewCycleSelected($event)"
            ></review-index-table>
          </span>
        </mat-list-item>
      </mat-nav-list>

      <mat-nav-list class="pb-3">
        <hr />
        <mat-list-item
          (click)="settings()"
          class="parent"
          *ngIf="allowSettings()"
        >
          <span class="full-width" *ngIf="isExpanded">Settings</span>
          <mat-icon matListItemIcon class="gray">settings</mat-icon>
        </mat-list-item>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content #sidenavContent class="sidenav-content scrollbar">
      <div class="container-fluid">
        <reviews-index
          [reviewCycle]="reviewCycle"
          [reviewBoardTypeID]="reviewBoardTypeId"
          [visible]="service.index"
          (reviewSelected)="reviewSelected($event)"
        ></reviews-index>
        <reviews-boards
          *ngIf="!service.index"
          [reviewBoardType]="reviewBoardType"
          [resourcesFiltered]="resourcesFiltered"
          [review]="review"
          [showOnlyActive]="showOnlyActive"
          (selectedReview)="reviewSelected($event)"
          (setLoading)="setLoadingBar($event)"
        >
        </reviews-boards>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <overlay-loading *ngIf="service.loading"></overlay-loading>
</div>
