<div class="cell-container full-width" *ngIf="mainPropertyName != 'notes'">
  <ng-container *ngIf="!hutchCondition; else hutchContent">
    <div
      class="cell"
      [ngClass]="{ 'bottom-border': schedule.subdetail }"
      [ngStyle]="{ 'background-color': getColor(schedule, false) }"
    >
      <div class="flex-display flex-row">
        <div
          *ngIf="mainProperty || mainPropertyName"
          [innerHTML]="format(mainProperty) | highlightSearch : filter"
        ></div>
      </div>
    </div>
    <div
      *ngIf="schedule?.subdetail"
      class="cell"
      [ngStyle]="{ 'background-color': getColor(schedule, true) }"
    >
      <div class="flex-display flex-row">
        <div
          *ngIf="subdetailProperty"
          [innerHTML]="
            format(subdetailProperty, true) | highlightSearch : filter
          "
        ></div>
      </div>
    </div>
  </ng-container>
  <ng-template #hutchContent>
    <div
      *ngIf="schedule?.subdetail"
      class="cell"
      [ngClass]="{ 'bottom-border': schedule.subdetail }"
      [ngStyle]="{ 'background-color': getColor(schedule, true) }"
    >
      <div class="flex-display flex-row">
        <div
          *ngIf="subdetailProperty"
          [innerHTML]="
            format(subdetailProperty, true) | highlightSearch : filter
          "
        ></div>
      </div>
    </div>
    <div
      class="cell"
      [ngStyle]="{ 'background-color': getColor(schedule, false) }"
    >
      <div class="flex-display flex-row">
        <div
          *ngIf="mainProperty || mainPropertyName"
          [innerHTML]="format(mainProperty) | highlightSearch : filter"
        ></div>
      </div>
    </div>
  </ng-template>
</div>

<div class="cell-container full-width" *ngIf="mainPropertyName == 'notes'">
  <ng-container *ngIf="!hutchCondition; else notesHutchContent">
    <div
      class="cell"
      [ngClass]="{ 'bottom-border': schedule.subdetail }"
      [ngStyle]="{ 'background-color': getColor(schedule, false) }"
    >
      <notes
        [notes]="schedule.notes"
        [canDelete]="true"
        (delete)="deleteNote(schedule, $event)"
      ></notes>
    </div>
    <div
      *ngIf="schedule?.subdetail"
      class="cell"
      [ngStyle]="{ 'background-color': getColor(schedule, true) }"
    >
      <notes
        [notes]="schedule.subdetail?.notes"
        [canDelete]="true"
        (delete)="deleteNoteSub(schedule, $event)"
      ></notes>
    </div>
  </ng-container>
  <ng-template #notesHutchContent>
    <div
      *ngIf="schedule?.subdetail"
      class="cell"
      [ngClass]="{ 'bottom-border': schedule.subdetail }"
      [ngStyle]="{ 'background-color': getColor(schedule, true) }"
    >
      <notes
        [notes]="schedule.subdetail?.notes"
        [canDelete]="true"
        (delete)="deleteNoteSub(schedule, $event)"
      ></notes>
    </div>
    <div
      class="cell"
      [ngStyle]="{ 'background-color': getColor(schedule, false) }"
    >
      <notes
        [notes]="schedule.notes"
        [canDelete]="true"
        (delete)="deleteNote(schedule, $event)"
      ></notes>
    </div>
  </ng-template>
</div>
