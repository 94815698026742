import { Component, OnInit, OnChanges, SimpleChanges, OnDestroy, Injector, ViewChild } from "@angular/core";
import { ReviewBoardType, Review, ReviewTypesEnum, ReviewBoard, ReviewCycle, } from "./reviews.models";
import { ReviewsService } from "./reviews.service";
import { BaseComponent } from "src/app/common/base/base.component";
import { FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subscription, Observable } from "rxjs";
import { ResourceType, ReviewCycleStatus } from "src/app/common/enumerations/enumerations";
import { Resource } from "src/app/components/catalogs/beamline-catalog/resource/resources";
import { PrivilegeEnum } from "src/app/services/role-privilege/privilege-enum";
import { ReviewCyclesStoreAndListenersService } from "./store/review-cycles/review-cycles.store";
import { ReviewPermissionsRefresh } from "./store/review-permissions/review-permissions.action";
import { ReviewPermissionsStoreAndListenersService } from "./store/review-permissions/review-permissions.store";
import { ReviewBoardTypesStoreAndListenersService } from "./store/review-board-types/review-board-types.store";
import { ReviewTypesStoreAndListenersService } from "./store/review-types/review-types.store";
import { ReviewBoardsStoreAndListenersService } from "./store/review-boards/review-boards.store";
import { ReviewStatusStoreAndListenersService } from "./store/review-status/review-status.store";
import { ReviewAttendanceCategoriesStoreAndListenersService } from "./store/review-attendance-category/review-attendance-category.store";
import { ReviewActionItemTypesStoreAndListenersService } from "./store/review-action-item-types/review-action-item-types.store";
import { ReviewActionItemsStoreAndListenersService } from "./store/review-action-items/review-action-items.store";
import { ReviewsStoreAndListenersService } from "./store/reviews/reviews.store";
import { ReviewAttendanceSaveStoreAndListenersService } from "./store/review-attendance-save/review-attendance-save.store";
import { ReviewsSettingsComponent } from "./reviews-settings/reviews-settings.component";
import { ReviewsBoardsAddResourceComponent } from "./reviews-boards/reviews-boards-reviews/reviews-boards-add-resource/reviews-boards-add-resource.component";
import { ReviewsUpdate } from "./store/reviews/reviews.action";
import { MatSidenavContent } from "@angular/material/sidenav";

@Component({
  selector: "reviews",
  templateUrl: "./reviews.component.html",
  styleUrls: ["./reviews.component.scss"],
})
export class ReviewsComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('sidenavContent') sidenavContent!: MatSidenavContent;

  isCreating!: boolean;

  reviewBoardTypeId!: number;
  routeSubs!: Subscription;

  reviewBoardTypes!: ReviewBoardType[];
  reviewBoardTypes$!: Observable<ReviewBoardType[]>;
  reviewBoardTypesSubs!: Subscription;
  reviewBoardType?: ReviewBoardType;

  reviewRequests$!: Observable<ReviewCycle[]>;
  reviewRequests!: ReviewCycle[];
  draftRequestsCount: number = 0;
  draftRequests!: ReviewCycle[];
  reviewRequestsFiltered!: ReviewCycle[];
  reviewRequest!: ReviewCycle;
  reviewRequestsSubs!: Subscription;
  moreInfo!: string;

  resourceCtrl = new FormControl();
  resource!: Resource | null;
  resources!: Resource[];
  resources$!: Observable<Resource[]>;
  resourcesSubs!: Subscription;
  resourcesFiltered!: Resource[];
  showOnlyActive = false;
  review?: Review | null;

  privilege = PrivilegeEnum;

  reviews!: Review[];
  reviews$!: Observable<Review[]>;
  reviewsSubs!: Subscription;

  reviewCycle!: ReviewCycle;

  addCustomResourcePrivilege!: boolean;

  // @ViewChild(ReviewsBoardsComponent) reviewBoards: ReviewsBoardsComponent;
  // @ViewChild(ReviewsIndexComponent) reviewIndex: ReviewsIndexComponent;

  constructor(
    protected override injector: Injector,
    private route: ActivatedRoute,
    public service: ReviewsService,
    private reviewBoardTypesStore: ReviewBoardTypesStoreAndListenersService,
    private reviewTypesStore: ReviewTypesStoreAndListenersService,
    private reviewBoardsStore: ReviewBoardsStoreAndListenersService,
    private reviewStatusStore: ReviewStatusStoreAndListenersService,
    private reviewAttendanceCategoriesStore: ReviewAttendanceCategoriesStoreAndListenersService,
    private reviewActionItemTypesStore: ReviewActionItemTypesStoreAndListenersService,
    private reviewActionItemsStore: ReviewActionItemsStoreAndListenersService,
    private reviewsStore: ReviewsStoreAndListenersService,
    private reviewPermissionsStore: ReviewPermissionsStoreAndListenersService,
    private reviewAttendanceSaveStore: ReviewAttendanceSaveStoreAndListenersService,
    private reviewCyclesStore: ReviewCyclesStoreAndListenersService,
  ) {
    super(injector);

    reviewBoardTypesStore.initListeners();
    reviewBoardTypesStore.initStore();
    reviewBoardsStore.initListeners();
    reviewBoardsStore.initStore();
    reviewStatusStore.initStore();
    reviewStatusStore.initListeners();
    reviewAttendanceCategoriesStore.initListeners();
    reviewAttendanceCategoriesStore.initStore();
    reviewPermissionsStore.initListeners();
    reviewPermissionsStore.initStore();
    reviewsStore.initListeners();
    reviewsStore.initStore();
    reviewTypesStore.initListeners();
    reviewTypesStore.initStore();
    reviewActionItemTypesStore.initListeners();
    reviewActionItemTypesStore.initStore();
    reviewActionItemsStore.initListeners();
    reviewActionItemsStore.initStore();
    reviewAttendanceSaveStore.initListeners();
    reviewAttendanceSaveStore.initStore();
    reviewCyclesStore.initListeners();
    reviewCyclesStore.initStore();
  }

  override ngOnDestroy(): void {
    this.routeSubs?.unsubscribe();
    this.reviewBoardTypesSubs?.unsubscribe();
    this.resourcesSubs?.unsubscribe();
    this.reviewsSubs?.unsubscribe();
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.store.dispatch(new ReviewPermissionsRefresh());
    this.ngOnChanges()
    this.service.Init();
  }

  ngOnChanges(changes?: SimpleChanges): void {
    if (this.service.index) { this.isExpanded = true; }
    this.routeSubs = this.route.params.subscribe((params) => {
      if (this.reviewBoardTypeId != +params["type"]) {
        this.reviewBoardTypeId = +params["type"];
        this.setLoadingBar(true);
        this.loadReviewBoardTypes();
        this.loadReviewRequests();
      }
    });
  }

  allowSettings() {
    return this.service.checkPrivilege(PrivilegeEnum.ChangeSettings);
  }

  loadReviewBoardTypes() {
    this.reviewBoardTypes$ = this.store.select(
      (state) => state.ReviewBoardTypes.data
    );
    this.reviewBoardTypesSubs = this.reviewBoardTypes$.subscribe((data) => {
      this.reviewBoardTypes = data;
      if (this.reviewBoardTypes) {
        this.loadReviews();
        this.reviewBoardType = this.reviewBoardTypes.find((x) => x.id == this.reviewBoardTypeId);
        this.loadResources();
        this.showAddButton();
      }
    });
  }

  loadReviewRequests() {
    this.reviewRequests$ = this.store.select(state => state.ReviewCycles.data);
    this.reviewRequestsSubs = this.reviewRequests$.subscribe(data => {
      this.reviewRequests = data.filter(x => !x.resourceID && x.status !== ReviewCycleStatus.Rejected && x.status !== ReviewCycleStatus.Pending && x.status != ReviewCycleStatus.Archived);
      this.draftRequests = data.filter(x => x.status == ReviewCycleStatus.Pending);
      this.draftRequestsCount = this.draftRequests.length;
      this.moreInfo = this.addMoreInfo();
    });
  }

  addMoreInfo(): string {
    let moreInfo = this.draftRequests.map(x => x.name).join(", ");
    return moreInfo;
  }

  loadReviews() {
    this.reviews$ = this.store.select(state => state.Reviews.data);
    this.reviewsSubs = this.reviews$.subscribe(async data => {
      if (data.length) {
        this.reviews = data.filter(r => r.reviewCycle?.reviewBoard?.reviewBoardTypeID == this.reviewBoardTypeId);
        const openDocument = await this.redirect.getDocument(this.formTypeEnum.REVIEW);
        const reviewCycleID = openDocument?.formID;
        const reviewID = localStorage.getItem('redirectID');
        if (reviewCycleID && reviewID) {
          let review = this.reviews.find(r => r.id == +reviewID);
          if (review) review = await this.service.getReviewById(review.id).toPromise();
          if (review) {
            this.review = review;
            this.store.dispatch(new ReviewsUpdate(review.id, review));
          }
          localStorage.removeItem('redirectID');
        }
      }
    });
  }

  loadResources() {
    this.resources$ = this.store.select((state) => state.Resources.data);
    this.resourcesSubs = this.resources$.subscribe((data) => {
      if (data?.length != 0) {
        //If BRC board, resources should include branchlines
        if (this.reviewBoardType?.id == 1)
          this.resources = data.filter(x => x.type == this.resourceTypeEnum.Beamline || x.type == this.resourceTypeEnum.Branchline || x.type == ResourceType.ReviewProcessResource);
        else {
          this.resources = data
            .filter((x) => x.type == this.reviewBoardType?.resourceTypeID || x.type == ResourceType.ReviewProcessResource)
            .sort((a, b) => this.utils.SortBeamlines(a.name, b.name));
        }
        this.resourcesFiltered = this.resources.sort((a, b) => this.utils.sortArrayAlphabeticallyWithComplexNumbers(a.name, b.name));
        this.setLoadingBar(false);
      }
    });
  }

  settings() {
    const settings = this.dialog.open(ReviewsSettingsComponent, {
      width: "70%",
      minWidth: "850px",
      maxHeight: '80vh',
      data: {
        reviewBoardType: this.reviewBoardType,
      },
      disableClose: true,
      autoFocus: false,
    });
    settings.afterClosed().subscribe((data) => { });
  }

  reviewSelected(review?: Review | null) {
    if (review) {
      this.review = review;
      this.service.index = false;
    }
  }

  changedResource(e: any) {
    const val = e.target.value as string;
    this.resourcesFiltered = this.resources.filter((x) => x.name?.toLowerCase().includes(val.toLowerCase())
    );
    this.resource = null;
  }

  selectedResource(e: any) {
    this.resource = e.option.value;
    if (this.resource)
      this.resourcesFiltered = [this.resource];
  }

  reviewCycleSelected(e: any) {
    this.reviewCycle = e;
  }

  gotoBoard() {
    this.review = null;
    this.service.index = false;
    this.isExpanded = false;

    this.sidenavContent.scrollTo({ top: 0, behavior: 'smooth' });
  }

  gotoIndex() {
    this.review = null;
    this.service.index = true;
    this.isExpanded = true;
  }

  label() {
    return this.review?.reviewCycle?.type == ReviewTypesEnum.MODF
      ? this.review?.reviewCycle.reviewBoard.resource?.name?.substr(0, 1) + "MR"
      : null;
  }

  setLoadingBar(val: boolean) {
    setTimeout(() => {
      this.service.loading = val;
    }, 100);
  }

  showOnlyActiveChanged(e: any) {
    this.showOnlyActive = e.checked;
  }

  async showAddButton() {
    this.addCustomResourcePrivilege = await this.service.checkPrivilege(
      PrivilegeEnum.CreateCustomResources,
      this.reviewBoardType
    );
    return !this.review &&
      this.reviewBoardType?.allowCustomResources &&
      this.addCustomResourcePrivilege;
  }

  clearFilter() {
    this.resource = null;
    this.resourceCtrl.setValue(this.resource);
    this.resourcesFiltered = this.resources;
  }

  openAddDialog() {
    const dialog = this.dialog.open(ReviewsBoardsAddResourceComponent, {
      width: "30%",
      minWidth: "400px",
      disableClose: true,
      autoFocus: false,
      data: {
        reviewBoardType: this.reviewBoardType,
        title: "Add Location",
      },
    });
    dialog.afterClosed().subscribe((resource: Resource) => {
      if (resource) {
        this.setLoadingBar(true);
        const reviewBoard: ReviewBoard = {
          id: 0,
          name: resource.name ?? '',
          description: resource.description ?? '',
          type: this.reviewBoardType?.id,
          code: this.reviewBoardType?.code,
          status: 1,
          resourceID: resource.id,
          reviewBoardTypeID: this.reviewBoardType?.id ?? 0,
        };
        this.service.createReviewBoard(reviewBoard).toPromise();
      }
    });
  }
}
