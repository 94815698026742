import { Component, Input, EventEmitter, Output, ViewChild, OnChanges, OnDestroy, SimpleChanges, OnInit, Injector } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/common/base/base.component';
import { FormStatusEnum, FormType, OlogNotificationType, Roles } from 'src/app/common/enumerations/enumerations';
import { ScheduleStatusEnum } from 'src/app/components/schedules/models/enums';
import { Schedule } from 'src/app/components/schedules/models/schedule';
import { ScheduleService } from 'src/app/components/schedules/services/schedule.service';
import { ActionItemService } from 'src/app/controls/catl/action-item/action-item.service';
import { PartialOlogComponent } from 'src/app/controls/olog/partial-olog/partial-olog.component';
import { YesNoDialogComponent } from 'src/app/controls/yes-no-dialog/yes-no-dialog.component';
import { MessageBanner } from 'src/app/components/messages/services/message-banner';
import { OLogService } from 'src/app/components/olog/olog.service';
import { WFSectionLocalResource, WfSignature, WFTaskSignatureResource } from 'src/app/services/work-flow/work-flow';
import { WorkFlowService } from 'src/app/services/work-flow/work-flow.service';
import { AbsiResource, SaveAbsiStatusResource } from '../../absi';
import { AbsiService } from '../../absi.service';
import { AbsiSectionXComponent } from '../absi-section-wrapper/absi-section-x/absi-section-x.component';
import { closest } from '@ng-bootstrap/ng-bootstrap/util/util';

@Component({
  selector: 'absi-review-and-concurrence',
  templateUrl: './absi-review-and-concurrence.component.html',
  styleUrls: ['./absi-review-and-concurrence.component.scss']
})

export class AbsiReviewAndConcurrenceComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() sectionInfo?: WFSectionLocalResource;
  @Input() absi?: AbsiResource | null;

  @Output() loading = new EventEmitter<boolean>();

  sectionInfo$!: Observable<WFSectionLocalResource[]>;
  sub!: Subscription;
  sectionLoaded = false;

  message!: MessageBanner;

  // @ViewChild('section') public section?: AbsiSectionXComponent;

  sections?: WFSectionLocalResource[];
  sectionsFiltered?: WFSectionLocalResource[];
  sections$!: Observable<WFSectionLocalResource[]>;
  sectionsSubs!: Subscription;

  ologButton = false;
  showReloadButton?: boolean;
  disableButtons?: boolean;

  showOlogButton?: boolean;
  showCloseButton?: boolean;
  showCompleteButton?: boolean;
  completeSignature?: WfSignature;
  closeSignature?: WfSignature;

  sectionLoading = true;

  constructor(
    protected override injector: Injector,
    private _absi: AbsiService,
    private serviceSchedule: ScheduleService,
    private _olog: OLogService,
    private _actionItems: ActionItemService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.message = this.getMessage('ABSI_RevNConc_Confirmation');
  }

  getSection() {
    this.sections$ = this.store.select(state => state.AbsiSections.data);
    this.sectionsSubs = this.sections$.subscribe((data: WFSectionLocalResource[]) => {
      const sectionInfo = data.find(x => x.id == this.sectionInfo?.id);
      if (sectionInfo) {
        const wfSignatures = sectionInfo.wfSignatures?.sort((a, b) => this.utils.sort(a.titleBefore, b.titleBefore));
        sectionInfo.wfSignatures = wfSignatures;
        this.sectionInfo = sectionInfo;
        this.loading.emit(false);
        setTimeout(() => {
          this.showReloadButton = this.sectionInfo?.wfSignatures?.some(s => !s.signed) && this.absi?.status == FormStatusEnum.ReviewAndConcurrence && this.hasRoles([Roles.OIC, Roles.FO]);
        }, 100);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.disableButtons = false;
    this.getSection();
  }

  setButtons(e: any) {
    setTimeout(() => {
      this.showCloseButton = e?.close;
      this.showCompleteButton = e?.complete;
      this.showOlogButton = this.showCloseButton && this.absi?.status == FormStatusEnum.ReviewAndConcurrence;
      this.completeSignature = e.completeSignature;
      this.closeSignature = e.closeSignature;
      this.sectionLoading = false;
    }, 500);
  }

  async complete() {
    this.disableButtons = true;
    const s = this.completeSignature;
    if (this.absi?.id && s?.taskId && s.approveId) {
      this.loading.emit(true);
      await this.approve(s.taskId, s.approveId, FormStatusEnum.ReviewAndConcurrence);
    }
  }

  create() {
    this.sectionInfo = { id: 0 };
    this.absi = new AbsiResource();
  }

  revert() {
    const count = this.sectionInfo?.wfSignatures?.filter(s => s.signed && s.type != -1).length;
    let remove = false;

    if (count) {
      const confirm = this.dialog.open(YesNoDialogComponent, {
        width: '400px',
        data: {
          message: count == 1 ? this.getMessage('one_absiApprovalReview')?.description : this.getMessage('multiple_absiApprovalReview')?.description.replace('{count}', count.toString()),
          icon: 'warn'
        }
      });
      confirm.afterClosed().subscribe(res => {
        remove = res != null;
        this.revertToActive(remove);
      });
    }
    else {
      this.revertToActive(remove);
    }
  }

  async revertToActive(remove: boolean) {
    if (this.absi?.id) {
      this.loading.emit(true);
      this.disableButtons = true;
      if (remove) {
        this.sectionInfo?.wfSignatures?.map(s => {
          if (s.signed) {
            this._absi.sign(s.taskID, s.unapproveID ?? 0).subscribe(res => {
            }, error => {
              console.log(error);
            });
          }
        });
      }
      const s = this.completeSignature;
      if (s?.taskId && s.unapproveId)
        await this.approve(s.taskId, s.unapproveId, FormStatusEnum.Active);
    }
  }

  async close(closeSchedule: boolean) {
    if (closeSchedule) {
      if (this.absi?.scheduleId) {
        const response = await this._olog.postToOlog(FormType.ABSI, this.absi?.id as number, OlogNotificationType.Closed);
        if (response?.ok) {
          this.loading.emit(true);
          this.serviceSchedule.getById(this.absi?.scheduleId).subscribe(async data => {
            const schedule = data;
            await this.openCompleteSchedule(schedule, ScheduleStatusEnum.completed);

            if (this.absi?.id)
              this._actionItems.emailByForm(this.absi?.id).toPromise();
            this.sectionLoaded = false;
          });
        }
      }
    }
    else {
      if (this.absi?.id)
        this._actionItems.emailByForm(this.absi?.id).toPromise();
      this.sectionLoaded = false;
      const s = this.closeSignature;
      if (s?.taskId && s.approveId)
        await this.approve(s.taskId, s.approveId, FormStatusEnum.Closed);
    }
  }

  async approve(taskId: number, actionId: number, status?: FormStatusEnum) {
    this.loading.emit(true);
    await this._absi.sign(taskId, actionId, status).toPromise().catch(error => {
      console.log(error);
      this.alert.defaultError();
    });
  }

  async openCompleteSchedule(schedule: Schedule, status: ScheduleStatusEnum) {
    if (this.absi?.wfTableID)
      this._absi.getFirstSignatureDate(this.absi?.wfTableID).toPromise().then(async date => {
        schedule.completedDate = date;
        await this.closeSchedule(schedule, status);
        if (this.absi?.id) {
          const s = this.closeSignature;
          if (s?.taskId && s.approveId)
            await this.approve(s.taskId, s.approveId, FormStatusEnum.Closed);
        }
      });
  }

  async closeSchedule(schedule: Schedule, status: ScheduleStatusEnum) {
    if (schedule != null) {
      schedule.statusId = status;
      await this.serviceSchedule.updateStatus(schedule).toPromise();
    }
  }

  refresh() {
    const confirm = this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        message: this.getMessage('ABSI_ReloadRnCWarning').description,
        icon: 'stop'
      }
    });
    confirm.afterClosed().subscribe(async data => {
      if (data && this.absi?.wfTableID) {
        this.loading.emit(true);
        this._absi.refreshRnC(this.absi?.wfTableID).toPromise().then(() => {

        })
          .catch(e => {
            this.alert.message('defaultErrorMessage');
            console.log(e);

          });
      }
    });
  }

  async editOlog() {
    if (this.absi) {
      const data = await this._olog.getOlog(FormType.ABSI, this.absi.id, OlogNotificationType.Closed).toPromise();
      const dialogRef = this.dialog.open(PartialOlogComponent, {
        height: 'fit-content',
        width: '80%',
        data
      });
      dialogRef.afterClosed().subscribe((isSaved) => {
        console.log('The dialog was closed');
        if (isSaved) {
          console.log(isSaved);
        }
      });
    }
  }
}
