<div
  *ngIf="schedule.subdetail"
  class="cell pt-1"
  [ngStyle]="{ 'background-color': getColor(schedule, true) }"
>
  <button
    mat-icon-button
    *ngIf="
      validateShowMenu(schedule.subdetail.statusId, schedule) &&
      validateAction(schedule.subdetail.statusId, ScheduleStatus.completed)
    "
    [matMenuTriggerFor]="menu"
    aria-label="Schedules menu"
  >
    <mat-icon color="primary">more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      *ngIf="
        validateAction(schedule.subdetail.statusId, ScheduleStatus.completed) &&
        sv.validateRole('createNote', tokenInfo, scheduleType)
      "
      (click)="openAddNoteSub(schedule)"
    >
      <mat-icon class="yellow">note_add</mat-icon>
      <span>Create Note</span>
    </button>
    <button
      mat-menu-item
      *ngIf="
        validateAction(
          schedule.subdetail.statusId,
          ScheduleStatus.requestExtension
        ) && sv.validateRole('requestExtension', tokenInfo, scheduleType)
      "
      (click)="
        requestRejectExtensionSubdetail(
          schedule,
          ScheduleStatus.requestExtension
        )
      "
    >
      <mat-icon class="blue">today</mat-icon>
      <span>Request extension</span>
    </button>
    <button
      mat-menu-item
      *ngIf="
        validateAction(
          schedule.subdetail.statusId,
          ScheduleStatus.extensionGranted
        ) &&
        sv.validateRole(
          'grantExtensionSubdetail',
          tokenInfo,
          scheduleType,
          schedule
        )
      "
      (click)="
        grantExtensionSubdetail(
          schedule.subdetail,
          ScheduleStatus.extensionGranted
        )
      "
    >
      <mat-icon class="orange">offline_pin</mat-icon>
      <span>Grant extension</span>
    </button>
    <button
      mat-menu-item
      *ngIf="
        validateAction(
          schedule.subdetail.statusId,
          ScheduleStatus.refuseExtension
        ) &&
        sv.validateRole('refuseSchedule', tokenInfo, scheduleType, schedule)
      "
      (click)="
        requestRejectExtensionSubdetail(
          schedule,
          ScheduleStatus.refuseExtension
        )
      "
    >
      <mat-icon class="red">unpublished</mat-icon>
      <span>Reject extension</span>
    </button>
    <button
      mat-menu-item
      *ngIf="
        validateAction(schedule.subdetail.statusId, ScheduleStatus.completed) &&
        sv.validateRole('completeSchedule', tokenInfo, scheduleType)
      "
      (click)="
        openCompleteSubdetail(
          schedule.subdetail,
          ScheduleStatus.completedSubdetail
        )
      "
    >
      <mat-icon class="green">done_outline</mat-icon>
      <span>Complete</span>
    </button>
  </mat-menu>
</div>
