
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SchedulesCreate, SchedulesDelete, SchedulesRefresh, SchedulesRefreshByID, SchedulesUpdate } from './schedule.action';
import { Schedule } from '../../models/schedule';
import { ScheduleService } from '../../services/schedule.service';
import { ScheduleTypeService } from '../../services/schedule-type.service';

export class SchedulesStateModel {
  data: Schedule[] = [];
}

@State<SchedulesStateModel>({
  name: 'Schedules',
  defaults: {
    data: [],
  }
})
@Injectable()
export class SchedulesState {
  constructor(
    private schedulesService: ScheduleService, private scheduleTypeService: ScheduleTypeService
  ) { }

  @Selector()
  static read(state: SchedulesStateModel) {
    return state.data;
  }

  @Action(SchedulesRefresh)
  refresh({ patchState }: StateContext<SchedulesStateModel>) {
    this.schedulesService.getAll().subscribe(
      data => patchState({ data }),
      error => console.error(error)
    );
  }

  @Action(SchedulesRefreshByID)
  refreshByID({ getState, patchState }: StateContext<SchedulesStateModel>, { id }: SchedulesRefreshByID) {
    this.schedulesService.getById(id).toPromise().then(item => {
      if (item) {
        const state = getState();
        const index = state.data.map(x => x.id).indexOf(id);

        if (index >= 0) {
          state.data.splice(index, 1);
        }

        patchState({
          data: [...state.data, item]
        });
      }
    });

  }

  @Action(SchedulesCreate)
  create({ getState, patchState }: StateContext<SchedulesStateModel>, { item }: SchedulesCreate) {
    patchState({
      data: [...getState().data, item]
    });
  }

  @Action(SchedulesUpdate)
  update({ getState, patchState }: StateContext<SchedulesStateModel>, { id, item }: SchedulesUpdate) {
    const state = getState();
    const index = state.data.map(x => x.id).indexOf(id);

    if (index >= 0) {
      state.data.splice(index, 1);
      patchState({
        data: [...state.data, item]
      });
    }
  }

  @Action(SchedulesDelete)
  delete({ getState, patchState }: StateContext<SchedulesStateModel>, { id }: SchedulesDelete) {
    patchState({
      data: getState().data.filter(x => x.id != id)
    });
  }


}
